import TextField from "@mui/material/TextField";
import { ErrorMessage } from "formik";
import PropTypes from "prop-types";
import MDBox from "shared/components/MDBox";
import MDTypography from "shared/components/MDTypography";

function FormFieldAutocomplete({ label, name, fieldVariant, fieldShrink, ...rest }) {
  return (
    <MDBox mb={1.5}>
      {fieldShrink ? (
        <TextField
          {...rest}
          name={name}
          label={label}
          fullWidth
          variant={fieldVariant}
          InputLabelProps={{ shrink: fieldShrink }}
        />
      ) : (
        <TextField {...rest} name={name} label={label} fullWidth variant={fieldVariant} />
      )}
      <MDBox>
        <MDTypography
          component="div"
          variant="caption"
          color="error"
          fontWeight="regular"
          mt={0.75}
        >
          <ErrorMessage name={name} />
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

FormFieldAutocomplete.defaultProps = {
  label: "",
  fieldVariant: "standard",
  fieldShrink: false,
};

FormFieldAutocomplete.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  fieldVariant: PropTypes.string,
  fieldShrink: PropTypes.bool,
};

export default FormFieldAutocomplete;
