const initialValues = {
  name: "",
  code_product: "",

  code_barcode: "",

  cost_price: "",
  operation_type: "",
  amount: "",
  nf: "",
  observation: "",
  derivations: [],
};

export default initialValues;
