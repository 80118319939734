/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";

import { Autocomplete, Grid } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDInput from "shared/components/MDInput";
import {
  compareOptionToValue,
  handleValue,
  adornedInputPropsAutocomplete,
  handleNoOptionsAutocomplete,
} from "shared/components/autocomplete/autocomplete_configs";
import FormFieldAutocomplete from "shared/components/form_field/form_field_autocomplete";
import FormFieldMemo from "shared/components/form_field/form_field_memo";
import PropTypes from "prop-types";
import ProductsService from "pages/product/service";
import toastError from "shared/components/snackbar/error/toast_error";

function ProductPISAutocomplete({ formData }) {
  const { values, setFieldValue, handleBlur } = formData;
  const { pis: pisV } = values;

  const [code, setCode] = useState("");
  const [options, setOptions] = useState();
  const [optionsPage, setOptionsPage] = useState(1);
  const [optionsTotalPages, setOptionsTotalPages] = useState(1);
  const [optionsSearch, setOptionsSearch] = useState(1);
  const [loading, setLoading] = useState(false);

  const loadPISs = async (search = "", setField, page = 1, limit = 100) => {
    try {
      setLoading(true);
      const res = await ProductsService.getProductPIS(page, limit, search);
      if (res) {
        setOptions(res.data);
        setOptionsPage(res.meta.page);
        setOptionsTotalPages(res.meta.total_pages);

        if (res.data.length > 0 && search.includes("code=")) setField("pis", res.data[0] ?? "");
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const loadMorePISs = async (search = "", page = 1, limit = 100) => {
    try {
      setLoading(true);
      const res = await ProductsService.getProductPIS(page, limit, search);
      if (res) {
        setOptions([...options, ...res.data]);
        setOptionsPage(res.meta.page);
        setOptionsTotalPages(res.meta.total_pages);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAutocompleteScroll = (event) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1 && optionsPage < optionsTotalPages) {
      loadMorePISs(optionsSearch, optionsPage + 1);
    }
  };

  useEffect(() => {
    if (pisV && !code) setCode(pisV.code);
  }, [pisV]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={2}>
        <MDInput
          name="pis code"
          label="Código PIS"
          variant="standard"
          type="text"
          value={code}
          onChange={(event) => {
            const { value } = event.target;
            setCode(value);
          }}
          onBlur={(event) => {
            const { value } = event.target;
            if (value) {
              setFieldValue("pis", "");
              loadPISs(`&code=${value.trim()}`, setFieldValue);
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={10}>
        <Autocomplete
          options={options ?? []}
          noOptionsText={handleNoOptionsAutocomplete(options, "async")}
          getOptionLabel={(o) => `${o?.code} - ${o?.name}`}
          value={handleValue(pisV)}
          isOptionEqualToValue={(o, v) => compareOptionToValue(o, v, "code")}
          onChange={(e, value) => {
            setFieldValue("pis", value);
            setCode(value?.code ?? "");
          }}
          renderOption={(props, option) => (
            <MDBox {...props} component="li" key={`${option.code}_${option.name}`}>
              {option.code} - {option.name}
            </MDBox>
          )}
          renderInput={(params) => (
            <FormFieldAutocomplete
              {...params}
              fieldVariant="standard"
              type="text"
              label="Descrição do PIS"
              name="pis"
              onChange={(event) => {
                const { value } = event.target;
                if (value && value.length >= 3) {
                  setOptionsSearch(`&description=${value.trim()}`);
                  setOptionsPage(1);
                  loadPISs(`&description=${value.trim()}`, setFieldValue, optionsPage);
                }
              }}
              onBlur={(event) => {
                handleBlur(event);
                if (!event.target.value) setOptions([]);
              }}
              InputProps={adornedInputPropsAutocomplete(loading, params)}
            />
          )}
          ListboxProps={{ onScroll: handleAutocompleteScroll }}
        />
      </Grid>
    </Grid>
  );
}

ProductPISAutocomplete.propTypes = {
  //   productState: PropTypes.objectOf(PropTypes.any).isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProductPISAutocomplete;
