/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */

import { Autocomplete, Grid, Icon, IconButton } from "@mui/material";

import { ErrorMessage, FieldArray } from "formik";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDTypography from "shared/components/MDTypography";

import { PropTypes } from "prop-types";
import { handleValue, renderInput } from "shared/components/autocomplete/autocomplete_configs";

function FieldArrayProductsFieldsList({
  name,
  label,
  options,
  valueField,
  formData,
  disabled,
  children,
}) {
  const { setFieldValue, values, errors, handleBlur } = formData;

  const handleDelete = (array, index) => {
    const dataFilter = array;
    dataFilter.splice(index, 1);
    setFieldValue(name, dataFilter);
  };

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {values[name] &&
            values[name].map((item, index) => (
              <Grid container spacing={1} key={index}>
                {children}

                <Grid item xs={12} sm={disabled ? 12 : 11}>
                  <Autocomplete
                    options={options}
                    disabled={disabled}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={handleValue(valueField[index])}
                    onChange={(e, v) => {
                      setFieldValue(`${name}[${index}]`, v);
                    }}
                    renderInput={(params) =>
                      renderInput("form", params, `${name}[${index}]`, "Selecione", handleBlur)
                    }
                  />
                </Grid>

                {values[name].length > 0 && !disabled && (
                  <Grid item xs={12} sm={1}>
                    <IconButton
                      sx={{ pt: 3 }}
                      title="Deletar"
                      color="error"
                      onClick={() => handleDelete(valueField, index, setFieldValue)}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
          <MDBox>
            <MDTypography
              component="div"
              variant="caption"
              color="error"
              align="center"
              fontWeight="regular"
              mt={0.75}
            >
              {typeof errors.derivations === "string" ? <ErrorMessage name={name} /> : <MDBox />}
            </MDTypography>
          </MDBox>
          {!disabled && (
            <MDBox mb={1}>
              <MDButton
                onClick={() => arrayHelpers.push("")}
                variant="gradient"
                color="info"
                mb={2}
                size="small"
              >
                Adicionar {label}
              </MDButton>
            </MDBox>
          )}
        </>
      )}
    />
  );
}

FieldArrayProductsFieldsList.defaultProps = {
  children: false,
  disabled: false,
};

FieldArrayProductsFieldsList.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired,
  label: PropTypes.string.isRequired,
  valueField: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string]))
    .isRequired,
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default FieldArrayProductsFieldsList;
