/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-else-return */
import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { CircularProgress, Divider, Grid, Icon, IconButton, Modal, useTheme } from "@mui/material";
import MDTypography from "shared/components/MDTypography";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";

import exportToXLSXMovements from "pages/movements/service/xlsx_movement";
// import downloadPdfMovement, { testPdfMovement } from "pages/movements/service/pdf_movement";

import MovementsService from "pages/movements/service";
import toastError from "shared/components/snackbar/error/toast_error";
import { convertData } from "shared/utils";

import ReactToPrint from "react-to-print";
import ReportDerivationColorsSizes from "./product_table_colors_sizes";
import ReportDerivationSizes from "./product_table_sizes";
import ReportDerivationUnique from "./product_table_unique";

import styles from "./styles.module.css";

function ReportModal({ open, onClose, filter }) {
  const componentRef = React.createRef();
  const theme = useTheme();
  // const elementRef = useRef(null);

  const [movementReportData, setMovementReportData] = useState();

  const [loading, setLoading] = useState(false);

  function changeFirstCharacter(text) {
    if (text.charAt(0) === "&") return `?${text.slice(1)}`;
    else return text;
  }

  const handleTableDerivations = (derivations, field) => {
    const mappedValues = derivations.map((item) => item[field]);
    const hasNonNullValues = mappedValues.some((value) => value !== undefined);
    const derivation_values = hasNonNullValues ? [...new Set(mappedValues)] : null;
    if (field === "size" && derivation_values) {
      return derivation_values;
    }
    if (field === "color" && derivation_values) {
      return derivation_values;
    }
    return [];
  };

  const formatDataToReportXLSX = (value) => {
    const remap = value.map((v) => {
      const derivations = v.product_derivations.map((derivation) => ({
        color: derivation.derivations.find((f) => f.derivation === 1)?.value ?? "",
        size: derivation.derivations.find((f) => f.derivation === 2)?.value ?? "",
        unique: derivation.derivations.find((f) => f.derivation === 3)?.value ?? "",
        amount: derivation.quantity,
      }));

      return {
        ...v,
        observations: v.observations,
        derivations,
      };
    });
    return remap;
  };

  const loadProductReport = async () => {
    try {
      setLoading(true);
      const res = await MovementsService.getAllDataFromMovement(changeFirstCharacter(filter));
      const formatedDataXLSX = formatDataToReportXLSX(res);

      const movementReport = formatedDataXLSX.map((v) => {
        const resMap = {
          ...v,
          color: handleTableDerivations(v?.derivations, "color"),
          size: handleTableDerivations(v?.derivations, "size"),
        };
        return resMap;
      });
      if (movementReport) setMovementReportData(movementReport);
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTypeTable = (movementData) => {
    if (movementData?.size.length > 0 && movementData?.color.length > 0) {
      return (
        <ReportDerivationColorsSizes
          productReport={movementData}
          colors={movementData?.color}
          sizes={movementData?.size}
        />
      );
    } else if (movementData?.size.length > 0 && movementData?.color.length === 0) {
      return <ReportDerivationSizes productReport={movementData} sizes={movementData?.size} />;
    } else if (movementData?.color.length > 0 && movementData?.size.length === 0) {
      return (
        <ReportDerivationColorsSizes productReport={movementData} colors={movementData?.color} />
      );
    } else {
      return <ReportDerivationUnique productReport={movementData} />;
    }
  };

  const resetModal = () => {
    onClose();
    setMovementReportData();
  };

  useEffect(() => {
    if (open && filter) loadProductReport();
  }, [open, filter]);

  const printTrigger = useCallback(
    () => (
      <MDButton disabled={!movementReportData} variant="gradient" color="primary" sx={{ mb: 2 }}>
        gerar pdf
      </MDButton>
    ),
    [movementReportData]
  );

  return (
    <Modal open={open} onClose={() => resetModal()}>
      <MDBox className={styles.modal}>
        <MDBox className={styles.modelHeader}>
          <Grid container>
            <Grid xs={11} sm={12} item>
              <MDTypography align="center">Relatório de Movimentação</MDTypography>
            </Grid>
            <Grid item xs={1} sm={1} position="absolute" style={{ right: 5, top: 5 }}>
              <MDBox display="flex" justifyContent="flex-end">
                <IconButton size="small" onClick={() => resetModal()}>
                  <Icon fontSize="small" align="right">
                    close
                  </Icon>
                </IconButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Divider sx={{ background: theme.palette.dark.main, m: 0 }} />
        <MDBox className={styles.modelContainer}>
          <MDBox display="flex" gap={2}>
            <MDButton
              disabled={!movementReportData}
              variant="gradient"
              color="primary"
              onClick={() => exportToXLSXMovements(movementReportData)}
              sx={{ mb: 2 }}
            >
              download xlsx
            </MDButton>
            <ReactToPrint trigger={printTrigger} content={() => componentRef.current} />

            {/* <MDButton
              variant="gradient"
              color="primary"
              onClick={() => {
                testPdfMovement(movementReportData);
                // downloadPdfMovement(elementRef);
              }}
              sx={{ mb: 2 }}
            >
              download pdf
            </MDButton> */}
          </MDBox>

          {loading && (
            <MDBox>
              <CircularProgress color="inherit" size={20} />
            </MDBox>
          )}
          {movementReportData && movementReportData.length > 0 && (
            <MDBox
              ref={componentRef}
              /* ref={elementRef} */
              id="listPDF"
              className={styles.modalContent}
            >
              {movementReportData?.map((v, index) => (
                <MDBox className={styles.modalContentItem} key={index}>
                  <Grid container>
                    <Grid item sm={6} display="flex">
                      Número OF:
                      <MDTypography variant="body2"> {v?.shipping_code} </MDTypography>
                    </Grid>
                    <Grid item sm={6} display="flex">
                      Período:
                      <MDTypography variant="body2">
                        {`${convertData(v?.start_date)} a ${convertData(v?.final_date)}`}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={6}>
                      Cliente Produto:
                    </Grid>
                    <Grid item sm={6} display="flex">
                      Nota fiscal:
                      <MDTypography variant="body2">{v?.nf}</MDTypography>
                    </Grid>
                    <Grid item sm={6} display="flex" px={2}>
                      Produto:
                      <MDTypography variant="body2"> {v?.product_name}</MDTypography>
                    </Grid>
                    <Grid item sm={6} display="flex">
                      Data da movimentação:
                      <MDTypography variant="body2">{convertData(v?.datetime)}</MDTypography>
                    </Grid>
                    <Grid item sm={6} display="flex" px={2}>
                      Pedido:
                    </Grid>
                    <Grid item sm={6} display="flex">
                      Código do produto:
                      <MDTypography variant="body2">{v?.product_code}</MDTypography>
                    </Grid>
                  </Grid>
                  <MDBox px={2}>{v && handleTypeTable(v)}</MDBox>
                  <MDBox>
                    <MDTypography>Observações: </MDTypography>
                    <MDTypography variant="body2"> {v?.observations} </MDTypography>
                  </MDBox>
                </MDBox>
              ))}
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </Modal>
  );
}

ReportModal.defaultProps = {
  onClose: null,
};

ReportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  filter: PropTypes.string.isRequired,
};

export default ReportModal;
