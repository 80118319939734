/* eslint-disable no-unused-vars */
import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Card, Grid, Icon, IconButton, useTheme } from "@mui/material";
import NotificationItem from "examples/Items/NotificationItem";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDInput from "shared/components/MDInput";
import MDProgress from "shared/components/MDProgress";
import MDTypography from "shared/components/MDTypography";
import toastError from "shared/components/snackbar/error/toast_error";
import DataTableApi from "shared/components/tables/data_table_api";

import CircularIndeterminate from "shared/components/loader/circularIndeterminate";
import { adornedInputCancelSearch } from "shared/components/input/input_configs";
import { treeData, gender_list, collection_list } from "shared/utils/select_lists";
import InputTreeView from "shared/components/input/input_tree_view";

import DerivationService from "pages/derivation/service";
import ProductService from "../service";
import ReportModal from "../components/report_modal";

function ProductList() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { palette } = theme;
  const { primary, light } = palette;

  const [openModal, setOpenModal] = useState(false);

  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();

  const [filter, setFilter] = useState("");
  const [filterCode, setFilterCode] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [filterGender, setFilterGender] = useState("");
  const [filterCollection, setFilterCollection] = useState("");
  const [filterSearch, setFilterSearch] = useState("");

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const [totalProducts, setTotalProducts] = useState([]);

  const loadProducts = async (page = 1, limit = 5, search = "") => {
    try {
      setLoadingData(true);
      const res = await ProductService.getAll(page, limit, search);
      if (res) {
        setDataTable(res);
        setTableTotal(res?.meta?.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const handleEdit = (row) => (
    <IconButton
      onClick={() => {
        const { code } = row.original;
        navigate(`/produtos/${code}/derivacoes`);
      }}
      size="small"
      disableRipple
    >
      <Icon fontSize="medium">visibility</Icon>
    </IconButton>
  );

  const loadTotalProducts = async (productId) => {
    try {
      const res = await DerivationService.getTotalProductsById(productId);
      if (res) return res.amount_available_for_sale;
    } catch (e) {
      toastError(e.message);
    }
    return null;
  };

  const handleTotalProducts = (row) => {
    const [loading, setLoading] = useState(false);
    const value = row.original;
    return (
      <MDBox display="flex" alignItems="center">
        <IconButton
          onClick={async () => {
            setLoading(true);
            const res = await loadTotalProducts(value.code);
            setTotalProducts([...totalProducts, { ...value, amount_available_for_sale: res }]);
            setLoading(false);
          }}
          size="small"
          title="Pesquisar Total de produtos"
          disableRipple
          sx={{
            background: primary.main,
            color: light.main,
            borderRadius: "10px",
            mr: 1,
            "&:hover": {
              background: primary.main,
              color: light.main,
            },
          }}
        >
          <Icon fontSize="small">search</Icon>
        </IconButton>
        {loading ? (
          <CircularIndeterminate />
        ) : (
          <MDTypography sx={{ fontSize: 12 }}>
            {totalProducts.find((v) => v.code === value.code)?.amount_available_for_sale ?? ""}
          </MDTypography>
        )}
      </MDBox>
    );
  };

  const dataTableData = {
    columns: [
      {
        Header: "Código",
        accessor: "code",
      },
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "Derivação",
        accessor: "derivation",
        Cell: ({ cell: { row } }) => handleEdit(row),
      },
      {
        Header: "Estoque",
        accessor: "storage",
        Cell: ({ cell: { row } }) => handleTotalProducts(row),
      },
      {
        Header: "Editar",
        accessor: "action",
        width: "15%",
      },
    ],

    rows: dataTable?.data || [],
  };

  const handleClickSearch = () => {
    let search = "";
    if (filterCode) search += `&code=${filterCode.trim()}`;
    if (filter) search += `&name=${filter.trim()}`;
    if (filterCategory) search += `&category=${filterCategory.id}`;
    if (filterGender) search += `&gender=${filterGender.code}`;
    if (filterCollection) search += `&collection=${filterCollection.code}`;

    setFilterSearch(search);
    setTablePage(1);
    loadProducts(1, tableLimit, search);
  };

  useEffect(
    () => loadProducts(tablePage, tableLimit, filterSearch),
    [tablePage, tableLimit, filterSearch]
  );

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pb={3}>
          <MDBox mb={3}>
            <MDBox pb={1}>
              <Card>
                <MDBox p={3} lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="medium" mb={1}>
                    Filtros
                  </MDTypography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={4}>
                      <MDInput
                        label="Código"
                        type="text"
                        name="filter"
                        value={filterCode}
                        size="small"
                        fullWidth
                        InputProps={adornedInputCancelSearch(filterCode, setFilterCode)}
                        onChange={(e) => {
                          setFilterCode(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <MDInput
                        label="Nome"
                        type="text"
                        name="filter"
                        value={filter}
                        size="small"
                        fullWidth
                        InputProps={adornedInputCancelSearch(filter, setFilter)}
                        onChange={(e) => {
                          setFilter(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <InputTreeView
                        name="category"
                        value={filterCategory}
                        label="Selecione a categoria"
                        stateValue={{ setFilterCategory }}
                        treeArray={treeData}
                        isSequential
                        fieldVariant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        options={gender_list}
                        getOptionLabel={(o) => o.name}
                        value={filterGender === "" ? null : filterGender}
                        isOptionEqualToValue={(o, v) => o.code === v.code}
                        onChange={(e, v) => {
                          setFilterGender(v);
                        }}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Selecione o gênero"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        options={collection_list}
                        getOptionLabel={(o) => o.name}
                        value={filterCollection === "" ? null : filterCollection}
                        isOptionEqualToValue={(o, v) => o.code === v.code}
                        onChange={(e, v) => {
                          setFilterCollection(v);
                        }}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            size="small"
                            variant="outlined"
                            label="Selecione a coleção"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <MDBox mt={2} style={{ maxWidth: "10rem" }}>
                    <MDButton
                      variant="gradient"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={handleClickSearch}
                    >
                      BUSCAR
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Card>
            </MDBox>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={10}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Produtos
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <MDButton
                      variant="gradient"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={() => navigate("/produtos/criar")}
                      sx={{ height: "100%" }}
                    >
                      CRIAR
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              {loadingData ? (
                <MDProgress variant="gradient" variantProgress="indeterminate" color="primary" />
              ) : (
                <DataTableApi
                  table={dataTableData}
                  onRowSelected={setRowSelected}
                  entriesPerPage={{ defaultValue: tableLimit }}
                  setTableLimit={setTableLimit}
                  tableLimit={tableLimit}
                  setTablePage={setTablePage}
                  tablePage={tablePage}
                  tableTotal={tableTotal}
                >
                  {[
                    <NotificationItem
                      icon={<Icon>create</Icon>}
                      title="edit"
                      key="edit"
                      onClick={(value) => {
                        navigate(`/produtos/editar/${value.code}`);
                      }}
                    />,
                  ]}
                </DataTableApi>
              )}
            </Card>
          </MDBox>
        </MDBox>
      </DashboardLayout>
      <ReportModal open={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
}

export default ProductList;
