import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    name: Yup.object().required("O nome do produto é obrigatório").nullable(),
    operation_type: Yup.object().required("O tipo de movimentação é obrigatório").nullable(),
    nf: Yup.string().required("O número de nota fiscal é obrigatório"),
    observation: Yup.string().required("A observação é obrigatória"),
    derivations: Yup.array()
      .of(
        Yup.object().shape({
          amount: Yup.number().min(0, "Valor deve ser positivo"),
          cost_price: Yup.string().when("amount", {
            is: (value) => value && value > 0,
            then: Yup.string().required("Preço de custo é obrigatório"),
            otherwise: Yup.string(),
          }),
        })
      )
      .test(
        "amount",
        "Necessário que alguma quantidade tenha sido preenchida",
        (values) => values.reduce((acc, curr) => acc + (curr.amount || 0), 0) >= 1
      ),

    code_barcode: Yup.string()
      .min(6, "Código deve ser ao menos 6 caracteres")
      .max(6, "Código deve ter no máximo 6 caracteres"),
  }),
];

export default validations;
