/* eslint-disable no-unused-vars */
import ClearIcon from "@mui/icons-material/Clear";
import { Card, Chip, Grid, Icon, IconButton, useTheme } from "@mui/material";
import NotificationItem from "examples/Items/NotificationItem";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDInput from "shared/components/MDInput";
import MDProgress from "shared/components/MDProgress";
import MDTypography from "shared/components/MDTypography";
import PopUp, { dialogButton } from "shared/components/pop_ups/pop_up";
import toastError from "shared/components/snackbar/error/toast_error";
import toastSuccess from "shared/components/snackbar/success/toast_success";
import DataTableApi from "shared/components/tables/data_table_api";
import parseRoleNumberToString from "shared/utils/parse_role_number_to_string";
import { adornedInputCancelSearch } from "shared/components/input/input_configs";
import UserService from "../service";

function UserList() {
  const { palette } = useTheme();
  const { ternary, quartenary, sextenary, white } = palette;

  const navigate = useNavigate();
  const [openPopUp, setOpenPopUp] = useState(false);
  const [userStatus, setUserStatus] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();

  const [filter, setFilter] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterSearch, setFilterSearch] = useState("");

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const handleChips = (role) => {
    let color = ternary.main;
    if (role === 0) color = quartenary.main;
    if (role === 1) color = sextenary.main;
    return (
      <Chip
        label={parseRoleNumberToString(role).toUpperCase()}
        style={{ backgroundColor: color, color: white.main, width: "100%" }}
      />
    );
  };

  const dataTableData = {
    columns: [
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Perfil",
        accessor: "role",
        Cell: ({ value }) => handleChips(value),
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Editar",
        accessor: "action",
        width: "15%",
      },
    ],

    rows: dataTable?.data || [],
  };

  const loadUsers = async (page = 1, limit = 5, search = "") => {
    try {
      setLoadingData(true);

      const res = await UserService.getAll(page, limit, search);

      if (res) {
        setDataTable(res);
        setTableTotal(res?.meta?.totalCount);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const handleStatus = async (guid, status) => {
    setOpenPopUp(true);
    setUserStatus({ guid, status });
  };

  const handleCancelChangeStatus = () => setOpenPopUp(false);

  const handleApplyChangeStatus = async () => {
    try {
      const { guid, status } = userStatus;

      await UserService.updateStatus(guid, status);

      await loadUsers(tablePage, tableLimit);

      toastSuccess("Usuário editado com sucesso");
    } catch (e) {
      toastError(e.message);
    } finally {
      setOpenPopUp(false);
    }
  };

  // const handleChange = (event) => {
  //   const { value } = event.target;
  //   if (value === "") {
  //     loadUsers(1, tableLimit);
  //     setTablePage(1);
  //     setFilterSearch("");
  //   }
  //   setFilter(value);
  // };

  const handleClickSearch = () => {
    let search = "";
    if (filter) search += `&name=${filter.trim()}`;
    if (filterEmail) search += `&email=${filterEmail.trim()}`;

    setFilterSearch(search);
    setTablePage(1);
    loadUsers(1, tableLimit, search);
  };

  useEffect(() => loadUsers(tablePage, tableLimit, filterSearch), [tablePage, tableLimit]);

  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pb={3}>
          <MDBox mb={3}>
            <MDBox pb={1}>
              <Card>
                <MDBox p={3} lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="medium" mb={1}>
                    Filtros
                    {/* <MDTypography variant="caption" fontWeight="medium">
                      Nome
                    </MDTypography> */}
                  </MDTypography>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <MDInput
                        type="text"
                        name="filter"
                        label="Nome"
                        value={filter}
                        size="small"
                        fullWidth
                        InputProps={adornedInputCancelSearch(filter, setFilter)}
                        onChange={(e) => setFilter(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MDInput
                        type="email"
                        name="filter"
                        label="Email"
                        value={filterEmail}
                        size="small"
                        fullWidth
                        InputProps={adornedInputCancelSearch(filterEmail, setFilterEmail)}
                        onChange={(e) => setFilterEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={2} display="flex">
                      <MDButton
                        variant="gradient"
                        color="primary"
                        size="small"
                        fullWidth
                        onClick={handleClickSearch}
                      >
                        BUSCAR
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </MDBox>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Usuários
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDBox width="12rem" ml="auto">
                      <MDButton
                        variant="gradient"
                        color="primary"
                        size="small"
                        fullWidth
                        onClick={() => navigate("/usuarios/criar")}
                      >
                        CRIAR
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {loadingData ? (
                <MDProgress variant="gradient" variantProgress="indeterminate" color="primary" />
              ) : (
                <DataTableApi
                  table={dataTableData}
                  onRowSelected={setRowSelected}
                  entriesPerPage={{ defaultValue: tableLimit }}
                  setTableLimit={setTableLimit}
                  tableLimit={tableLimit}
                  setTablePage={setTablePage}
                  tablePage={tablePage}
                  tableTotal={tableTotal}
                  handleStatus={handleStatus}
                >
                  {[
                    <NotificationItem
                      icon={<Icon>create</Icon>}
                      title="edit"
                      key="edit"
                      onClick={(value) => {
                        navigate(`/usuarios/editar/${value.id}`);
                      }}
                    />,
                  ]}
                </DataTableApi>
              )}
            </Card>
          </MDBox>
        </MDBox>
      </DashboardLayout>
      <PopUp
        open={openPopUp}
        onClose={() => {}}
        title="Alterar status"
        contentText={`Deseja ${userStatus?.status ? "ativar" : "desativar"} o usuário?`}
        actions={[
          <MDButton
            color="secondary"
            onClick={handleCancelChangeStatus}
            style={dialogButton}
            key="cancel"
          >
            cancelar
          </MDButton>,
          <MDButton
            color="primary"
            onClick={handleApplyChangeStatus}
            style={dialogButton}
            key="confirm"
          >
            confirmar
          </MDButton>,
        ]}
      />
    </>
  );
}

export default UserList;
