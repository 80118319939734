const initialValues = {
  code: "",
  name: "",
  release_date: "",
  brand: "",
  weight: "",
  unit_of_measurement: "",
  derivations: [""],
  categories: [""],
  ncm: "",
  cest: "",
  pis: "",
  cofins: "",
  tax_origin: "",
  stores: "",

  genre: "",
  collections: "",
  combos: "",

  supplier_code: "",
  observations: "",
};

export default initialValues;
