// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modal__t8gJy {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 90%;
  transform: translate(-50%, -50%);
  min-width: 70%;
  background-color: white !important;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  overflow-y: auto;
}

.styles_modelHeader__NVgGn {
  padding: 10px 10px 0 10px;
}

.styles_modelContainer__zic1n {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  width: 100%;
  height: 100%;
}

.styles_multipleInputTable__KURWE {
  width: 100%;
  overflow-x: auto;
  font-size: 14px;

  table {
    width: 100%;
  }

  th {
    white-space: nowrap;
    /* background-color: #ddd; */
  }

  th,
  tr,
  td {
    border: 1px solid #d2d6da;
    /* padding: 5px; */
  }

  td {
    font-size: 14px;
    text-align: center;
  }

  .styles_tdCurrentAmount__EyDov {
    font-size: 16px;
  }
}

@media screen and (max-width: 850px) {
  .styles_modal__t8gJy {
    min-width: 95%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/product/report_barcode/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,eAAe;EACf,gCAAgC;EAChC,cAAc;EACd,kCAAkC;EAClC,mBAAmB;EACnB,2CAA2C;EAC3C,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,eAAe;;EAEf;IACE,WAAW;EACb;;EAEA;IACE,mBAAmB;IACnB,4BAA4B;EAC9B;;EAEA;;;IAGE,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".modal {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  max-height: 90%;\n  transform: translate(-50%, -50%);\n  min-width: 70%;\n  background-color: white !important;\n  border-radius: 10px;\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n  overflow-y: auto;\n}\n\n.modelHeader {\n  padding: 10px 10px 0 10px;\n}\n\n.modelContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 15px;\n  width: 100%;\n  height: 100%;\n}\n\n.multipleInputTable {\n  width: 100%;\n  overflow-x: auto;\n  font-size: 14px;\n\n  table {\n    width: 100%;\n  }\n\n  th {\n    white-space: nowrap;\n    /* background-color: #ddd; */\n  }\n\n  th,\n  tr,\n  td {\n    border: 1px solid #d2d6da;\n    /* padding: 5px; */\n  }\n\n  td {\n    font-size: 14px;\n    text-align: center;\n  }\n\n  .tdCurrentAmount {\n    font-size: 16px;\n  }\n}\n\n@media screen and (max-width: 850px) {\n  .modal {\n    min-width: 95%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `styles_modal__t8gJy`,
	"modelHeader": `styles_modelHeader__NVgGn`,
	"modelContainer": `styles_modelContainer__zic1n`,
	"multipleInputTable": `styles_multipleInputTable__KURWE`,
	"tdCurrentAmount": `styles_tdCurrentAmount__EyDov`
};
export default ___CSS_LOADER_EXPORT___;
