import { Card } from "@mui/material";
import logo from "assets/images/logo_vertical.png";
import { Form, Formik } from "formik";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useNavigate, useParams } from "react-router-dom";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDTypography from "shared/components/MDTypography";
import FormField from "shared/components/form_field";
import toastError from "shared/components/snackbar/error/toast_error";
import toastSuccess from "shared/components/snackbar/success/toast_success";
import initialValues from "./schemas/initial_values";
import validations from "./schemas/validations";
import ChangePasswordService from "./service";

function ChangePassword() {
  const navigate = useNavigate();
  const { token } = useParams();

  const changePassword = async (values, actions) => {
    const { password, confirmPassword } = values;

    try {
      await ChangePasswordService.changePassword(token, password, confirmPassword);

      toastSuccess("Senha alterada com sucesso");

      actions.setTouched({});
      actions.setSubmitting(false);

      navigate("/entrar");
    } catch (e) {
      toastError(e.message);
    }
  };

  return (
    <BasicLayout>
      <MDBox
        p={2}
        mb={1}
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          marginBottom: 5,
        }}
      >
        <img alt="logo" src={logo} width="100%" />
      </MDBox>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="primary"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Recuperar Senha
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <Formik
            initialValues={initialValues}
            validationSchema={validations[0]}
            onSubmit={changePassword}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting }) => {
              const { password: passwordV, confirmPassword: confirmPasswordV } = values;
              return (
                <Form id="change-password-form" autoComplete="off">
                  <>
                    <MDBox mb={2}>
                      <FormField
                        fieldVariant="outlined"
                        name="password"
                        label="Senha *"
                        type="password"
                        value={passwordV}
                        error={errors.password && touched.password}
                        success={passwordV.length > 0 && !errors.password}
                      />
                    </MDBox>

                    <MDBox mb={2}>
                      <FormField
                        fieldVariant="outlined"
                        name="confirmPassword"
                        label="Confirmar Senha *"
                        type="password"
                        value={confirmPasswordV}
                        error={errors.confirmPassword && touched.confirmPassword}
                        success={confirmPasswordV.length > 0 && !errors.confirmPassword}
                      />
                    </MDBox>
                  </>

                  <MDBox display="flex" alignItems="center" justifyContent="center">
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      sx={{
                        cursor: "pointer",
                        color: "#015488",
                      }}
                      onClick={() => navigate("/entrar")}
                    >
                      Voltar para o login
                    </MDTypography>
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton
                      disabled={isSubmitting}
                      color="primary"
                      type="submit"
                      size="large"
                      fullWidth
                    >
                      Enviar
                    </MDButton>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default ChangePassword;
