import { isAxiosError } from "axios";
import httpService from "../../../shared/services/http";

export default class LoginService {
  static async login(email, password) {
    const route = `/login`;

    try {
      const response = await httpService.post(
        route,
        {},
        {
          auth: {
            username: email,
            password,
          },
        }
      );

      if (!response) {
        throw new Error("Não foi possível fazer login");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 401:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível fazer login");
    }
  }
}
