/* eslint-disable no-unused-vars */
import { Card, CircularProgress, Grid, Icon } from "@mui/material";
import NotificationItem from "examples/Items/NotificationItem";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDInput from "shared/components/MDInput";
import MDProgress from "shared/components/MDProgress";
import MDTypography from "shared/components/MDTypography";
import toastError from "shared/components/snackbar/error/toast_error";
import DataTableApi from "shared/components/tables/data_table_api";
import ProductService from "pages/product/service";
import { adornedInputCancelSearch } from "shared/components/input/input_configs";
import ReplicatePriceModal from "../components/replicate_price_modal";

import DerivationService from "../service";
import { iconButton } from "./styles";
import DerivationReportModal from "../components/report_modal";

function DerivationList() {
  const { code } = useParams();

  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState();
  const [rowSelected, setRowSelected] = useState();
  const [filter, setFilter] = useState("");
  const [filterSearch, setFilterSearch] = useState("");

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  const [productData, setProductData] = useState();

  // modals
  const [modalReplicateOpen, setModalReplicateOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [loadingSync, setLoadingSync] = useState(false);

  const handleExtraColumns = (row, type) => {
    const { derivations } = row.original;
    const res = derivations.find((v) => v.derivation === type) || null;
    return res?.value;
  };

  const handlePrice = (value, qtd = 1) => {
    if (!value) return " - ";
    const valueTotal = value * qtd;
    const formatedPrice = valueTotal.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatedPrice;
  };

  const dataTableData = {
    columns: [
      {
        Header: "Nome",
        accessor: "name",
      },
      {
        Header: "Quantidade",
        accessor: "amount_available_for_sale",
      },
      {
        Header: "Cor",
        accessor: "color",
        Cell: ({ cell: { row } }) => handleExtraColumns(row, 1),
        show: productData?.derivations?.some((v) => v === 1),
      },
      {
        Header: "Tamanho",
        accessor: "size",
        Cell: ({ cell: { row } }) => handleExtraColumns(row, 2),
        show: productData?.derivations?.some((v) => v === 2),
      },
      {
        Header: "Unico",
        accessor: "unique",
        Cell: ({ cell: { row } }) => handleExtraColumns(row, 3),
        show: productData?.derivations?.some((v) => v === 3),
      },
      {
        Header: "Código de barras",
        accessor: "barcode",
      },
      {
        Header: "Preço de custo",
        accessor: "cost_price",
        Cell: ({ value }) => handlePrice(value),
      },
      {
        Header: "Preço de venda",
        accessor: "sale_price",
        Cell: ({ value }) => handlePrice(value),
      },
      {
        Header: "Editar",
        accessor: "action",
        width: "15%",
      },
    ],
    rows: dataTable?.data || [],
  };

  const loadDerivations = async (page = 1, limit = 5, search = "") => {
    try {
      setLoadingData(true);
      const res = await DerivationService.getAll(code, page, limit, search);
      if (res) {
        setDataTable(res);
        setTableTotal(res?.meta?.total);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const loadProduct = async () => {
    try {
      const res = await ProductService.getById(code);
      if (res) setProductData(res);
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleClickSearch = () => {
    let search = "";
    if (filter) search += `&name=${filter.trim()}`;

    setFilterSearch(search);
    setTablePage(1);
    loadDerivations(1, tableLimit, search);
  };

  const syncDerivations = async () => {
    try {
      setLoadingSync(true);
      const res = await DerivationService.syncDerivations(code);
      loadDerivations(tablePage, tableLimit, filterSearch);
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingSync(false);
    }
  };

  useEffect(() => loadDerivations(tablePage, tableLimit, filterSearch), [tablePage, tableLimit]);

  useEffect(() => {
    loadProduct();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <MDBox mb={3}>
          <MDBox pb={1}>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <MDTypography variant="h5" fontWeight="medium" mb={1}>
                  Filtros
                </MDTypography>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <MDInput
                      type="text"
                      name="filter"
                      label="Nome"
                      value={filter}
                      size="small"
                      fullWidth
                      InputProps={adornedInputCancelSearch(filter, setFilter)}
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={2} display="flex">
                    <MDButton
                      disabled={!filter}
                      variant="gradient"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={handleClickSearch}
                    >
                      BUSCAR
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </MDBox>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Derivação {productData && `- ${productData?.code} - ${productData?.name}`}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <MDButton
                    variant="gradient"
                    color="primary"
                    size="small"
                    fullWidth
                    onClick={() => setOpenModal(true)}
                  >
                    relatório de estoque
                  </MDButton>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <MDButton
                    variant="gradient"
                    color="primary"
                    size="small"
                    fullWidth
                    onClick={() => setModalReplicateOpen(true)}
                  >
                    Replicar preços
                  </MDButton>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <MDButton
                    variant="gradient"
                    title="Atualizar dados"
                    size="small"
                    fullWidth
                    onClick={syncDerivations}
                    sx={iconButton}
                  >
                    {loadingSync ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      "Atualizar Dados"
                    )}
                  </MDButton>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <MDButton
                    variant="gradient"
                    color="primary"
                    size="small"
                    fullWidth
                    onClick={() => navigate(`/produtos/${code}/derivacoes/criar`)}
                  >
                    CRIAR
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
            {loadingData ? (
              <MDProgress variant="gradient" variantProgress="indeterminate" color="primary" />
            ) : (
              <DataTableApi
                table={dataTableData}
                onRowSelected={setRowSelected}
                entriesPerPage={{ defaultValue: tableLimit }}
                setTableLimit={setTableLimit}
                tableLimit={tableLimit}
                setTablePage={setTablePage}
                tablePage={tablePage}
                tableTotal={tableTotal}
              >
                {[
                  <NotificationItem
                    icon={<Icon>create</Icon>}
                    title="edit"
                    key="edit"
                    onClick={(value) => {
                      navigate(`/produtos/${code}/derivacoes/editar/${value.code}`);
                    }}
                  />,
                ]}
              </DataTableApi>
            )}
          </Card>
        </MDBox>
      </MDBox>

      <ReplicatePriceModal
        open={modalReplicateOpen}
        onClose={() => setModalReplicateOpen(false)}
        loadList={() => loadDerivations(tablePage, tableLimit, filterSearch)}
      />

      <DerivationReportModal open={openModal} onClose={() => setOpenModal(false)} />
    </DashboardLayout>
  );
}

export default DerivationList;
