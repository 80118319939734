import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import dialog from "assets/theme/components/dialog";
import dialogActions from "assets/theme/components/dialog/dialogActions";
import dialogContent from "assets/theme/components/dialog/dialogContent";
import dialogContentText from "assets/theme/components/dialog/dialogContentText";
import dialogTitle from "assets/theme/components/dialog/dialogTitle";
import PropTypes from "prop-types";
import React from "react";

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);
export const dialogButton = {
  width: "115px",
};

function PopUp({ open, onClose, title, contentText, contentComponent, actions }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ ...dialog, textAlign: "center" }}
    >
      <DialogTitle sx={dialogTitle}>{title}</DialogTitle>
      <DialogContent sx={dialogContent}>
        <DialogContentText sx={dialogContentText}>{contentText}</DialogContentText>
        {contentComponent}
      </DialogContent>
      <DialogActions sx={{ ...dialogActions, justifyContent: "space-evenly" }}>
        {actions.map((item) => item)}
      </DialogActions>
    </Dialog>
  );
}

PopUp.defaultProps = {
  onClose: null,
  contentText: null,
  contentComponent: null,
};

PopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  contentText: PropTypes.string,
  contentComponent: PropTypes.node,
  actions: PropTypes.node.isRequired,
};

export default PopUp;
