/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { createRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Autocomplete, Card, Divider, Grid, Icon, useTheme } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDProgress from "shared/components/MDProgress";
import MDTypography from "shared/components/MDTypography";
import FormField from "shared/components/form_field";
import FormFieldMemo from "shared/components/form_field/form_field_memo";

import { renderInput } from "shared/components/autocomplete/autocomplete_configs";
import adornedInputPropsInput from "shared/components/input/input_configs";
import toastError from "shared/components/snackbar/error/toast_error";
import toastSuccess from "shared/components/snackbar/success/toast_success";
import { currencyMask } from "shared/utils/masks";

import { useAuthContext } from "context/auth_context";
import DerivationService from "pages/derivation/service";
import getMovementOperationTypesFromUserPermissions from "shared/utils/get_movement_operation_types_from_user_permissions";
import { movement_operation_type_list } from "shared/utils/select_lists";
import MovementsService from "../service";
import styles from "./styles.module.css";

import ProductAutocomplete from "./components/product_autocomplete";
import initialValues from "./schemas/initial_values";
import validations from "./schemas/validations";

function MovementCreate() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { userLogged } = useAuthContext();

  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  // product
  const [productOptions, setProductOptions] = useState([]);

  const [loadingData, setLoadingData] = useState(false);

  // search bar code
  const [isSearchByBarCode, setIsSearchByBarCode] = useState(false);
  const [loadingBarCode, setLoadingBarCode] = useState(false);

  const submitButtonRef = createRef();

  const handleMask = (value) => {
    let withMask = value;
    withMask = withMask.replace(".", "");
    withMask = withMask.replace(",", ".");
    return Number(withMask);
  };

  const handlerDerivationsSubmit = (derivations = []) => {
    const res =
      derivations
        .filter((v) => v.amount > 0)
        .map((v) => {
          const mapRes = {
            code_derivation: v.code_derivation,
            cost_price: v.cost_price ? handleMask(v.cost_price) : "",
            amount: v.amount,
          };
          return mapRes;
        }) || [];
    return res;
  };

  const handleSubmit = async (values, actions) => {
    const data = values.name;
    try {
      const stock = {
        nf: values.nf,
        observation: values.observation,
        derivations:
          values.derivations.length > 0 ? handlerDerivationsSubmit(values.derivations) : [],
      };

      const operationType = values.operation_type?.id;

      if (operationType === 1) {
        const res = await MovementsService.addToStock(data.code, stock);
      } else if (operationType === 2) {
        const res = await MovementsService.removeFromStock(data.code, stock);
      } else {
        return;
      }

      setIsSearchByBarCode(false);
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess("Movimentação gerada com sucesso", "");
      window.location.reload();
    } catch (e) {
      toastError(e.message);
    }
  };

  const loadDerivations = async (code, setFieldValue, search = "", page = 1, limit = 500) => {
    try {
      setLoadingData(true);
      setFieldValue("derivations", []);
      const res = await DerivationService.getAll(code, page, limit, search);
      if (res) {
        setFieldValue(
          "derivations",
          res?.data.map((v) => {
            const disableCostPrice = !!v.cost_price;
            const derivRes = {
              name: v.name,
              amount: 0,
              code_derivation: v.code,
              current_amount: v.amount_available_for_sale ?? 0,
              cost_price: v.cost_price ? currencyMask(v.cost_price) : "",
              disable_cost_price: disableCostPrice,
            };
            return derivRes;
          }) ?? []
        );
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const loadDerivationsByBarcode = async (code, code_derivation) => {
    try {
      setLoadingBarCode(true);
      const res = await DerivationService.getDerivationsByBarcode(code, code_derivation);
      if (res) return res;
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingBarCode(false);
    }
    return null;
  };

  const addDerivationToTable = async (values) => {
    const derivation = await loadDerivationsByBarcode(values?.name?.code, values.code_barcode);
    if (derivation) {
      const derivationsToAdd = {
        name: derivation.name,
        amount: 1,
        code_derivation: derivation.code,
        current_amount: derivation.amount_available_for_sale ?? 0,
        cost_price: derivation.cost_price ? currencyMask(derivation.cost_price) : "",
        disable_cost_price: !!derivation.cost_price,
      };

      setInitialValuesFromApi({
        ...values,
        derivations: [...initialValuesFromApi.derivations, derivationsToAdd],
        code_barcode: "",
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox className={styles.modelHeader}>
          <Grid container>
            <Grid xs={11} sm={12} item>
              <MDTypography align="center">Movimentações</MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Divider sx={{ background: theme.palette.dark.main, m: 0 }} />
        <MDBox className={styles.modelContainer}>
          <Formik
            initialValues={initialValuesFromApi ?? initialValues}
            validationSchema={validations[0]}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, isSubmitting, setFieldValue, handleBlur, errors }) => {
              const {
                name: nameV,
                code_product: code_productV,
                code_barcode: code_barcodeV,

                derivations: derivationsV,
                nf: nfV,
                observation: observationV,
                operation_type: operation_typeV,
              } = values;
              return (
                <Form
                  id="template-form"
                  autoComplete="off"
                  style={{ width: "100%" }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.target.nodeName !== "TEXTAREA") {
                      e.preventDefault();
                      if (submitButtonRef.current) {
                        submitButtonRef.current.click();
                      }
                    }
                  }}
                >
                  <ProductAutocomplete
                    productState={{ productOptions, setProductOptions }}
                    formData={{ values, setFieldValue, handleBlur }}
                    loadDerivations={loadDerivations}
                    setIsSearchByBarCode={setIsSearchByBarCode}
                  />

                  <MDBox mb={2}>
                    <Autocomplete
                      options={
                        userLogged?.role === 0
                          ? movement_operation_type_list
                          : getMovementOperationTypesFromUserPermissions(userLogged?.permissions)
                      }
                      getOptionLabel={(o) => o.type}
                      value={operation_typeV === "" ? null : operation_typeV}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(e, value) => {
                        setFieldValue("operation_type", value);
                      }}
                      renderInput={(params) =>
                        renderInput(
                          "form",
                          params,
                          "operation_type",
                          "Tipo de movimentação *",
                          handleBlur,
                          () => {},
                          "outlined"
                        )
                      }
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <FormFieldMemo
                      name="nf"
                      label="Nota fiscal *"
                      fieldVariant="outlined"
                      type="text"
                      value={nfV}
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <FormField
                      name="observation"
                      label="Observações *"
                      fieldVariant="outlined"
                      type="text"
                      value={observationV}
                      multiline
                      rows={4}
                    />
                  </MDBox>

                  {isSearchByBarCode && (
                    <MDBox mb={2} display="flex" gap={2} alignItems="center">
                      <Grid container spacing={1}>
                        <Grid item xs={10} sm={8}>
                          <FormField
                            name="code_barcode"
                            fieldVariant="outlined"
                            label="Código de barras"
                            type="text"
                            size="small"
                            value={code_barcodeV}
                            onChange={(event) => {
                              const { value } = event.target;
                              const codeNumber = value.replace(/\D/g, "");
                              if (codeNumber.length <= 6) setFieldValue("code_barcode", codeNumber);
                            }}
                            InputProps={adornedInputPropsInput(loadingBarCode)}
                          />
                        </Grid>
                        <Grid item>
                          <MDButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              addDerivationToTable(values);
                              setFieldValue("code_barcode", "");
                            }}
                            ref={submitButtonRef}
                          >
                            <Icon size="medium">search</Icon>
                          </MDButton>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <MDButton
                            color="primary"
                            size="small"
                            fullWidth
                            onClick={() => {
                              setIsSearchByBarCode(false);
                              loadDerivations(values.name.code, setFieldValue);
                            }}
                          >
                            Todas as derivações
                          </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>
                  )}

                  {loadingData && (
                    <MDBox mb={2}>
                      <MDProgress
                        variant="gradient"
                        variantProgress="indeterminate"
                        color="primary"
                      />
                    </MDBox>
                  )}
                  {values.derivations.length > 0 && (
                    <MDBox mb={2} sx={{ border: "1px solid #d2d6da", py: 2, borderRadius: "10px" }}>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        px={1}
                      >
                        <MDTypography variant="body2">Derivações</MDTypography>
                        {!isSearchByBarCode && (
                          <MDButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              setIsSearchByBarCode(true);
                              setInitialValuesFromApi({ ...values, derivations: [] });
                            }}
                          >
                            entrada por código de barras
                          </MDButton>
                        )}
                      </MDBox>
                      <Divider sx={{ background: theme.palette.dark.main, mb: 0 }} />
                      {loadingData ? (
                        <MDProgress
                          variant="gradient"
                          variantProgress="indeterminate"
                          color="primary"
                        />
                      ) : (
                        <MDBox sx={{ maxHeight: "500px", overflowY: "auto" }}>
                          <table className={styles.multipleInputTable}>
                            <thead>
                              <tr>
                                <th>Código</th>
                                <th>Nome</th>
                                <th>Custo de produto:(R$)</th>
                                <th>Quantidade</th>
                                <th>Quantidade atual</th>
                              </tr>
                            </thead>
                            <tbody>
                              <FieldArray
                                name="derivations"
                                render={(arrayHelpers) =>
                                  values.derivations &&
                                  values.derivations.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.code_derivation}</td>
                                      <td>{item.name}</td>
                                      <td>
                                        {item.disable_cost_price ? (
                                          item.cost_price
                                        ) : (
                                          <FormFieldMemo
                                            name={`derivations[${index}].cost_price`}
                                            fieldVariant="outlined"
                                            type="text"
                                            size="small"
                                            onChange={(e, v) => {
                                              const { value } = e.target;
                                              setFieldValue(
                                                `derivations[${index}].cost_price`,
                                                currencyMask(value)
                                              );
                                            }}
                                            styleField={{ marginBottom: 0, height: "100%" }}
                                          />
                                        )}
                                      </td>
                                      <td>
                                        <FormFieldMemo
                                          name={`derivations[${index}].amount`}
                                          fieldVariant="outlined"
                                          type="number"
                                          size="small"
                                          styleField={{ marginBottom: 0, height: "100%" }}
                                        />
                                      </td>
                                      <td className={styles.tdCurrentAmount}>
                                        {item.current_amount}
                                      </td>
                                    </tr>
                                  ))
                                }
                              />
                            </tbody>
                          </table>
                        </MDBox>
                      )}
                    </MDBox>
                  )}
                  {derivationsV.length > 0 && (
                    <MDBox mb={1}>
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="error"
                        align="center"
                        fontWeight="regular"
                        mt={0.75}
                      >
                        {typeof errors.derivations === "string" ? (
                          <ErrorMessage name="derivations" />
                        ) : (
                          <MDBox />
                        )}
                      </MDTypography>
                    </MDBox>
                  )}

                  <MDBox align="center">
                    <MDButton disabled={isSubmitting} type="submit" color="primary" size="medium">
                      salvar
                    </MDButton>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default MovementCreate;
