import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    code: Yup.string().required("Código pai é obrigatório"),
    name: Yup.string().required("Nome é obrigatório"),
    release_date: Yup.date().required("Data é obrigatória"),
    brand: Yup.object().required("Marca é obrigatória").nullable(),
    stores: Yup.array()
      .required("Loja é obrigatória")
      .min(1, "O campo deve ter no mínimo 1 loja selecionada"),
    weight: Yup.string().required("Peso é obrigatório"),
    unit_of_measurement: Yup.object().required("Unidade de medida é obrigatória").nullable(),
    genre: Yup.object().required("Gênero é obrigatório").nullable(),
    collections: Yup.array()
      .required("Coleções é obrigatória")
      .min(1, "O campo deve ter no mínimo 1 coleção selecionada"),
    combos: Yup.array()
      .required("Combos é obrigatório")
      .min(1, "O campo deve ter no mínimo 1 combo selecionado"),
    derivations: Yup.array()
      .of(Yup.object().required("Derivação é obrigatória").nullable())
      .required("Derivação é obrigatória")
      .min(1, "O campo deve ter no mínimo 1 derivação selecionada"),
    categories: Yup.array()
      .of(Yup.object().required("Categoria é obrigatória"))
      .required("Categoria é obrigatória")
      .min(1, "O campo deve ter no mínimo 1 categoria selecionada"),
    ncm: Yup.object().required("NCM é obrigatório").nullable(),
    // cest: Yup.object().required("CEST é obrigatório").nullable(),
    // pis: Yup.object().required("PIS é obrigatório").nullable(),
    // cofins: Yup.object().required("COFINS é obrigatório").nullable(),
    tax_origin: Yup.object().required("Origem fiscal é obrigatória").nullable(),
    supplier_code: Yup.string().required("Código de fornecedor é obrigatório"),
    observations: Yup.string().required("Observações é obrigatório"),
  }),
];

export default validations;
