import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    // old_price: Yup.string().required("Preço antigo é obrigatório"),
    sale_price: Yup.string().required("Preço venda é obrigatório"),
    cost_price: Yup.string().required("Custo de produto é obrigatório"),
  }),
];

export default validations;
