// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_modal__28R0b {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 90%;
  transform: translate(-50%, -50%);
  min-width: 50%;
  max-width: 100%;
  background-color: white !important;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  overflow-y: auto;
  overflow-x: auto;
}

.styles_modelHeader__79Aik {
  padding: 10px 10px 0 10px;
}

.styles_modelContainer__AMtWx {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  width: 100%;
  height: 100%;
}

.styles_modalTable__t7\\+L0 {
  width: 100%;
  overflow-x: auto;
  font-size: 14px;
  th {
    white-space: nowrap;
  }

  th,
  tr,
  td {
    border: 1px solid;
    padding: 5px;
  }

  td {
    font-size: 12px;
  }

  th.styles_subject__Cw17o {
    min-width: 200px;
  }

  div {
    max-height: 100px;
    overflow-y: auto;
  }
}

@media screen and (max-width: 850px) {
  .styles_modal__28R0b {
    min-width: 95%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/derivation/components/replicate_price_modal/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,eAAe;EACf,gCAAgC;EAChC,cAAc;EACd,eAAe;EACf,kCAAkC;EAClC,mBAAmB;EACnB,2CAA2C;EAC3C,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,sBAAsB;EACtB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,YAAY;AACd;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf;IACE,mBAAmB;EACrB;;EAEA;;;IAGE,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".modal {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  max-height: 90%;\n  transform: translate(-50%, -50%);\n  min-width: 50%;\n  max-width: 100%;\n  background-color: white !important;\n  border-radius: 10px;\n  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n  overflow-y: auto;\n  overflow-x: auto;\n}\n\n.modelHeader {\n  padding: 10px 10px 0 10px;\n}\n\n.modelContainer {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n  align-items: center;\n  padding: 15px;\n  width: 100%;\n  height: 100%;\n}\n\n.modalTable {\n  width: 100%;\n  overflow-x: auto;\n  font-size: 14px;\n  th {\n    white-space: nowrap;\n  }\n\n  th,\n  tr,\n  td {\n    border: 1px solid;\n    padding: 5px;\n  }\n\n  td {\n    font-size: 12px;\n  }\n\n  th.subject {\n    min-width: 200px;\n  }\n\n  div {\n    max-height: 100px;\n    overflow-y: auto;\n  }\n}\n\n@media screen and (max-width: 850px) {\n  .modal {\n    min-width: 95%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `styles_modal__28R0b`,
	"modelHeader": `styles_modelHeader__79Aik`,
	"modelContainer": `styles_modelContainer__AMtWx`,
	"modalTable": `styles_modalTable__t7+L0`,
	"subject": `styles_subject__Cw17o`
};
export default ___CSS_LOADER_EXPORT___;
