/* eslint-disable no-unused-vars */
import html2canvas from "html2canvas";
import JSPDF from "jspdf";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import JsBarcode from "jsbarcode";
import convertMMToPT from "shared/utils/converters";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const cloneItemsInArray = (array) => {
  const arrayRepeated = [];
  array.forEach((item) => {
    for (let i = 0; i < item.amount; i++) {
      const clonedItem = item;
      arrayRepeated.push(clonedItem);
    }
  });
  return arrayRepeated;
};

function createSVG(barcodeValue) {
  const svgNamespace = "http://www.w3.org/2000/svg";
  const svg = document.createElementNS(svgNamespace, "svg");

  JsBarcode(svg, barcodeValue, {
    width: 1,
    height: 20,
    margin: 0,
    displayValue: false,
  });

  const svgString = new XMLSerializer().serializeToString(svg);
  return svgString;
}

async function downloadPdfBarCode(dataDerivation, productData) {
  const pageSize = {
    width: convertMMToPT(106),
    height: convertMMToPT(30),
  };

  const pageMargins = [convertMMToPT(3), 0, convertMMToPT(3), 0];

  const info = {
    title: "Relatório de Código de barras",
  };

  const defaultStyle = {
    fontSize: 8,
    alignment: "center",
  };

  const styles = {
    alignL: {
      alignment: "left",
      margin: 0,
      padding: 0,
    },
    alignR: {
      alignment: "right",
    },
    table: {
      width: "100%",
      alignment: "right",
    },
    barcode: {
      fontSize: 8,
    },
    code: {
      bold: true,
    },
    fontColor: {
      fontSize: 6,
    },
  };

  const repeatedArray = cloneItemsInArray(dataDerivation);
  const values = repeatedArray.map((m, index, array) => {
    const svgRes = createSVG(m.barcode);

    let pageBreak;
    const isEven = array.length % 2 === 0;
    if (isEven && index >= array.length - 2) {
      pageBreak = "auto";
    } else if (!isEven && index === array.length - 1) {
      pageBreak = "auto";
    } else {
      pageBreak = "after";
    }

    const colorFormated = (
      m.derivations.find((v) => v.derivation === 1)?.value.replace(/\s+/g, "") ?? ""
    ).substring(0, 9);

    const nameFormated = productData.name.substring(0, 52);

    const res = {
      stack: [
        { text: `Cód: ${productData.code}`, style: "code" },
        { text: nameFormated },
        {
          table: {
            widths: ["*", "*"],
            body: [
              [
                {
                  text: `Cor: ${colorFormated}`,
                  // style: "alignL",
                },
                {
                  text: `Tam: ${m.derivations.find((v) => v.derivation === 2)?.value ?? ""}`,
                  // style: "fontColor",
                },
              ],
            ],
          },
          layout: "noBorders",
        },
        {
          svg: svgRes,
          margin: [0, 0, 0, 0],
        },
        { text: m.barcode, margin: [0, 0, 0, 0], style: "barcode", pageBreak },
      ],
      width: convertMMToPT(50),
      height: convertMMToPT(30),
      margin: [15, 8, 0, 0],
    };
    return res;
  });

  const arrayofArrays = [
    values.filter((f, index) => index % 2 === 0),
    values.filter((f, index) => index % 2 !== 0),
  ];

  const content = [
    {
      table: {
        widths: ["*", "*"],
        body: [arrayofArrays],
      },
      layout: "noBorders",
      margin: [0, 0, 0, 0],
    },
  ];

  const docDefinition = {
    pageSize,
    pageMargins,
    info,
    defaultStyle,
    styles,
    content,
  };
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);

  pdfDocGenerator.download(`cb_${productData.code}`);
}

export default downloadPdfBarCode;
