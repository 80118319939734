/* eslint-disable no-unused-vars */
import { useState } from "react";

import { Autocomplete, Grid } from "@mui/material";
import MDBox from "shared/components/MDBox";
import {
  compareOptionToValue,
  handleValue,
  adornedInputPropsAutocomplete,
  handleNoOptionsAutocomplete,
} from "shared/components/autocomplete/autocomplete_configs";
import FormFieldAutocomplete from "shared/components/form_field/form_field_autocomplete";
import FormFieldMemo from "shared/components/form_field/form_field_memo";
import PropTypes from "prop-types";
import ProductsService from "pages/product/service";
import toastError from "shared/components/snackbar/error/toast_error";

function ProductAutocomplete({ productState, formData, loadDerivations, setIsSearchByBarCode }) {
  const { productOptions, setProductOptions } = productState;
  const { values, setFieldValue, handleBlur } = formData;
  const { name: nameV, code_product: code_productV } = values;

  const [productOptionsPage, setProductOptionsPage] = useState(1);
  const [productOptionsTotalPages, setProductOptionsTotalPages] = useState(1);
  const [productOptionsSearch, setProductOptionsSearch] = useState(1);
  const [loading, setLoading] = useState(false);

  const loadProducts = async (search = "", setField, page = 1, limit = 100) => {
    try {
      setLoading(true);
      setIsSearchByBarCode(false);
      const res = await ProductsService.getAll(page, limit, search);
      if (res) {
        setProductOptions(res.data);
        setProductOptionsPage(res.meta.page);
        setProductOptionsTotalPages(res.meta.total_pages);

        if (search.includes("code=")) {
          setField("name", res.data[0]);
          loadDerivations(res.data[0].code, setFieldValue);
        }
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreProducts = async (search = "", page = 1, limit = 100) => {
    try {
      setLoading(true);
      const res = await ProductsService.getAll(page, limit, search);
      if (res) {
        setProductOptions([...productOptions, ...res.data]);
        setProductOptionsPage(res.meta.page);
        setProductOptionsTotalPages(res.meta.total_pages);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleProductsScroll = (event) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1 && productOptionsPage < productOptionsTotalPages) {
      loadMoreProducts(productOptionsSearch, productOptionsPage + 1);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={2}>
        <FormFieldMemo
          name="code_product"
          label="Código do produto"
          fieldVariant="outlined"
          type="text"
          value={code_productV}
          onBlur={(event) => {
            const { value } = event.target;
            if (value && value.length >= 3) {
              setFieldValue("code_product", value);
              loadProducts(`&code=${value.trim()}`, setFieldValue);
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={10}>
        <Autocomplete
          options={productOptions ?? []}
          noOptionsText={handleNoOptionsAutocomplete(productOptions, "async")}
          getOptionLabel={(o) => `${o?.code} - ${o?.name}`}
          value={handleValue(nameV)}
          isOptionEqualToValue={(o, v) => compareOptionToValue(o, v, "code")}
          onChange={(e, value) => {
            setFieldValue("name", value);
            if (value) loadDerivations(value.code, setFieldValue);
          }}
          renderOption={(props, option) => (
            <MDBox {...props} component="li" key={`${option.code}_${option.name}`}>
              {option.code} - {option.name}
            </MDBox>
          )}
          renderInput={(params) => (
            <FormFieldAutocomplete
              {...params}
              fieldVariant="outlined"
              type="text"
              label="Nome do produto *"
              name="name"
              onChange={(event) => {
                const { value } = event.target;
                if (value && value.length >= 3) {
                  setProductOptionsSearch(`&name=${value.trim()}`);
                  setProductOptionsPage(1);
                  loadProducts(`&name=${value.trim()}`, setFieldValue, productOptionsPage);
                }
              }}
              onBlur={(event) => {
                handleBlur(event);
                if (!event.target.value) setProductOptions([]);
              }}
              InputProps={adornedInputPropsAutocomplete(loading, params)}
            />
          )}
          ListboxProps={{ onScroll: handleProductsScroll }}
        />
      </Grid>
    </Grid>
  );
}

ProductAutocomplete.defaultProps = {
  setIsSearchByBarCode: () => {},
};

ProductAutocomplete.propTypes = {
  productState: PropTypes.objectOf(PropTypes.any).isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
  loadDerivations: PropTypes.func.isRequired,
  setIsSearchByBarCode: PropTypes.func,
};

export default ProductAutocomplete;
