/* eslint-disable no-unused-vars */
import { utils, writeFileXLSX, writeFile } from "xlsx";

export const calculateColumnSize = (array) => {
  const objectMaxLength = [];
  for (let i = 0; i < array.length; i++) {
    const value = Object.values(array[i] === null ? "" : array[i]);
    for (let j = 0; j < value.length; j++) {
      if (typeof value[j] === "number" || typeof value[j] === "string") {
        objectMaxLength[j] =
          objectMaxLength[j] >= value[j].toString().length
            ? objectMaxLength[j]
            : value[j].toString().length + 5;
      } else {
        objectMaxLength[j] = 10;
      }
    }
  }

  const wscols = objectMaxLength.map((header) => {
    const data = {
      width: header,
    };
    return data;
  });
  return wscols;
};

export const calculateColumnArrayOfArraoSize = (arrayOfArrays) => {
  const columnMaxLength = [];

  for (let i = 0; i < arrayOfArrays.length; i++) {
    const currentArray = arrayOfArrays[i];

    for (let j = 0; j < currentArray.length; j++) {
      const value = currentArray[j];

      if (typeof value === "number" || typeof value === "string") {
        columnMaxLength[j] =
          columnMaxLength[j] >= value.toString().length
            ? columnMaxLength[j]
            : value.toString().length + 5;
      } else {
        columnMaxLength[j] = 10;
      }
    }
  }

  const wscols = columnMaxLength.map((header) => {
    const data = {
      width: header,
    };
    return data;
  });

  return wscols;
};

export const exportToXLSXReport = (dataXLSX) => {
  const { fileName } = dataXLSX;

  const columnNames = [
    "Código",
    "Descrição",
    "Estoque",
    "Preço de estoque",
    "Custo de estoque",
    "Preço de venda",
    "Valor total do estoque",
  ];

  const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: columnNames.length - 1 } }];
  const resultSize = calculateColumnArrayOfArraoSize(dataXLSX.data);

  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet(dataXLSX.data, {
    origin: "A3",
    skipHeader: true,
  });

  // footer
  const lastRow = dataXLSX.data.length + 2;
  utils.sheet_set_array_formula(ws, `C${lastRow + 1}`, `SUM(C3:C${lastRow})`);
  utils.sheet_set_array_formula(ws, `D${lastRow + 1}`, `SUM(D3:D${lastRow})`);
  utils.sheet_set_array_formula(ws, `E${lastRow + 1}`, `SUM(E3:E${lastRow})`);
  utils.sheet_set_array_formula(ws, `F${lastRow + 1}`, `SUM(F3:F${lastRow})`);
  utils.sheet_set_array_formula(ws, `G${lastRow + 1}`, `SUM(G3:G${lastRow})`);
  utils.sheet_add_aoa(ws, [["Total:"]], { origin: `A${lastRow + 1}` });

  // Write a title in the primary row
  utils.sheet_add_aoa(ws, [["Relatório de estoque"]]);
  utils.sheet_add_aoa(ws, [columnNames], {
    origin: "A2",
  });

  utils.book_append_sheet(wb, ws, "Relatório de estoque");
  ws["!merges"] = merge;
  ws["!cols"] = resultSize;
  writeFileXLSX(wb, `${fileName}.xlsx`);
};

// ----------------------------------------- movement product report  -------------------------------
// export const exportToXLSXInputProductReport = (dataXLSX, type, productSizes = []) => {
//   const { fileName } = dataXLSX;

//   let columnNames = [];

//   if (type === "colors_sizes") {
//     columnNames = ["Cor", ...productSizes, "Total"];
//   } else if (type === "colors") {
//     columnNames = ["Cor", "Total"];
//   } else if (type === "sizes") {
//     columnNames = [...productSizes, "Total"];
//   }

//   const tableRef = document.getElementById("htmlTable");

//   const wb = utils.table_to_book(tableRef, { origin: "A7" });
//   const firstSheetName = wb.SheetNames[0];
//   const ws = wb.Sheets[firstSheetName];

//   const newItems = [["NovoItem1", "NovoItem2", "NovoItem3"]];
//   utils.sheet_add_aoa(ws, newItems, { origin: "A1" });
//   utils.sheet_add_aoa(ws, newItems, { origin: -1 });

//   writeFileXLSX(wb, `Relatório de estoque.xlsx`);
// };
