import { isAxiosError } from "axios";
import httpService from "../../../shared/services/http";

export default class MovementsService {
  static async create(body) {
    const route = `/movement`;

    try {
      const response = await httpService.post(route, body);

      if (!response) {
        throw new Error("Não foi possível criar movimentação");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 409:
          case 502:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível criar movimentação");
    }
  }

  static async getAll(page, limit, search) {
    const route = `/movement`;
    const pagination = `?page=${page}&limit=${limit}`;
    try {
      const response = await httpService.get(`${route}${pagination}${search}`);

      if (!response) {
        throw new Error("Não foi possível carregar movimentações");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          default:
        }
      }

      throw new Error("Não foi possível carregar movimentações");
    }
  }

  static async getById(id) {
    const route = `/movement/${id}`;

    try {
      const response = await httpService.get(route);

      if (!response) {
        throw new Error("Não foi possível carregar movimentação");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("movimentação não encontrado");

          default:
        }
      }

      throw new Error("Não foi possível carregar movimentação");
    }
  }

  static async update(id, body) {
    const route = `/movement/${id}`;

    try {
      const response = await httpService.patch(route, body);

      if (!response) {
        throw new Error("Não foi possível atualizar movimentação");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("movimentação não encontrado");

          case 409:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível atualizar movimentação");
    }
  }

  static async delete(id) {
    const route = `/movement/${id}`;

    try {
      const response = await httpService.delete(route);

      if (!response) {
        throw new Error("Não foi possível remover movimentação");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("movimentação não encontrado");

          default:
        }
      }

      throw new Error("Não foi possível remover movimentação");
    }
  }

  static async addToStock(code, body) {
    const route = `/stock/add/${code}`;

    try {
      const response = await httpService.post(route, body);

      if (!response) {
        throw new Error("Não foi possível fazer movimentação de estoque");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 403:
            throw new Error("Sem permissão para dar entrada no estoque");

          case 409:
          case 502:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível fazer movimentação de estoque");
    }
  }

  static async removeFromStock(code, body) {
    const route = `/stock/remove/${code}`;

    try {
      const response = await httpService.post(route, body);

      if (!response) {
        throw new Error("Não foi possível fazer movimentação de estoque");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 403:
            throw new Error("Sem permissão para dar saída no estoque");

          case 409:
          case 502:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível fazer movimentação de estoque");
    }
  }

  // report movement get

  static async getAllDataFromMovement(search) {
    const route = `/report/movement`;
    try {
      const response = await httpService.get(`${route}${search}`);

      if (!response) {
        throw new Error("Não foi possível carregar dados");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          default:
        }
      }

      throw new Error("Não foi possível carregar dados");
    }
  }
}
