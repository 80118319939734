/* eslint-disable no-unused-vars */
function iconButton(theme) {
  const { palette } = theme;
  const { primary, light } = palette;

  return {
    background: primary.main,
    color: light.main,
    borderRadius: "10px",
    "&:hover": {
      background: primary.main,
      color: light.main,
    },
  };
}

function test(theme) {
  const { palette } = theme;
  const { primary, light } = palette;

  return {};
}

export { iconButton, test };
