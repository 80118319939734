/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React Base Styles
import colors from "assets/theme/base/colors";

const { info, dark, grey, secondary } = colors;

const globals = {
  html: {
    scrollBehavior: "smooth",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  // scroll
  "::-webkit-scrollbar": {
    width: "6px",
    height: "6px",
    background: "#ddd",
  },
  "::-webkit-scrollbar-track": {
    background: "transparent",
    // boxShadow: "inset 0 0 5px grey",
    borderRadius: "10px",
  },
  "::-webkit-scrollbar-thumb": {
    background: grey[700],
    borderRadius: "10px",
  },
  "::-webkit-scrollbar-thumb:hover": {
    background: grey[800],
  },
  "::-moz-selection": {
    color: "white",
    background: secondary.main,
  },
};

export default globals;
