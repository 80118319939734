/* eslint-disable no-nested-ternary */

export const arrangeAlphabeticalOrder = (array, param = "name") =>
  array?.sort((a, b) => (a[param] > b[param] ? 1 : b[param] > a[param] ? -1 : 0));

export const convertData = (date) => {
  if (!date) return "";
  const entryDate = new Date(date);

  const day = entryDate.getUTCDate();
  const month = entryDate.getUTCMonth() + 1;
  const year = entryDate.getUTCFullYear();

  const formatedDate = `${day < 10 ? "0" : ""}${day}/${month < 10 ? "0" : ""}${month}/${year}`;

  return formatedDate;
};
