import { isAxiosError } from "axios";
import httpService from "../../../shared/services/http";

export default class ForgotPasswordService {
  static async sendEmail(email) {
    const route = `/forgot-password/${email}`;

    try {
      const response = await httpService.get(route);

      if (!response) {
        throw new Error("Não foi possível enviar e-mail");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("Usuário não encontrado");

          case 502:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível enviar e-mail");
    }
  }
}
