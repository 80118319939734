/* eslint-disable no-unused-vars */
import { utils, writeFileXLSX } from "xlsx";
import { calculateColumnArrayOfArraoSize } from "shared/utils/report_utils";

export const dataXLSX = [
  {
    code: "010101",
    name: "Teste API",
    weight: "10.000",
    brand: 101,
    unit_of_measurement: "PC",
    active: true,
    ncm: "1022190",
    cest: "1100500",
    tax_origin: 0,
    release_date: "2023-12-12T00:00:00-03:00",
    categories: [47],
    amount_available_for_sale: 45,
    cost_price: "20.00",
    sale_price: "25.00",
    observations:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse et fermentum magna. Fusce nec ante enim. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In lacinia diam eget massa ultricies, non tincidunt lacus molestie. Nullam pharetra mattis ex, nec egestas sapien efficitur vel.",
  },
  {
    code: "010441",
    name: "Teste API",
    weight: "10.000",
    brand: 101,
    unit_of_measurement: "PC",
    active: true,
    ncm: "1022190",
    cest: "1100500",
    tax_origin: 0,
    release_date: "2023-12-12T00:00:00-03:00",
    categories: [47],
    amount_available_for_sale: 15,
    cost_price: "50.00",
    sale_price: "75.50",
    observations: "Lorem ipsum dolor sit amet",
  },
  {
    code: "213441",
    name: "Teste API",
    weight: "10.000",
    brand: 101,
    unit_of_measurement: "PC",
    active: true,
    ncm: "1022190",
    cest: "1100500",
    tax_origin: 0,
    release_date: "2023-12-12T00:00:00-03:00",
    categories: [47],
    amount_available_for_sale: 150,
    cost_price: "5.00",
    sale_price: "15.50",
    observations: "Lorem ipsum dolor sit amet",
  },
  {
    code: "1234567",
    name: "Teste API",
    weight: "10.000",
    brand: 101,
    unit_of_measurement: "PC",
    active: true,
    ncm: "1022190",
    cest: "1100500",
    tax_origin: 0,
    release_date: "2023-12-12T00:00:00-03:00",
    categories: [47],
    amount_available_for_sale: 45,
    cost_price: "24.00",
    sale_price: "32.00",
    observations:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse et fermentum magna. Fusce nec ante enim. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. In lacinia diam eget massa ultricies, non tincidunt lacus molestie. Nullam pharetra mattis ex, nec egestas sapien efficitur vel.",
  },
];

export const exportToExcel = (dataStock) => {
  // const { fileName } = dataStock;

  const table = document.getElementById("stockTable");

  const columnNames = [
    "Código",
    "Descrição",
    "Estoque",
    "Preço de custo",
    "Custo de estoque",
    "Preço de venda",
    "Valor total do estoque",
  ];

  const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: columnNames.length - 1 } }];

  const allData = [];
  dataStock.forEach((t, index) =>
    allData.push([
      t.code,
      t.observations,
      t.amount_available_for_sale,
      Number(t.cost_price),
      Number(t.cost_price) * t.amount_available_for_sale,
      Number(t.sale_price),
      Number(t.sale_price) * t.amount_available_for_sale,
    ])
  );
  // const resultSize = calculateColumnArrayOfArraoSize(allData);

  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet(allData, {
    origin: "A3",
    skipHeader: true,
  });

  // footer
  const lastRow = dataStock.length + 2;
  utils.sheet_set_array_formula(ws, `C${lastRow + 1}`, `SUM(C3:C${lastRow})`);
  utils.sheet_set_array_formula(ws, `D${lastRow + 1}`, `SUM(D3:D${lastRow})`);
  utils.sheet_set_array_formula(ws, `E${lastRow + 1}`, `SUM(E3:E${lastRow})`);
  utils.sheet_set_array_formula(ws, `F${lastRow + 1}`, `SUM(F3:F${lastRow})`);
  utils.sheet_set_array_formula(ws, `G${lastRow + 1}`, `SUM(G3:G${lastRow})`);
  utils.sheet_add_aoa(ws, [["Total:"]], { origin: `A${lastRow + 1}` });

  // Write a title in the primary row
  utils.sheet_add_aoa(ws, [["Relatório de estoque"]]);
  utils.sheet_add_aoa(ws, [columnNames], {
    origin: "A2",
  });

  utils.book_append_sheet(wb, ws, "Relatório de estoque");
  ws["!merges"] = merge;
  // ws["!cols"] = resultSize;
  writeFileXLSX(wb, `Relatório de estoque.xlsx`);
};
