import { isAxiosError } from "axios";
import httpService from "../../../shared/services/http";

export default class ProductsService {
  static async create(body) {
    const route = `/product`;

    try {
      const response = await httpService.post(route, body);

      if (!response) {
        throw new Error("Não foi possível criar produto");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 409:
          case 502:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível criar produto");
    }
  }

  static async getAll(page, limit, search) {
    const route = `/product`;
    const pagination = `?page=${page}&limit=${limit}`;
    try {
      const response = await httpService.get(`${route}${pagination}${search}`);

      if (!response) {
        throw new Error("Não foi possível carregar produtos");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          default:
        }
      }

      throw new Error("Não foi possível carregar produtos");
    }
  }

  static async getById(id) {
    const route = `/product/${id}`;

    try {
      const response = await httpService.get(route);

      if (!response) {
        throw new Error("Não foi possível carregar produto");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("produto não encontrado");

          default:
        }
      }

      throw new Error("Não foi possível carregar produto");
    }
  }

  static async update(id, body) {
    const route = `/product/${id}`;

    try {
      const response = await httpService.patch(route, body);

      if (!response) {
        throw new Error("Não foi possível atualizar produto");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("produto não encontrado");

          case 409:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível atualizar produto");
    }
  }

  static async delete(id) {
    const route = `/product/${id}`;

    try {
      const response = await httpService.delete(route);

      if (!response) {
        throw new Error("Não foi possível remover produto");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("produto não encontrado");

          default:
        }
      }

      throw new Error("Não foi possível remover produto");
    }
  }

  static async getProductFieldsList() {
    try {
      const route = `/product/fields`;

      const response = await httpService.get(route);

      if (!response) {
        throw new Error("Não foi possível carregar a lista de campos do produto");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("Listas de produto derivação não encontrada");

          default:
        }
      }

      throw new Error("Não foi possível carregar listas de produto derivação");
    }
  }

  static async whoAmI() {
    const route = `/who-am-i`;

    try {
      const response = await httpService.get(route);

      if (!response) {
        throw new Error("Não foi possível carregar informações");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("produto não encontrado");

          default:
        }
      }

      throw new Error("Não foi possível carregar informações");
    }
  }

  static async getProductNCM(page, limit, filter) {
    try {
      const route = `/product/fields/ncm`;
      const pagination = `?page=${page}&limit=${limit}`;
      const response = await httpService.get(`${route}${pagination}${filter}`);

      if (!response) throw new Error("Não foi possível carregar a lista de NCM");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        switch (status) {
          case 404:
            throw new Error("Listas de NCM não encontrada");
          default:
            throw new Error("Não foi possível carregar a lista de NCM");
        }
      }
      throw new Error("Não foi possível carregar lista de NCM");
    }
  }

  static async getProductCEST(page, limit, filter) {
    try {
      const route = `/product/fields/cest`;
      const pagination = `?page=${page}&limit=${limit}`;
      const response = await httpService.get(`${route}${pagination}${filter}`);

      if (!response) throw new Error("Não foi possível carregar a lista de CEST");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        switch (status) {
          case 404:
            throw new Error("Listas de CEST não encontrada");
          default:
            throw new Error("Não foi possível carregar a lista de CEST");
        }
      }
      throw new Error("Não foi possível carregar lista de CEST");
    }
  }

  static async getProductPIS(page, limit, filter) {
    try {
      const route = `/product/fields/pis`;
      const pagination = `?page=${page}&limit=${limit}`;
      const response = await httpService.get(`${route}${pagination}${filter}`);

      if (!response) throw new Error("Não foi possível carregar a lista de PIS");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        switch (status) {
          case 404:
            throw new Error("Listas de PIS não encontrada");
          default:
            throw new Error("Não foi possível carregar a lista de PIS");
        }
      }
      throw new Error("Não foi possível carregar lista de PIS");
    }
  }

  static async getProductCOFINS(page, limit, filter) {
    try {
      const route = `/product/fields/cofins`;
      const pagination = `?page=${page}&limit=${limit}`;
      const response = await httpService.get(`${route}${pagination}${filter}`);

      if (!response) throw new Error("Não foi possível carregar a lista de COFINS");

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        switch (status) {
          case 404:
            throw new Error("Listas de COFINS não encontrada");
          default:
            throw new Error("Não foi possível carregar a lista de COFINS");
        }
      }
      throw new Error("Não foi possível carregar lista de COFINS");
    }
  }
}
