/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */

import PropTypes from "prop-types";
import MDBox from "shared/components/MDBox";

import styles from "./styles.module.css";

function ReportDerivationUnique({ productReport }) {
  const { derivations } = productReport;

  const calculateTotal = (unique) =>
    derivations.reduce((total, item) => {
      if (item.unique === unique) {
        return total + item.amount;
      }
      return total;
    }, 0);

  return (
    <MDBox className={styles.modalTable}>
      <table id="htmlTable">
        <thead>
          <tr>
            <th>Único</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {derivations.length > 0 &&
            derivations.map((item, index) => (
              <tr key={`${item}-${index}`}>
                <td>{item.unique}</td>
                <td>{calculateTotal(item.unique)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </MDBox>
  );
}

ReportDerivationUnique.propTypes = {
  productReport: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ReportDerivationUnique;
