/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-else-return */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import { CircularProgress, Divider, Grid, Icon, IconButton, Modal, useTheme } from "@mui/material";
import MDTypography from "shared/components/MDTypography";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";

import FormField from "shared/components/form_field";
import { Form, Formik } from "formik";
import { currencyMask } from "shared/utils/masks";
import toastError from "shared/components/snackbar/error/toast_error";
import toastSuccess from "shared/components/snackbar/success/toast_success";
import DerivationService from "pages/derivation/service";

import styles from "./styles.module.css";
import initialValues from "./schemas/initial_values";
import validations from "./schemas/validations";

function ReplicatePriceModal({ open, onClose, loadList }) {
  const { code } = useParams();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const handleMask = (value) => {
    let withMask = value;
    withMask = withMask.replace(".", "");
    withMask = withMask.replace(",", ".");
    return Number(withMask);
  };

  const handleSubmit = async (values, actions) => {
    try {
      setLoading(true);
      const data = {
        old_price: handleMask(values.old_price),
        sale_price: handleMask(values.sale_price),
        cost_price: handleMask(values.cost_price),
      };
      const res = await DerivationService.replicatePrices(code, data);
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess("Preços de replicados com sucesso");
      onClose();
      loadList();
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <MDBox className={styles.modal}>
        <MDBox className={styles.modelHeader}>
          <Grid container>
            <Grid xs={11} sm={12} item>
              <MDTypography align="center">Replicar preços em derivações</MDTypography>
            </Grid>
            <Grid item xs={1} sm={1} position="absolute" style={{ right: 5, top: 5 }}>
              <MDBox display="flex" justifyContent="flex-end">
                <IconButton size="small" onClick={onClose}>
                  <Icon fontSize="small" align="right">
                    close
                  </Icon>
                </IconButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Divider sx={{ background: theme.palette.dark.main, m: 0 }} />
        <MDBox className={styles.modelContainer}>
          <Formik
            initialValues={initialValues}
            validationSchema={validations[0]}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue, handleBlur }) => {
              const {
                old_price: old_priceV,
                sale_price: sale_priceV,
                cost_price: cost_priceV,
              } = values;

              return (
                <Form id="user-create-form" autoComplete="off" style={{ width: "100%" }}>
                  <MDBox px={3}>
                    <FormField
                      name="cost_price"
                      label="Custo de produto(R$) *"
                      type="text"
                      value={cost_priceV}
                      onChange={(e, v) => {
                        const { value } = e.target;
                        setFieldValue("cost_price", currencyMask(value));
                      }}
                      error={errors.cost_price && touched.cost_price}
                      success={cost_priceV.length > 0 && !errors.cost_price}
                    />
                  </MDBox>

                  <MDBox px={3}>
                    <FormField
                      name="old_price"
                      label="Preço antigo(R$)"
                      type="text"
                      value={old_priceV}
                      onChange={(e, v) => {
                        const { value } = e.target;
                        setFieldValue("old_price", currencyMask(value));
                      }}
                      error={errors.old_price && touched.old_price}
                      success={old_priceV.length > 0 && !errors.old_price}
                    />
                  </MDBox>

                  <MDBox px={3}>
                    <FormField
                      name="sale_price"
                      label="Preço venda(R$) *"
                      type="text"
                      value={sale_priceV}
                      onChange={(e, v) => {
                        const { value } = e.target;
                        setFieldValue("sale_price", currencyMask(value));
                      }}
                      error={errors.sale_price && touched.sale_price}
                      success={sale_priceV.length > 0 && !errors.sale_price}
                    />
                  </MDBox>

                  <MDBox display="flex" gap="5px" py={2} px={3} ml="auto">
                    <MDButton
                      disabled={isSubmitting}
                      color="primary"
                      type="submit"
                      size="large"
                      fullWidth
                    >
                      {loading ? <CircularProgress color="inherit" size={20} /> : "SALVAR"}
                    </MDButton>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

ReplicatePriceModal.defaultProps = {
  onClose: null,
  loadList: () => {},
};

ReplicatePriceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  loadList: PropTypes.func,
};

export default ReplicatePriceModal;
