import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    name: Yup.object().required("O nome do produto é obrigatório").nullable(),
    derivations: Yup.array()
      .of(
        Yup.object().shape({
          amount: Yup.number().min(0, "Valor deve ser positivo"),
        })
      )
      .test(
        "amount",
        "Necessário que alguma quantidade tenha sido preenchida",
        (values) => values.reduce((acc, curr) => acc + (curr.amount || 0), 0) >= 1
      ),
    code_barcode: Yup.string()
      .min(6, "Código deve ser ao menos 6 caracteres")
      .max(6, "Código deve ter no máximo 6 caracteres"),
  }),
];

export default validations;
