/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";

import { Card, Divider, Grid, Icon, useTheme } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "shared/components/MDTypography";
import MDInput from "shared/components/MDInput";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDProgress from "shared/components/MDProgress";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import FormField from "shared/components/form_field";
import FormFieldMemo from "shared/components/form_field/form_field_memo";

import toastSuccess from "shared/components/snackbar/success/toast_success";
import toastError from "shared/components/snackbar/error/toast_error";
import adornedInputPropsInput from "shared/components/input/input_configs";
import { arrangeAlphabeticalOrder } from "shared/utils";

import DerivationService from "pages/derivation/service";
import CircularIndeterminate from "shared/components/loader/circularIndeterminate";
import styles from "./styles.module.css";

import initialValues from "./schemas/initial_values";
import validations from "./schemas/validations";
import ProductAutocomplete from "./components/product_autocomplete";
import downloadPdfBarCode from "./components/pdf_bar_code_tags";

function ReportBarcode() {
  const theme = useTheme();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  // product
  const [productOptions, setProductOptions] = useState([]);
  const [productOptionsSearch, setProductOptionsSearch] = useState(1);

  const [loadingData, setLoadingData] = useState(false);
  const [derivationOptionsPage, setDerivationOptionsPage] = useState(1);
  const [derivationOptionsTotalPages, setDerivationOptionsTotalPages] = useState(1);
  const [derivationsOptions, setDerivationsOptions] = useState();

  // search bar code
  const [isSearchByBarCode, setIsSearchByBarCode] = useState(false);
  const [derivationsSearchOptions, setDerivationsSearchOptions] = useState();
  const [loadingMoreDerivation, setLoadingMoreDerivation] = useState(false);

  const handlerDerivationsSubmit = (derivations = []) => {
    const res =
      derivations
        .filter((v) => v.amount > 0)
        .map((v) => {
          const mapRes = { ...v, code_derivation: v.code_derivation, amount: v.amount };
          return mapRes;
        }) || [];
    return res;
  };

  const handleSubmit = async (values, actions) => {
    const data = values.name;
    const stock = values.derivations.length > 0 ? handlerDerivationsSubmit(values.derivations) : [];

    downloadPdfBarCode(stock, data);
  };

  const loadDerivations = async (code, setFieldValue, search = "", page = 1, limit = 100) => {
    try {
      setLoadingData(true);
      setFieldValue("derivations", []);
      const res = await DerivationService.getAll(code, page, limit, search);
      if (res) {
        setDerivationsSearchOptions(res.data);
        setDerivationOptionsPage(res.meta.page);
        setDerivationOptionsTotalPages(res.meta.total_pages);
        setFieldValue(
          "derivations",
          arrangeAlphabeticalOrder(
            res?.data.map((v) => {
              const derivRes = {
                ...v,
                amount: 0,
              };
              return derivRes;
            }) ?? []
          )
        );
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const loadMoreDerivations = async (values, setFieldValue, search = "", page = 1, limit = 100) => {
    try {
      setLoadingMoreDerivation(true);
      const res = await DerivationService.getAll(values.name.code, page, limit, search);
      if (res) {
        const mapRes =
          res?.data.map((v) => {
            const derivRes = {
              ...v,
              amount: 0,
            };
            return derivRes;
          }) ?? [];
        setFieldValue("derivations", arrangeAlphabeticalOrder([...values.derivations, ...mapRes]));
        setDerivationsSearchOptions([...derivationsSearchOptions, ...res.data]);
        setDerivationOptionsPage(res.meta.page);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingMoreDerivation(false);
    }
  };

  const handleScroll = (event, values, setField) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (
      listboxNode.scrollHeight - position <= 1 &&
      derivationOptionsPage < derivationOptionsTotalPages
    ) {
      loadMoreDerivations(values, setField, "", derivationOptionsPage + 1);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <MDBox className={styles.modelHeader}>
          <Grid container>
            <Grid xs={11} sm={12} item>
              <MDTypography align="center">Relatório de código de barras</MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <Divider sx={{ background: theme.palette.dark.main, m: 0 }} />
        <MDBox className={styles.modelContainer}>
          <Formik
            initialValues={initialValuesFromApi ?? initialValues}
            validationSchema={validations[0]}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, isSubmitting, setFieldValue, handleBlur, errors }) => {
              const { derivations: derivationsV } = values;
              return (
                <Form id="template-form" autoComplete="off" style={{ width: "100%" }}>
                  <ProductAutocomplete
                    productState={{ productOptions, setProductOptions }}
                    formData={{ values, setFieldValue, handleBlur }}
                    loadDerivations={loadDerivations}
                    setIsSearchByBarCode={setIsSearchByBarCode}
                  />

                  {loadingData && (
                    <MDBox mb={2}>
                      <MDProgress
                        variant="gradient"
                        variantProgress="indeterminate"
                        color="primary"
                      />
                    </MDBox>
                  )}
                  {values.derivations.length > 0 && (
                    <MDBox sx={{ border: "1px solid #d2d6da", py: 2, borderRadius: "10px" }}>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        px={1}
                      >
                        <MDTypography variant="body2">Derivações</MDTypography>
                      </MDBox>
                      <Divider sx={{ background: theme.palette.dark.main, mb: 0 }} />
                      {loadingData ? (
                        <MDProgress
                          variant="gradient"
                          variantProgress="indeterminate"
                          color="primary"
                        />
                      ) : (
                        <MDBox
                          sx={{ maxHeight: "300px", overflowY: "auto" }}
                          onScroll={(e) => handleScroll(e, values, setFieldValue)}
                        >
                          <table className={styles.multipleInputTable}>
                            <thead>
                              <tr>
                                <th>Código</th>
                                <th>Nome</th>
                                <th>Cor</th>
                                <th>Tamanho</th>
                                <th>Quantidade da etiqueta</th>
                              </tr>
                            </thead>
                            <tbody>
                              <FieldArray
                                name="derivations"
                                render={(arrayHelpers) =>
                                  values.derivations &&
                                  values.derivations.map((item, index) => (
                                    <tr key={index}>
                                      <td>{item.code}</td>
                                      <td>{item.name}</td>
                                      <td>
                                        {item.derivations.find((v) => v.derivation === 1)?.value ??
                                          " - "}
                                      </td>
                                      <td>
                                        {item.derivations.find((v) => v.derivation === 2)?.value ??
                                          " - "}
                                      </td>
                                      <td style={{ width: "20%" }}>
                                        <FormFieldMemo
                                          name={`derivations[${index}].amount`}
                                          fieldVariant="outlined"
                                          type="number"
                                          size="small"
                                          styleField={{ marginBottom: 0, height: "100%" }}
                                        />
                                      </td>
                                    </tr>
                                  ))
                                }
                              />
                            </tbody>
                          </table>
                          {loadingMoreDerivation && (
                            <MDBox
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              my={2}
                            >
                              <CircularIndeterminate />
                            </MDBox>
                          )}
                        </MDBox>
                      )}
                    </MDBox>
                  )}
                  {derivationsV.length > 0 && (
                    <MDBox mb={1}>
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="error"
                        align="center"
                        fontWeight="regular"
                        mt={0.75}
                      >
                        {typeof errors.derivations === "string" ? (
                          <ErrorMessage name="derivations" />
                        ) : (
                          <MDBox />
                        )}
                      </MDTypography>
                    </MDBox>
                  )}

                  <MDBox align="center">
                    <MDButton disabled={isSubmitting} type="submit" color="primary" size="medium">
                      gerar relatório
                    </MDButton>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default ReportBarcode;
