import { Autocomplete, Box, Card, createFilterOptions } from "@mui/material";
import { useAuthContext } from "context/auth_context";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDTypography from "shared/components/MDTypography";
import {
  handleValue,
  renderInput,
  setValue,
  compareOptionToValue,
} from "shared/components/autocomplete/autocomplete_configs";
import FormField from "shared/components/form_field";
import toastError from "shared/components/snackbar/error/toast_error";
import toastSuccess from "shared/components/snackbar/success/toast_success";
import parseRoleNumberToString from "shared/utils/parse_role_number_to_string";
import { user_permissions_list } from "shared/utils/select_lists";
import UserService from "../service";
import initialValues from "./schemas/initial_values";
import valifations from "./schemas/validations";

function UserEdit() {
  const { updateToken, userLogged } = useAuthContext();
  const { id } = useParams();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  const navigate = useNavigate();

  const filterOptionsRole = createFilterOptions({
    stringify: parseRoleNumberToString,
  });

  const handleSubmit = async (values, actions) => {
    try {
      const { role, permissions, name, email } = values;
      const permissionSlugs = permissions.map((permission) => permission.slug);
      await UserService.update(id, role, permissionSlugs, name, email);

      if (userLogged.id === id) {
        const me = await UserService.whoAmI();

        updateToken(me.access_token);
      }

      actions.setTouched({});
      actions.setSubmitting(false);

      toastSuccess("Usuário editado com sucesso");

      navigate("/usuarios");
    } catch (e) {
      toastError(e.message);
    }
  };

  const loadUser = async () => {
    try {
      const user = await UserService.getById(id);
      const filteredPermissions = user_permissions_list.filter((userPermission) =>
        user.permissions.includes(userPermission.slug)
      );

      Object.assign(user, { permissions: filteredPermissions });

      setInitialValuesFromApi(user);
    } catch (e) {
      toastError(e.message);
    }
  };

  useEffect(() => {
    loadUser();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValuesFromApi ?? initialValues}
        validationSchema={valifations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, isSubmitting, setFieldValue, handleBlur }) => {
          const { role: roleV, permissions: permissionsV, name: nameV, email: emailV } = values;

          return (
            <Form id="user-edit-form" autoComplete="off">
              <Card id="basic-info" sx={{ overflow: "visible" }}>
                <MDBox p={3}>
                  <MDTypography variant="h5">Editar Usuário</MDTypography>
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    options={[0, 1]}
                    getOptionLabel={parseRoleNumberToString}
                    value={handleValue(roleV)}
                    onChange={(_, value) => setValue(setFieldValue, "role", value)}
                    filterOptions={filterOptionsRole}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {parseRoleNumberToString(option)}
                      </Box>
                    )}
                    renderInput={(params) =>
                      renderInput("form", params, "role", "Perfil *", handleBlur)
                    }
                  />
                </MDBox>

                {roleV && roleV !== 0 ? (
                  <MDBox px={3}>
                    <Autocomplete
                      multiple
                      options={user_permissions_list}
                      value={permissionsV || []}
                      getOptionLabel={(o) => o.type}
                      isOptionEqualToValue={compareOptionToValue}
                      onChange={(e, value) => setValue(setFieldValue, "permissions", value)}
                      renderInput={(params) =>
                        renderInput("form", params, "permissions", "Permissões", handleBlur)
                      }
                    />
                  </MDBox>
                ) : (
                  <MDBox />
                )}

                <MDBox px={3}>
                  <FormField
                    name="name"
                    label="Nome completo *"
                    type="text"
                    value={nameV}
                    error={errors.name && touched.name}
                    success={nameV.length > 0 && !errors.name}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="email"
                    label="Email *"
                    type="email"
                    value={emailV}
                    error={errors.email && touched.email}
                    success={emailV.length > 0 && !errors.email}
                  />
                </MDBox>

                <MDBox py={3} px={3} width="12rem" ml="auto">
                  <MDButton
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                    size="large"
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default UserEdit;
