/* eslint-disable no-unused-vars */
import { utils, writeFileXLSX } from "xlsx";

export const exportToExcel = (dataStock) => {
  const columnNames = [
    "Código",
    "Descrição",
    "Estoque",
    "Preço de custo",
    "Custo de estoque",
    "Preço de venda",
    "Valor total do estoque",
  ];

  const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: columnNames.length - 1 } }];

  const allData = [];
  dataStock.forEach((t) =>
    allData.push([
      t.code,
      t.description,
      t.stock,
      t.cost_price ? Number(t.cost_price) : 0,
      t.cost_of_stock ? Number(t.cost_of_stock) : 0,
      t.sale_price ? Number(t.sale_price) : 0,
      t.total_value_of_stock ? Number(t.total_value_of_stock) : 0,
    ])
  );
  const wb = utils.book_new();
  const ws = utils.aoa_to_sheet(allData, {
    origin: "A3",
    skipHeader: true,
  });

  // footer
  const lastRow = dataStock.length + 2;
  utils.sheet_set_array_formula(ws, `C${lastRow + 1}`, `SUM(C3:C${lastRow})`);
  utils.sheet_set_array_formula(ws, `D${lastRow + 1}`, `SUM(D3:D${lastRow})`);
  utils.sheet_set_array_formula(ws, `E${lastRow + 1}`, `SUM(E3:E${lastRow})`);
  utils.sheet_set_array_formula(ws, `F${lastRow + 1}`, `SUM(F3:F${lastRow})`);
  utils.sheet_set_array_formula(ws, `G${lastRow + 1}`, `SUM(G3:G${lastRow})`);
  utils.sheet_add_aoa(ws, [["Total:"]], { origin: `A${lastRow + 1}` });

  // Write a title in the primary row
  utils.sheet_add_aoa(ws, [["Relatório de estoque"]]);
  utils.sheet_add_aoa(ws, [columnNames], {
    origin: "A2",
  });

  utils.book_append_sheet(wb, ws, "Relatório de estoque");
  ws["!merges"] = merge;
  // ws["!cols"] = resultSize;
  writeFileXLSX(wb, `Relatório de estoque.xlsx`);
};

export default exportToExcel;
