import { isAxiosError } from "axios";
import httpService from "../../../shared/services/http";

export default class ChangePasswordService {
  static async changePassword(token, password, confirmPassword) {
    const route = `/change-password/${token}`;

    try {
      const response = await httpService.patch(
        route,
        {},
        {
          auth: {
            username: password,
            password: confirmPassword,
          },
        }
      );

      if (!response) {
        throw new Error("Não foi possível trocar a senha");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 400:
          case 401:
          case 410:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível trocar a senha");
    }
  }
}
