/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */

import { useEffect, useState } from "react";
import { Autocomplete, Box, Card, Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDTypography from "shared/components/MDTypography";
import {
  handleValue,
  renderInput,
  setValue,
} from "shared/components/autocomplete/autocomplete_configs";
import FormField from "shared/components/form_field";
import toastError from "shared/components/snackbar/error/toast_error";
import toastSuccess from "shared/components/snackbar/success/toast_success";
import { weightMask } from "shared/utils/masks";
import {
  tax_origin_list,
  NCM_list,
  CEST_list,
  PIS_list,
  COFINS_list,
} from "shared/utils/select_lists";

import initialValues from "./schemas/initial_values";
import validations from "./schemas/validations";

import FieldArrayProductsFieldsList from "../components/field_array_products_fields_list";
import FieldArrayProductsCategories from "../components/field_array_products_categories";
import ProductsService from "../service";

import ProductNCMAutocomplete from "../components/product_NCM";
import ProductCESTAutocomplete from "../components/product_CEST";
import ProductPISAutocomplete from "../components/product_PIS";
import ProductCOFINSAutocomplete from "../components/product_COFINS";

function ProductCreate() {
  const navigate = useNavigate();

  const [brands, setBrands] = useState([]);
  const [stores, setStores] = useState([]);
  const [unitsOfMeasurement, setUnitsOfMeasurement] = useState([]);
  const [derivationTypes, setDerivationTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [genre, setGenre] = useState([]);
  const [collection, setCollection] = useState([]);
  const [combos, setCombos] = useState([]);

  const handleMask = (value) => {
    let withMask = value;
    withMask = withMask.replace(".", "");
    withMask = withMask.replace(",", ".");
    return Number(withMask);
  };

  const handleArraySubmit = (array, field = "id") => {
    const res = array.map((v) => v[field]);
    return res;
  };

  const handleDateSubmit = (date) => {
    const isoString = `${date}T00:00:00Z`;
    return isoString;
  };

  const handleSubmit = async (values, actions) => {
    try {
      const data = {
        code: values.code,
        name: values.name,
        release_date: handleDateSubmit(values.release_date),
        brand: values.brand ? values.brand?.id : "",
        stores: handleArraySubmit(values.stores),
        weight: handleMask(values.weight),
        unit_of_measurement: values.unit_of_measurement.acronym,
        genre: values.genre.name,
        collections: handleArraySubmit(values.collections, "name"),
        combos: handleArraySubmit(values.combos, "name"),
        derivations: handleArraySubmit(values.derivations),
        categories: handleArraySubmit(values.categories),
        ncm: values.ncm?.code,
        cest: values.cest?.code,
        pis: values.pis?.code,
        cofins: values.cofins?.code,
        tax_origin: values.tax_origin.id,
        supplier_code: values.supplier_code,
        observations: values.observations,
      };
      if (!data.cest || !data.cest.trim() === "") delete data.cest;
      if (!data.pis || !data.pis.trim() === "") delete data.pis;
      if (!data.cofins || !data.cofins.trim() === "") delete data.cofins;

      await ProductsService.create(data);
      toastSuccess("Produto criado com sucesso");
      actions.setTouched({});
      actions.setSubmitting(false);
      navigate("/produtos");
    } catch (e) {
      toastError(e.message);
    }
  };

  function findItemCharacteristc(array, itemSelected) {
    const res = array.characteristics.find((v) => v.name === itemSelected).list || null;

    return res;
  }

  const loadProductDerivationLists = async () => {
    try {
      const derivationListData = await ProductsService.getProductFieldsList();
      if (derivationListData) {
        setBrands(derivationListData.brands);
        setStores(derivationListData.stores);
        setUnitsOfMeasurement(derivationListData.units_of_measurement);
        setDerivationTypes(derivationListData.derivation_types);
        setCategories(derivationListData.categories);
        // setCharacteristics(derivationListData.characteristics);
        setGenre(findItemCharacteristc(derivationListData, "Gênero"));
        setCollection(findItemCharacteristc(derivationListData, "Coleção"));
        setCombos(findItemCharacteristc(derivationListData, "Combos"));
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  useEffect(() => {
    loadProductDerivationLists();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValues}
        validationSchema={validations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, isSubmitting, setFieldValue, handleBlur }) => {
          const {
            code: codeV,
            brand: brandV,
            release_date: release_dateV,
            name: nameV,
            weight: weightV,
            unit_of_measurement: unit_of_measurementV,
            derivations: derivationsV,
            categories: categoriesV,
            ncm: ncmV,
            cest: cestV,
            pis: pisV,
            cofins: cofinsV,
            tax_origin: tax_originV,
            stores: storesV,

            genre: genreV,
            collections: collectionsV,
            combos: combosV,

            supplier_code: supplier_codeV,
            observations: observationsV,
          } = values;

          return (
            <Form id="user-create-form" autoComplete="off">
              <Card id="basic-info" sx={{ overflow: "visible" }}>
                <MDBox p={3}>
                  <MDTypography variant="h5">Novo Produto</MDTypography>
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="code"
                    label="Código pai *"
                    type="text"
                    value={codeV}
                    onChange={(e) => {
                      let { value } = e.target;
                      if (value.includes(" ")) value = value.replace(" ", "-");
                      setFieldValue("code", value);
                    }}
                    error={errors.code && touched.code}
                    success={codeV.length > 0 && !errors.code}
                  />
                </MDBox>

                <Grid px={3} container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <Autocomplete
                      options={brands}
                      value={handleValue(brandV)}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(e, value) => setValue(setFieldValue, "brand", value)}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.id}>
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) =>
                        renderInput("form", params, "brand", "Marca *", handleBlur)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      fieldShrink
                      name="release_date"
                      label="Lançamento Pai *"
                      type="date"
                      value={release_dateV}
                      error={errors.release_date && touched.release_date}
                      success={release_dateV.length > 0 && !errors.release_date}
                    />
                  </Grid>
                </Grid>

                <MDBox px={3}>
                  <Autocomplete
                    multiple
                    options={stores}
                    value={storesV || []}
                    getOptionLabel={(option) => `${option.id} - ${option.name}`}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(e, value) => setValue(setFieldValue, "stores", value)}
                    renderInput={(params) =>
                      renderInput("form", params, "stores", "Loja *", handleBlur)
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="name"
                    label="Nome *"
                    type="text"
                    value={nameV}
                    error={errors.name && touched.name}
                    success={nameV.length > 0 && !errors.name}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="weight"
                    label="Peso (kg)*"
                    type="text"
                    value={weightV}
                    onChange={(e, v) => {
                      const { value } = e.target;
                      setFieldValue("weight", weightMask(value));
                    }}
                    error={errors.weight && touched.weight}
                    success={weightV.length > 0 && !errors.weight}
                  />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    options={unitsOfMeasurement}
                    getOptionLabel={(o) => `${o.acronym} - ${o.description}`}
                    value={handleValue(unit_of_measurementV)}
                    isOptionEqualToValue={(o, v) => o.id === v.id}
                    onChange={(e, value) => setFieldValue("unit_of_measurement", value)}
                    renderInput={(params) =>
                      renderInput(
                        "form",
                        params,
                        "unit_of_measurement",
                        "Unidade de medida *",
                        handleBlur
                      )
                    }
                  />
                </MDBox>

                {/* fieldarray  */}
                <MDBox px={3}>
                  <MDTypography variant="body2">Derivações</MDTypography>
                  <FieldArrayProductsFieldsList
                    name="derivations"
                    options={derivationTypes}
                    valueField={derivationsV}
                    label="derivação"
                    formData={{ setFieldValue, values, errors, handleBlur }}
                  />
                </MDBox>

                {/* fieldarray  */}
                <MDBox px={3}>
                  <MDTypography variant="body2">Categorias</MDTypography>
                  <FieldArrayProductsCategories
                    options={categories}
                    name="categories"
                    valueField={categoriesV}
                    label="Categoria"
                    formData={{ setFieldValue, values, errors, handleBlur }}
                  />
                </MDBox>

                <MDBox px={3}>
                  <ProductNCMAutocomplete formData={{ values, setFieldValue, handleBlur }} />
                </MDBox>

                <MDBox px={3}>
                  <ProductCESTAutocomplete formData={{ values, setFieldValue, handleBlur }} />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    options={tax_origin_list}
                    value={handleValue(tax_originV)}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(e, value) => setValue(setFieldValue, "tax_origin", value)}
                    renderInput={(params) =>
                      renderInput("form", params, "tax_origin", "Origem Fiscal *", handleBlur)
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <ProductPISAutocomplete formData={{ values, setFieldValue, handleBlur }} />
                </MDBox>

                <MDBox px={3}>
                  <ProductCOFINSAutocomplete formData={{ values, setFieldValue, handleBlur }} />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    options={genre}
                    getOptionLabel={(option) => option.name}
                    value={handleValue(genreV)}
                    isOptionEqualToValue={(option, value) => option?.code === value.code}
                    onChange={(e, value) => setValue(setFieldValue, "genre", value)}
                    renderInput={(params) =>
                      renderInput("form", params, "genre", "Gênero *", handleBlur)
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    multiple
                    options={collection}
                    getOptionLabel={(option) => option.name}
                    value={collectionsV || []}
                    isOptionEqualToValue={(option, value) => option?.code === value.code}
                    onChange={(e, value) => setFieldValue("collections", value)}
                    renderInput={(params) =>
                      renderInput("form", params, "collections", "Coleções *", handleBlur)
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    multiple
                    options={combos}
                    getOptionLabel={(option) => option.name}
                    value={combosV || []}
                    isOptionEqualToValue={(option, value) => option?.code === value.code}
                    onChange={(e, value) => setValue(setFieldValue, "combos", value)}
                    renderInput={(params) =>
                      renderInput("form", params, "combos", "Combos *", handleBlur)
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="supplier_code"
                    label="Código de fornecedor *"
                    type="text"
                    value={supplier_codeV}
                    onChange={(v) => {
                      let { value } = v.target;
                      if (value.includes("/")) value = value.replace("/", "");
                      setFieldValue("supplier_code", value);
                    }}
                    error={errors.supplier_code && touched.supplier_code}
                    success={supplier_codeV.length > 0 && !errors.supplier_code}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="observations"
                    label="Observações *"
                    type="text"
                    value={observationsV}
                    error={errors.observations && touched.observations}
                    success={observationsV.length > 0 && !errors.observations}
                  />
                </MDBox>

                <MDBox display="flex" gap="5px" py={2} px={3} ml="auto">
                  <MDButton
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                    size="large"
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default ProductCreate;
