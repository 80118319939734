export const user_permissions_list = [
  { id: 1, type: "Entrada no estoque", slug: "add-stock" },
  { id: 2, type: "Saída no estoque", slug: "remove-stock" },
];

export const movement_operation_type_list = [
  { id: 1, type: "Entrada" },
  { id: 2, type: "Saída" },
];

export const store_list = [
  {
    id: 1,
    name: "Hiatto",
  },
  {
    id: 2,
    name: "Hiatto Atacado",
  },
];

export const gender_list = [
  {
    code: 6,
    name: "Masculino",
  },
  {
    code: 7,
    name: "Feminino",
  },
  {
    code: 10,
    name: "Unisex",
  },
];

export const collection_list = [
  {
    code: 88,
    name: "Inverno",
  },
  {
    code: 89,
    name: "Verão",
  },
];

export const brand_list = [
  {
    id: 100,
    name: "DIVERSOS",
  },
  {
    id: 102,
    name: "Epzui",
  },
  {
    id: 101,
    name: "Hiatto",
  },
];

export const derivation_list = [
  { id: 1, name: "Cor" },
  { id: 2, name: "Tamanho" },
  { id: 3, name: "Único" },
];

export const derivation_list_items_options = {
  cor: [
    {
      id: 27,
      nome: "Amarelo",
    },
    {
      id: 26,
      nome: "Azul",
    },
  ],
  tamanho: [
    {
      id: 3,
      nome: 34,
    },
    {
      id: 4,
      nome: 36,
    },
    {
      id: 55,
      nome: 1,
    },
    {
      id: 56,
      nome: 2,
    },
  ],
  unico: [
    {
      id: 8,
      nome: "Único",
    },
  ],
};

export const unit_of_measurement_list = [
  {
    id: 1,
    descricao: "Unidade",
    sigla: "UN",
  },
  {
    id: 2,
    descricao: "Quilos",
    sigla: "KG",
  },
  {
    id: 3,
    descricao: "Rolo",
    sigla: "RL",
  },
  {
    id: 4,
    descricao: "Pacote",
    sigla: "PCT",
  },
  {
    id: 5,
    descricao: "Mil",
    sigla: "MI",
  },
  {
    id: 6,
    descricao: "Litro",
    sigla: "L",
  },
  {
    id: 7,
    descricao: "Metro",
    sigla: "MT",
  },
  {
    id: 8,
    descricao: "Peça",
    sigla: "PC",
  },
  {
    id: 9,
    descricao: "Metro Cúbico",
    sigla: "M3",
  },
];

export const tax_origin_list = [
  {
    id: 0,
    name: "0 - Nacional, exceto as indicadas nos códigos 3 a 5",
  },
  {
    id: 1,
    name: "1 - Estrangeira - Importação direta, exceto a indicada no código 6",
  },
  {
    id: 2,
    name: "2 - Estrangeira - Adquirida no mercado interno, exceto a indicada no código 7",
  },
  {
    id: 3,
    name: "3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% (quarenta por cento)",
  },
  {
    id: 4,
    name: "4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de que tratam o Decreto-Lei nº 288/67, e as Leis nºs 8.248/91, 8.387/91, 10.176/01 e 11 . 4 8 4 / 0 7",
  },
  {
    id: 5,
    name: "5 - Nacional, mercadoria ou bem com Conteúdo de Importação inferior ou igual a 40% (quarenta por cento)",
  },
  {
    id: 6,
    name: "6 - Estrangeira - Importação direta, sem similar nacional, constante em lista de Resolução CAMEX",
  },
  {
    id: 7,
    name: "7 - Estrangeira - Adquirida no mercado interno, sem similar nacional, constante em lista de Resolução CAMEX",
  },
  {
    id: 8,
    name: "8 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 70% (setenta por cento)",
  },
];

export const NCM_list = [
  {
    codigo: "1012100",
    descricao: "Cavalos, asininos e muares, vivos.- Cavalos: - -- Reprodutores de raca pura",
    ativo: true,
    requerCest: false,
  },
  {
    codigo: "1012900",
    descricao: "Cavalos, asininos e muares, vivos.- Cavalos: - -- Outros",
    ativo: true,
    requerCest: false,
  },
  {
    codigo: "1013000",
    descricao: "Cavalos, asininos e muares, vivos.- Cavalos: - - Asininos",
    ativo: true,
    requerCest: false,
  },
  {
    codigo: "1019000",
    descricao: "Cavalos, asininos e muares, vivos.- Cavalos: - - Outros",
    ativo: true,
    requerCest: false,
  },
  {
    codigo: "1022110",
    descricao:
      "Animais vivos da especie bovina.- Bovinos domesticos:-- Reprodutores de raca pura - Prenhes ou com cria ao pé",
    ativo: true,
    requerCest: false,
  },
  {
    codigo: "1022190",
    descricao:
      "Animais vivos da especie bovina.- Bovinos domesticos:-- Reprodutores de raca pura - Outros",
    ativo: true,
    requerCest: false,
  },
  {
    codigo: "1022911",
    descricao:
      "Animais vivos da especie bovina.- Bovinos domesticos:-- OutrosPara reproducao - Prenhes ou com cria ao pé",
    ativo: true,
    requerCest: false,
  },
  {
    codigo: "1022919",
    descricao:
      "Animais vivos da especie bovina.- Bovinos domesticos:-- OutrosPara reproducao - Outros",
    ativo: true,
    requerCest: false,
  },
  {
    codigo: "1022990",
    descricao:
      "Animais vivos da especie bovina.- Bovinos domesticos:-- OutrosPara reproducao - Outros",
    ativo: true,
    requerCest: false,
  },
  {
    codigo: "1023110",
    descricao:
      "Animais vivos da especie bovina.- Bufalos:-- Reprodutores de raca pura - Prenhes ou com cria ao pé",
    ativo: true,
    requerCest: false,
  },
  {
    codigo: "1023190",
    descricao: "Animais vivos da especie bovina.- Bufalos:-- Reprodutores de raca pura - Outros",
    ativo: true,
    requerCest: false,
  },
];

export const CEST_list = [
  {
    codigo: "1100100",
    descricao: "Água sanitária, branqueador e outros alvejantes",
  },
  {
    codigo: "1100500",
    descricao: "Detergentes líquidos, exceto para lavar roupa",
  },
  {
    codigo: "109100",
    descricao: "Cilindros pneumáticos",
  },
  {
    codigo: "1000900",
    descricao: "Veda rosca, lona plástica para uso na construção, fitas isolantes e afins",
  },
  {
    codigo: "1100900",
    descricao: "Esponjas para limpeza",
  },
  {
    codigo: "100500",
    descricao: "Frisos, decalques, molduras e acabamentos",
  },
  {
    codigo: "1900500",
    descricao: "Maletas e pastas para documentos e de estudante, e artefatos semelhantes",
  },
  {
    codigo: "100800",
    descricao: "Partes de veículos automóveis, tratores e máquinas autopropulsadas",
  },
  {
    codigo: "1900400",
    descricao:
      "Artigos de escritório e artigos escolares de plástico e outros materiais classificados nas posições 3901 a 3914, exceto estojos",
  },
];

export const PIS_list = [
  {
    codigo: "01",
    nome: "Operação tributável com alíquota básica",
  },
  {
    codigo: "02",
    nome: "Operação tributável com alíquota diferenciada",
  },
  {
    codigo: "03",
    nome: "Operação tributável com alíquota por unidade de medida de produto",
  },
  {
    codigo: "04",
    nome: "Operação tributável monofásica - Revenda a alíquota zero",
  },
  {
    codigo: "05",
    nome: "Operação tributável por substituição tributária",
  },
  {
    codigo: "06",
    nome: "Operação tributável a alíquota zero",
  },
  {
    codigo: "07",
    nome: "Operação isenta da contribuição",
  },
  {
    codigo: "08",
    nome: "Operação sem incidência da contribuição",
  },
];

export const COFINS_list = [
  {
    codigo: "01",
    nome: "Operação tributável com alíquota básica",
  },
  {
    codigo: "02",
    nome: "Operação tributável com alíquota diferenciada",
  },
  {
    codigo: "03",
    nome: "Operação tributável com alíquota por unidade de medida de produto",
  },
  {
    codigo: "04",
    nome: "Operação tributável monofásica - Revenda a alíquota zero",
  },
  {
    codigo: "05",
    nome: "Operação tributável por substituição tributária",
  },
  {
    codigo: "06",
    nome: "Operação tributável a alíquota zero",
  },
  {
    codigo: "07",
    nome: "Operação isenta da contribuição",
  },
  {
    codigo: "08",
    nome: "Operação sem incidência da contribuição",
  },
];

export const treeData = [
  {
    id: 1,
    name: "Feminino",
    children: [
      {
        id: 7,
        name: "Camiseta / Blusa",
        children: [
          {
            id: 23,
            name: "Camiseta Lisa",
          },
          {
            id: 24,
            name: "Camiseta Estampada",
          },
          {
            id: 25,
            name: "Camiseta Estonada",
          },
        ],
      },
      {
        id: 9,
        name: "Moletom",
        children: [
          {
            id: 31,
            name: "Moletom Canguru",
          },
          {
            id: 32,
            name: "Moletom Fechado",
          },
          {
            id: 33,
            name: "Jaqueta Moletom",
          },
        ],
      },
      {
        id: 10,
        name: "Calça",
        children: [
          {
            id: 34,
            name: "Calça Jogger",
          },
        ],
      },
      {
        id: 11,
        name: "Jaqueta / Casaco",
      },
      {
        id: 12,
        name: "Regatas",
      },
      {
        id: 13,
        name: "Trico",
      },
      {
        id: 14,
        name: "Vestido",
      },
      {
        id: 15,
        name: "Camisa",
        children: [
          {
            id: 35,
            name: "Lisa",
          },
        ],
      },
      {
        id: 16,
        name: "Polos",
      },
    ],
  },
  {
    id: 2,
    name: "Dicas",
  },
  {
    id: 6,
    name: "Masculino",
    children: [
      {
        id: 17,
        name: "Bermudas",
      },
      {
        id: 18,
        name: "Calças",
      },
      {
        id: 19,
        name: "Camisas",
      },
      {
        id: 20,
        name: "Camisetas",

        children: [
          {
            id: 26,
            name: "Basicas",
          },
          {
            id: 27,
            name: "Estampadas",
          },
          {
            id: 28,
            name: "Linha Premium",
          },
          {
            id: 29,
            name: "Bordadas",
          },
          {
            id: 30,
            name: "Full Print",
          },
        ],
      },
      {
        id: 21,
        name: "Jaquetas",
      },
      {
        id: 22,
        name: "Polos",
      },
    ],
  },
];

export const testReportMovement = [
  {
    id: "65d6190697962f518e0e7e68",
    type: 1,
    product_code: "123",
    product_name: "Nome do Produto",
    shipping_code: "20240000001",
    movements_ids: [390476, 390477],
    nf: "2024-ABC",
    datetime: "2024-02-21T15:38:46.973Z",
    movements: [
      {
        movement: 390476,
        deposit: 1,
        product: "123-002",
        movement_value: 1,
        movement_date_time: "2024-02-21 12:38:45-03",
        included_date_time: "2024-02-21 12:38:45",
        operation_type: 1,
        type: 1,
        quantity: 1,
        origin: 18,
        observation: "Observações",
        product_derivation: {
          name: "Verde",
          code: "123-002",
          stores: [1],
          derivations: [
            {
              derivation: 1,
              value: "Verde",
            },
          ],
          grouper: "123",
        },
      },
      {
        movement: 390477,
        product: "123-001-G",
        movement_value: 1,
        movement_date_time: "2024-02-21 12:38:46-03",
        included_date_time: "2024-02-21 12:38:46",
        operation_type: 1,
        quantity: 1,
        product_derivation: {
          name: "Branco",
          code: "123-001-G",
          stores: [1],
          derivations: [
            {
              derivation: 1,
              value: "Branco",
            },
          ],
          grouper: "123",
        },
      },
    ],
  },
];
