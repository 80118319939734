import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import logoIcon from "assets/images/logo_icon.png";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import { setMiniSidenav, useMaterialUIController } from "context";
import { useAuthContext } from "context/auth_context";
import Sidenav from "examples/Sidenav";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import routes from "routes";
import { setNavigate } from "shared/utils/redirect_to";

export default function App() {
  setNavigate(useNavigate());
  const { userLogged } = useAuthContext();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // filter of routes
  const filterRoutesByRole = (routesToFilter) => {
    const filterRoute = (route) => {
      if (!userLogged) return true;
      if (
        (!route.roles || route.roles.includes(userLogged.role)) &&
        (userLogged.role === 0 ||
          !route.permissions ||
          userLogged.permissions.some((permission) => route.permissions.includes(permission)))
      )
        return true;
      return false;
    };
    const filterRouteOrCollapse = (route) => {
      if (route.collapse) {
        const filteredCollapse = route.collapse.filter(filterRoute);
        return filteredCollapse.length > 0 ? { ...route, collapse: filteredCollapse } : null;
      }
      return filterRoute(route) ? route : null;
    };
    return routesToFilter.map(filterRouteOrCollapse).filter(Boolean);
  };

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && userLogged && (
        <Sidenav
          color={sidenavColor}
          brand={logoIcon}
          brandName="Hiatto"
          routes={filterRoutesByRole(routes)}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      <Routes>
        {getRoutes(filterRoutesByRole(routes))}
        <Route path="*" element={<Navigate to="/entrar" />} />
      </Routes>
      <ToastContainer />
    </ThemeProvider>
  );
}
