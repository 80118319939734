/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { CircularProgress, Divider, Grid, Icon, IconButton, Modal, useTheme } from "@mui/material";
import MDTypography from "shared/components/MDTypography";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";

import { exportToExcel } from "pages/derivation/service/xlsx_stock";

import DerivationService from "pages/derivation/service";
import toastError from "shared/components/snackbar/error/toast_error";
import { useParams } from "react-router-dom";

import ReactToPrint from "react-to-print";
import styles from "./styles.module.css";

function DerivationReportModal({ open, onClose, data }) {
  const componentRef = React.createRef();
  const theme = useTheme();
  const productId = useParams();

  const [stockReportData, setStockReportData] = useState();

  const [loading, setLoading] = useState(false);

  const loadProductStockReport = async () => {
    try {
      setLoading(true);
      const res = await DerivationService.getDataStockReport(productId.code);
      if (res) setStockReportData(res);
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePrice = (value, qtd = 1) => {
    const valueTotal = value * qtd;
    const formatedPrice = valueTotal.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatedPrice;
  };

  const reduceValue = (value) =>
    stockReportData.reduce((total, obj) => total + Number(obj[value] || 0), 0);

  const resetModal = () => {
    setStockReportData();
    onClose();
  };

  useEffect(() => {
    if (open) loadProductStockReport();
  }, [open]);

  const printTrigger = useCallback(
    () => (
      <MDButton disabled={!stockReportData} variant="gradient" color="primary" sx={{ mb: 2 }}>
        gerar pdf
      </MDButton>
    ),
    [stockReportData]
  );

  return (
    <Modal open={open} onClose={resetModal}>
      <MDBox className={styles.modal}>
        <MDBox className={styles.modelHeader}>
          <Grid container>
            <Grid xs={11} sm={12} item>
              <MDTypography align="center">Relatório de Estoque</MDTypography>
            </Grid>
            <Grid item xs={1} sm={1} position="absolute" style={{ right: 5, top: 5 }}>
              <MDBox display="flex" justifyContent="flex-end">
                <IconButton size="small" onClick={resetModal}>
                  <Icon fontSize="small" align="right">
                    close
                  </Icon>
                </IconButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Divider sx={{ background: theme.palette.dark.main, m: 0 }} />

        <MDBox className={styles.modelContainer}>
          <MDBox display="flex" gap={2}>
            <MDButton
              disabled={!stockReportData}
              variant="gradient"
              color="primary"
              onClick={() => exportToExcel(stockReportData)}
              sx={{ mb: 2 }}
            >
              download xlsx
            </MDButton>
            <ReactToPrint trigger={printTrigger} content={() => componentRef.current} />
            {/* <MDButton
              variant="gradient"
              color="primary"
              onClick={() => testPdfStock(dataXLSX)}
              sx={{ mb: 2 }}
            >
              download pdf
            </MDButton> */}
          </MDBox>

          {loading && (
            <MDBox>
              <CircularProgress color="inherit" size={20} />
            </MDBox>
          )}

          {stockReportData && stockReportData.length > 0 && (
            <MDBox ref={componentRef} className={styles.modalTable}>
              <table id="stockTable">
                <thead>
                  <tr>
                    <th colSpan={7}>Relatório de estoque</th>
                  </tr>
                  <tr>
                    <th>Código</th>
                    <th className={styles.subject}>Descrição</th>
                    <th>Estoque</th>
                    <th>Preço de custo</th>
                    <th>Custo de estoque</th>
                    <th>Preço de venda</th>
                    <th>Valor total do estoque</th>
                  </tr>
                </thead>
                <tbody>
                  {stockReportData.map((row, index) => (
                    <tr key={index}>
                      <td>{row.code}</td>
                      <td>
                        <div>{row.description || " - "}</div>
                      </td>
                      <td>{row.stock || " - "}</td>
                      <td>{row.cost_price ? handlePrice(row.cost_price) : " - "}</td>
                      <td>{row.cost_of_stock ? handlePrice(row.cost_of_stock) : " - "}</td>
                      <td>{row.sale_price ? handlePrice(row.sale_price) : " - "}</td>
                      <td>
                        {row.total_value_of_stock ? handlePrice(row.total_value_of_stock) : " - "}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th>Total</th>
                    <td> </td>
                    <td>{reduceValue("stock")} </td>
                    <td>{/* {handlePrice(reduceValue("cost_price"))} */} </td>
                    <td>{handlePrice(reduceValue("cost_of_stock"))}</td>
                    <td>{/* {handlePrice(reduceValue("sale_price"))} */} </td>
                    <td>{handlePrice(reduceValue("total_value_of_stock"))}</td>
                  </tr>
                </tfoot>
              </table>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
    </Modal>
  );
}

DerivationReportModal.defaultProps = {
  onClose: null,
  data: [],
};

DerivationReportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.any),
};

export default DerivationReportModal;
