import { Box, Chip, CircularProgress, TextField } from "@mui/material";
import FormFieldAutocomplete from "shared/components/form_field/form_field_autocomplete";

export const noOptionsText = "Sem opções";

export const handleValue = (value) => (value === "" ? null : value);

export const compareOptionToValue = (option, value, compareValue = "id") =>
  option[compareValue] === value[compareValue];

export const setValue = (setFieldValue, fieldName, value) => {
  setFieldValue(fieldName, value !== null ? value : "");
};

export const renderOption = (props, option, _, keyV = "id", optionValue = "name") => (
  <Box {...props} component="li" key={option[keyV]}>
    {option[optionValue]}
  </Box>
);

export const renderInput = (
  type,
  params,
  name,
  label,
  handleBlur,
  onChange,
  variant = "standard"
) => {
  switch (type) {
    case "form":
      return (
        <FormFieldAutocomplete
          fieldVariant={variant}
          {...params}
          type="text"
          label={label}
          name={name}
          onChange={onChange}
          onBlur={handleBlur}
        />
      );

    default:
      return <TextField {...params} label={label} onChange={onChange} />;
  }
};

export const multipleChips = (
  value,
  getTagProps,
  labelOption = null,
  theme,
  translateFunc = null
) => {
  if (translateFunc)
    return value.map((option, index) => (
      <Chip
        {...getTagProps({ index })}
        style={{ backgroundColor: theme.palette.info.main }}
        label={translateFunc(labelOption ? option[labelOption] : option)}
      />
    ));
  return value.map((option, index) => (
    <Chip
      {...getTagProps({ index })}
      style={{ backgroundColor: theme.palette.info.main }}
      label={labelOption ? option[labelOption] : option}
    />
  ));
};

export const handleNoOptionsAutocomplete = (array, type) => {
  switch (type) {
    case "async":
      return array?.length > 0
        ? "Sem opções"
        : "Digite no mínimo 3 caracteres para carregar as opções";

    default:
      return "Sem opções";
  }
};

export const adornedInputPropsAutocomplete = (loading, params) => {
  const adorned = {
    ...params.InputProps,
    endAdornment: (
      <>
        {loading ? <CircularProgress color="inherit" size={20} /> : null}
        {params.InputProps.endAdornment}
      </>
    ),
  };
  return adorned;
};
