/* eslint-disable no-unused-vars */
import html2canvas from "html2canvas";
import JSPDF from "jspdf";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const handlePrice = (value, qtd = 1) => {
  const valueTotal = value * qtd;
  const formatedPrice = valueTotal.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
  return formatedPrice;
};

async function testPdfStock(dataStock) {
  const info = {
    title: "Relatório de estoque",
  };

  const defaultStyle = {
    fontSize: 10,
  };

  const styles = {
    alignL: {
      alignment: "left",
      margin: 0,
      padding: 0,
    },
    paddingX: {
      margin: [10, 0, 10, 0],
    },
    table: {
      width: "100%",
      alignment: "right",
    },
    title: {
      alignment: "center",
      bold: true,
      fontSize: 14,
      margin: [0, 0, 0, 10],
    },
    th: {
      //   margin: 3,
      alignment: "center",
      //   margin: [10, 0, 10, 0],
      //   valign: "middle",
      bold: true,
    },
    td: {
      margin: 3,
    },
  };

  const allData = [];
  allData.push([
    { text: "Código", style: "th" },
    { text: "Descrição", style: "th" },
    { text: "Estoque", style: "th" },
    { text: "Preço de custo", style: "th" },
    { text: "Custo de estoque", style: "th" },
    { text: "Preço de venda", style: "th" },
    { text: "Valor total do estoque", style: "th" },
  ]);

  dataStock.forEach((t, index) =>
    allData.push([
      t.code,
      t.observations,
      t.amount_available_for_sale,
      handlePrice(Number(t.cost_price)),
      handlePrice(Number(t.cost_price) * t.amount_available_for_sale),
      handlePrice(Number(t.sale_price)),
      handlePrice(Number(t.sale_price) * t.amount_available_for_sale),
    ])
  );

  const reduceValue = (value) => dataStock.reduce((total, obj) => total + Number(obj[value]), 0);
  const reduceSumAmountPrice = (value, value2) =>
    dataStock.reduce((total, obj) => total + Number(obj[value]) * Number(obj[value2]), 0);

  const sumStock = reduceValue("amount_available_for_sale");
  const sumCostPrice = reduceValue("cost_price");
  const sumAmountCostPrice = reduceSumAmountPrice("cost_price", "amount_available_for_sale");
  const sumSalePrice = reduceValue("sale_price");
  const sumAmountSalePrice = reduceSumAmountPrice("sale_price", "amount_available_for_sale");

  allData.push([
    "Total:",
    "",
    sumStock,
    handlePrice(sumCostPrice),
    handlePrice(sumAmountCostPrice),
    handlePrice(sumSalePrice),
    handlePrice(sumAmountSalePrice),
  ]);

  const content = [
    { text: "Relatorio de Estoque", style: "title" },
    {
      table: {
        body: allData,
      },
      //   layout: "noBorders",
      margin: [0, 0, 0, 0],
    },
  ];

  const header = (currentPage, pageCount, pageSize) => ({
    columns: [
      { text: `Filtro: descrição`, alignment: "left", margin: [10, 10] },
      {
        text: `Data de Criação: ${new Date().toLocaleDateString()}`,
        alignment: "right",
        margin: [10, 10],
      },
    ],
  });

  const docDefinition = {
    header,
    info,
    defaultStyle,
    styles,
    content,
  };
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);

  pdfDocGenerator.download(`pdf_estoque`);
}

export default testPdfStock;
