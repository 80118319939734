/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */

import { Autocomplete, Box, Card, Grid } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDTypography from "shared/components/MDTypography";
import {
  handleValue,
  renderInput,
  setValue,
} from "shared/components/autocomplete/autocomplete_configs";

import {
  NCM_list,
  CEST_list,
  tax_origin_list,
  PIS_list,
  COFINS_list,
} from "shared/utils/select_lists";
import { weightMask } from "shared/utils/masks";

import FormField from "shared/components/form_field";
import toastError from "shared/components/snackbar/error/toast_error";
import toastSuccess from "shared/components/snackbar/success/toast_success";
import ProductsService from "../service";
import initialValues from "./schemas/initial_values";
import valifations from "./schemas/validations";

import FieldArrayProductsDerivations from "../components/field_array_products_fields_list";
import FieldArrayProductsCategories from "../components/field_array_products_categories";

import ProductNCMAutocomplete from "../components/product_NCM";
import ProductCESTAutocomplete from "../components/product_CEST";
import ProductPISAutocomplete from "../components/product_PIS";
import ProductCOFINSAutocomplete from "../components/product_COFINS";

function ProductEdit() {
  const { id } = useParams();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  const [brands, setBrands] = useState([]);
  const [stores, setStores] = useState([]);
  const [unitsOfMeasurement, setUnitsOfMeasurement] = useState([]);
  const [derivationTypes, setDerivationTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [genres, setGenres] = useState([]);
  const [collections, setCollections] = useState([]);
  const [combos, setCombos] = useState([]);

  const navigate = useNavigate();

  const handleMask = (value) => {
    let withMask = value;
    withMask = withMask.replace(".", "");
    withMask = withMask.replace(",", ".");
    return Number(withMask);
  };

  const handleArraySubmit = (array, field = "id") => {
    const res = array.map((v) => v[field]);
    return res;
  };

  const handleDateSubmit = (date) => {
    const isoString = `${date}T00:00:00Z`;
    return isoString;
  };

  const handleSubmit = async (values, actions) => {
    try {
      const data = {
        code: values.code,
        name: values.name,
        release_date: handleDateSubmit(values.release_date),
        brand: values.brand ? values.brand?.id : "",
        stores: handleArraySubmit(values.stores),
        weight: handleMask(values.weight),
        unit_of_measurement: values.unit_of_measurement.acronym,
        genre: values.genre.name,
        collections: values.collections,
        combos: values.combos,
        derivations: handleArraySubmit(values.derivations),
        categories: handleArraySubmit(values.categories),
        ncm: values.ncm?.code,
        cest: values.cest?.code,
        pis: values.pis?.code,
        cofins: values.cofins?.code,
        tax_origin: values.tax_origin.id,
        supplier_code: values.supplier_code,
        observations: values.observations,
      };
      if (!data.cest || !data.cest.trim() === "") delete data.cest;
      if (!data.pis || !data.pis.trim() === "") delete data.pis;
      if (!data.cofins || !data.cofins.trim() === "") delete data.cofins;

      await ProductsService.update(id, data);
      actions.setTouched({});
      actions.setSubmitting(false);
      toastSuccess("Produto editado com sucesso");
      navigate("/produtos");
    } catch (e) {
      toastError(e.message);
    }
  };

  const findItemById = (idP, array) => {
    const res = array.find((v) => v.id === idP) || "";

    return res;
  };

  const findItemsArrayById = (prodArray, array, field = "") => {
    const resArray = [];
    for (let index = 0; index < prodArray.length; index++) {
      const element = array.find((e) => e.id === prodArray[index][field]) || "";
      if (element) resArray.push(element);
    }
    return resArray;
  };

  const findItemsArrayByValue = (prodArray, array) => {
    const resArray = [];
    for (let index = 0; index < prodArray.length; index++) {
      const element = array.find((e) => e.id === prodArray[index]) || "";
      if (element) resArray.push(element);
    }
    return resArray;
  };

  function returnArrayOfTreeItems(arrayIds, treeDataList) {
    const result = [];
    function findItemTreeById(idItem, array) {
      for (const item of array) {
        if (item.id === idItem) return item;
        if (item.children) {
          const found = findItemTreeById(idItem, item.children);
          if (found) return found;
        }
      }
      return null;
    }
    arrayIds.forEach((idItem) => {
      const item = findItemTreeById(idItem, treeDataList);
      if (item) result.push({ id: item.id, name: item.name });
    });
    return result;
  }

  function findItemCharacteristc(array, itemSelected) {
    const res = array.characteristics.find((v) => v.name === itemSelected).list || null;

    return res;
  }

  function findItemProductCharacteristc(array, itemSelected) {
    if (array.characteristics.length) {
      const res = array.characteristics.find((v) => v.name === itemSelected).value_description;

      return res;
    }
    return null;
  }

  const loadProduct = async () => {
    try {
      const derivationListData = await ProductsService.getProductFieldsList();

      if (derivationListData) {
        setBrands(derivationListData.brands);
        setStores(derivationListData.stores);
        setUnitsOfMeasurement(derivationListData.units_of_measurement);
        setDerivationTypes(derivationListData.derivation_types);
        setCategories(derivationListData.categories);
        // setCharacteristics(derivationListData.characteristics);
        setGenres(findItemCharacteristc(derivationListData, "Gênero"));
        setCollections(findItemCharacteristc(derivationListData, "Coleção").map((v) => v.name));
        setCombos(findItemCharacteristc(derivationListData, "Combos").map((v) => v.name));
      }

      const product = await ProductsService.getById(id);
      const brand = findItemById(product.brand, derivationListData.brands) || "";
      const unit_of_measurement =
        derivationListData.units_of_measurement.find(
          (v) => v.acronym === product.unit_of_measurement
        ) || "";

      const tax_origin_data = tax_origin_list.find((v) => v.id === product.tax_origin) || "";

      setInitialValuesFromApi({
        code: product.code,
        name: product.name,
        release_date: product?.release_date?.split("T")[0],
        brand: product.brand ? brand : "",
        stores: findItemsArrayById(product.stores, derivationListData.stores, "id"),
        weight: product.weight ? weightMask(product.weight) : "",
        unit_of_measurement: product.unit_of_measurement ? unit_of_measurement : "",
        genre: findItemProductCharacteristc(product, "Gênero") ?? "",
        collections: findItemProductCharacteristc(product, "Coleção")?.filter((v) => v) ?? [],
        combos: findItemProductCharacteristc(product, "Combos")?.filter((v) => v) ?? [],
        derivations: product.derivations
          ? findItemsArrayByValue(product.derivations, derivationListData.derivation_types)
          : [],
        categories: product.categories
          ? returnArrayOfTreeItems(product.categories, derivationListData.categories)
          : [""],
        ncm: product.ncm ?? "",
        cest: product.cest ?? "",
        tax_origin: tax_origin_data,
        pis: product.pis ?? "",
        cofins: product.cofins ?? "",
        supplier_code: product.supplier_code ? product.supplier_code : "",
        observations: product.observations ? product.observations : "",
      });
    } catch (e) {
      toastError(e.message);
    }
  };

  useEffect(() => {
    loadProduct();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValuesFromApi ?? initialValues}
        validationSchema={valifations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, isSubmitting, setFieldValue, handleBlur }) => {
          const {
            code: codeV,
            brand: brandV,
            release_date: release_dateV,
            name: nameV,
            weight: weightV,
            unit_of_measurement: unit_of_measurementV,
            derivations: derivationsV,
            categories: categoriesV,
            ncm: ncmV,
            cest: cestV,
            pis: pisV,
            cofins: cofinsV,
            tax_origin: tax_originV,
            stores: storesV,

            genre: genreV,
            collections: collectionsV,
            combos: combosV,

            supplier_code: supplier_codeV,
            observations: observationsV,
          } = values;

          return (
            <Form id="user-edit-form" autoComplete="off">
              <Card id="basic-info" sx={{ overflow: "visible" }}>
                <MDBox p={3}>
                  <MDTypography variant="h5">Editar Produto</MDTypography>
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="code"
                    label="Código pai *"
                    type="text"
                    value={codeV}
                    error={errors.code && touched.code}
                    success={codeV.length > 0 && !errors.code}
                  />
                </MDBox>

                <Grid px={3} container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <Autocomplete
                      options={brands}
                      value={handleValue(brandV)}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={(e, value) => setValue(setFieldValue, "brand", value)}
                      renderOption={(props, option) => (
                        <Box component="li" {...props} key={option.id}>
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) =>
                        renderInput("form", params, "brand", "Marca *", handleBlur)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      fieldShrink
                      name="release_date"
                      label="Lançamento Pai *"
                      type="date"
                      value={release_dateV}
                      error={errors.release_date && touched.release_date}
                      success={release_dateV?.length > 0 && !errors.release_date}
                    />
                  </Grid>
                </Grid>

                <MDBox px={3}>
                  <Autocomplete
                    multiple
                    options={stores}
                    value={storesV || []}
                    getOptionLabel={(option) => `${option.id} - ${option.name}`}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(e, value) => setValue(setFieldValue, "stores", value)}
                    renderInput={(params) =>
                      renderInput("form", params, "stores", "Loja *", handleBlur)
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="name"
                    label="Nome completo *"
                    type="text"
                    value={nameV}
                    error={errors.name && touched.name}
                    success={nameV.length > 0 && !errors.name}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="weight"
                    label="Peso (kg)*"
                    type="text"
                    value={weightV}
                    onChange={(e, v) => {
                      const { value } = e.target;
                      setFieldValue("weight", weightMask(value));
                    }}
                    error={errors.weight && touched.weight}
                    success={weightV.length > 0 && !errors.weight}
                  />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    options={unitsOfMeasurement}
                    getOptionLabel={(o) => `${o.acronym} - ${o.description}`}
                    value={handleValue(unit_of_measurementV)}
                    isOptionEqualToValue={(o, v) => o?.id === v?.id}
                    onChange={(e, value) => setFieldValue("unit_of_measurement", value)}
                    renderInput={(params) =>
                      renderInput(
                        "form",
                        params,
                        "unit_of_measurement",
                        "Unidade de medida *",
                        handleBlur
                      )
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <MDTypography variant="body2">Derivações</MDTypography>
                  <FieldArrayProductsDerivations
                    options={derivationTypes}
                    name="derivations"
                    valueField={derivationsV}
                    label="derivação"
                    formData={{ setFieldValue, values, errors, handleBlur }}
                    disabled
                  />
                </MDBox>

                <MDBox px={3}>
                  <MDTypography variant="body2">Categorias</MDTypography>
                  <FieldArrayProductsCategories
                    options={categories}
                    name="categories"
                    valueField={categoriesV}
                    label="Categoria"
                    formData={{ setFieldValue, values, errors, handleBlur }}
                  />
                </MDBox>

                <MDBox px={3}>
                  <ProductNCMAutocomplete formData={{ values, setFieldValue, handleBlur }} />
                </MDBox>

                <MDBox px={3}>
                  <ProductCESTAutocomplete formData={{ values, setFieldValue, handleBlur }} />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    options={tax_origin_list}
                    value={tax_originV || null}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(e, value) => setFieldValue("tax_origin", value)}
                    renderInput={(params) =>
                      renderInput("form", params, "tax_origin", "Origem Fiscal *", handleBlur)
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <ProductPISAutocomplete formData={{ values, setFieldValue, handleBlur }} />
                </MDBox>

                <MDBox px={3}>
                  <ProductCOFINSAutocomplete formData={{ values, setFieldValue, handleBlur }} />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    options={genres}
                    getOptionLabel={(option) => option?.name ?? genreV}
                    value={handleValue(genreV)}
                    isOptionEqualToValue={(option, value) => option?.name === value.name}
                    onChange={(_, value) => setFieldValue("genre", value)}
                    renderInput={(params) =>
                      renderInput("form", params, "genre", "Gênero *", handleBlur)
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    multiple
                    options={collections}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={collectionsV}
                    onChange={(_, value) => setValue(setFieldValue, "collections", value)}
                    renderInput={(params) =>
                      renderInput("form", params, "collections", "Coleções *", handleBlur)
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <Autocomplete
                    multiple
                    options={combos}
                    getOptionLabel={(option) => option}
                    isOptionEqualToValue={(option, value) => option === value}
                    value={combosV ?? []}
                    onChange={(_, value) => setValue(setFieldValue, "combos", value)}
                    renderInput={(params) =>
                      renderInput("form", params, "combos", "Combos *", handleBlur)
                    }
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="supplier_code"
                    label="Código de fornecedor *"
                    type="text"
                    value={supplier_codeV}
                    onChange={(v) => {
                      let { value } = v.target;
                      if (value.includes("/")) value = value.replace("/", "");
                      setFieldValue("supplier_code", value);
                    }}
                    error={errors.supplier_code && touched.supplier_code}
                    success={supplier_codeV.length > 0 && !errors.supplier_code}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="observations"
                    label="Observações *"
                    type="text"
                    value={observationsV}
                    error={errors.observations && touched.observations}
                    success={observationsV.length > 0 && !errors.observations}
                  />
                </MDBox>

                <MDBox py={3} px={3} width="12rem" ml="auto">
                  <MDButton
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                    size="large"
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default ProductEdit;
