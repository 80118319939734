import { movement_operation_type_list, user_permissions_list } from "./select_lists";

export default function getMovementOperationTypesFromUserPermissions(userPermissions) {
  if (!userPermissions) return user_permissions_list;
  const filteredObjectPermissions = user_permissions_list.filter((userPermission) =>
    userPermissions.includes(userPermission.slug)
  );
  const filteredObjectPermissionIds = filteredObjectPermissions.map(
    (filteredObjectPermission) => filteredObjectPermission.id
  );
  const filteredMovementOperationTypes = movement_operation_type_list.filter(
    (movementOperationType) => filteredObjectPermissionIds.includes(movementOperationType.id)
  );

  return filteredMovementOperationTypes;
}
