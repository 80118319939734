import { CircularProgress, IconButton, InputAdornment } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const adornedInputPropsInput = (loading) => {
  const adorned = {
    endAdornment: (
      <InputAdornment position="end">
        {loading && <CircularProgress color="inherit" size={20} />}
      </InputAdornment>
    ),
  };
  return adorned;
};

export const adornedInputCancelSearch = (value, setValue) => {
  const adorned = {
    endAdornment: (
      <IconButton
        sx={{ visibility: value ? "visible" : "hidden" }}
        size="small"
        onClick={() => setValue("")}
      >
        <ClearIcon />
      </IconButton>
    ),
  };
  return adorned;
};

export default adornedInputPropsInput;
