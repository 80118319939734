/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */

import { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  Divider,
  Grid,
  Icon,
  IconButton,
  createFilterOptions,
  useTheme,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDTypography from "shared/components/MDTypography";
import {
  handleValue,
  renderInput,
  setValue,
} from "shared/components/autocomplete/autocomplete_configs";
import FormField from "shared/components/form_field";
import toastError from "shared/components/snackbar/error/toast_error";
import toastSuccess from "shared/components/snackbar/success/toast_success";
import { currencyMask } from "shared/utils/masks";

import { derivation_list_items_options } from "shared/utils/select_lists";
import ProductService from "pages/product/service";
import MDProgress from "shared/components/MDProgress";

import initialValues from "./schemas/initial_values";
import validations from "./schemas/validations";
import DerivationService from "../service";

function DerivationEdit() {
  const { code, id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();

  const [derivationLists, setDerivationLists] = useState();
  const [primaryDerivation, setPrimaryDerivation] = useState();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  const handleDerivationsSubmit = (values) => {
    const derivationsValues = Object.keys(values)
      .filter((key) => key.startsWith("derivation_") && values[key] !== null && values[key] !== "")
      .map((key) => {
        let res = values[key];
        if (key.includes("color"))
          res = {
            id: 1,
            value: values[key].name,
          };
        if (key.includes("size"))
          res = {
            id: 2,
            value: String(values[key].name),
          };
        if (key.includes("unique"))
          res = {
            id: 3,
            value: values[key].name,
          };
        return res;
      });
    return derivationsValues;
  };

  const handleMask = (value) => {
    let withMask = value;
    withMask = withMask.replace(".", "");
    withMask = withMask.replace(",", ".");
    return Number(withMask);
  };

  const handleSubmit = async (values, actions) => {
    try {
      const data = {
        code_derivation: values.codeDerivation,
        derivations: handleDerivationsSubmit(values) || [],
        name: values.name,
        old_price: handleMask(values.old_price),
        sale_price: handleMask(values.sale_price),
        cost_price: handleMask(values.cost_price),
      };
      await DerivationService.update(code, data, id);
      actions.setTouched({});
      actions.setSubmitting(false);

      toastSuccess("Derivação de produto criado com sucesso");

      navigate(`/produtos/${code}/derivacoes`);
    } catch (e) {
      toastError(e.message);
    }
  };

  function verifyTypeDerivation(array, type) {
    return array.some((obj) => obj.type === type);
  }

  function accessArrayOptionsByType(array, type) {
    const selectedObj = array.find((obj) => obj.type === type);
    return selectedObj ? selectedObj.options : null;
  }

  function handleLoadDerivationValue(array, type) {
    const derivationType = array.find((obj) => obj.derivation === type) || null;
    let data = derivationType;
    if (derivationType)
      data = {
        id: derivationType.id,
        name: derivationType.value,
        derivation: derivationType.derivation,
      };
    return data;
  }

  const loadDerivationLists = async () => {
    try {
      const derivationListData = await DerivationService.getProductDerivationLists(code);
      if (derivationListData) setDerivationLists(derivationListData.derivations_options);
    } catch (e) {
      toastError(e.message);
    }
  };

  const loadDerivation = async () => {
    try {
      const derivation = await DerivationService.getById(code, id);
      const derivationsOptionsData = derivation.derivations.map((v) => v.derivation);

      if (derivation) {
        setPrimaryDerivation(derivation.derivations[0].derivation);
        setInitialValuesFromApi({
          codeDerivation: derivation.code,
          name: derivation.name,
          old_price: currencyMask(derivation.old_price),
          sale_price: currencyMask(derivation.sale_price),
          cost_price: currencyMask(derivation.cost_price),

          derivations_options: derivationsOptionsData,
          derivation_color: handleLoadDerivationValue(derivation.derivations, 1),
          derivation_size: handleLoadDerivationValue(derivation.derivations, 2),
          derivation_unique: handleLoadDerivationValue(derivation.derivations, 3),
        });
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  const handleDerivationName = (values) => {
    const [derivation_colorV, derivation_sizeV, derivation_uniqueV] = values;
    const selectedValues = [];
    if (derivation_colorV) selectedValues.push(derivation_colorV.name);
    if (derivation_sizeV) selectedValues.push(String(derivation_sizeV.name));
    if (derivation_uniqueV) selectedValues.push(derivation_uniqueV.name);
    const nameValue = selectedValues.join(" - ");
    return nameValue;
  };

  useEffect(() => {
    loadDerivationLists();
    loadDerivation();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Formik
        initialValues={initialValuesFromApi ?? initialValues}
        validationSchema={validations[0]}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, isSubmitting, setFieldValue, handleBlur }) => {
          const {
            codeDerivation: codeDerivationV,
            name: nameV,
            derivations: derivationsV,
            old_price: old_priceV,
            sale_price: sale_priceV,
            cost_price: cost_priceV,

            derivations_options: derivations_optionsV,
            derivation_color: derivation_colorV,
            derivation_size: derivation_sizeV,
            derivation_unique: derivation_uniqueV,
          } = values;

          return (
            <Form id="user-create-form" autoComplete="off">
              <Card id="basic-info" sx={{ overflow: "visible" }}>
                <MDBox p={3}>
                  <MDTypography variant="h5">Editar Derivação</MDTypography>
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    disabled
                    name="codeDerivation"
                    label="Código derivação *"
                    type="text"
                    value={codeDerivationV}
                    error={errors.codeDerivation && touched.codeDerivation}
                    success={codeDerivationV.length > 0 && !errors.codeDerivation}
                  />
                </MDBox>

                {derivationLists && derivationLists.length > 0 ? (
                  <MDBox px={3}>
                    {verifyTypeDerivation(derivationLists, 1) && (
                      <Autocomplete
                        disabled={primaryDerivation === 1 && derivations_optionsV.length > 1}
                        options={accessArrayOptionsByType(derivationLists, 1) || []}
                        getOptionLabel={(o) => o.name}
                        value={handleValue(derivation_colorV)}
                        isOptionEqualToValue={(o, v) => o.id === v.id}
                        onChange={(e, value) => {
                          setFieldValue("derivation_color", value);
                          const nameValue = handleDerivationName(
                            [value, derivation_sizeV, derivation_uniqueV],
                            setFieldValue
                          );
                          setFieldValue("name", nameValue);
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.id}>
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) =>
                          renderInput(
                            "form",
                            params,
                            "derivation_color",
                            "Derivação Cor *",
                            handleBlur
                          )
                        }
                      />
                    )}
                    {verifyTypeDerivation(derivationLists, 2) && (
                      <Autocomplete
                        disabled={primaryDerivation === 2 && derivations_optionsV.length > 1}
                        options={accessArrayOptionsByType(derivationLists, 2) || []}
                        getOptionLabel={(o) => String(o.name)}
                        value={handleValue(derivation_sizeV)}
                        isOptionEqualToValue={(o, v) => o.id === v.id}
                        onChange={(e, value) => {
                          setFieldValue("derivation_size", value);
                          const nameValue = handleDerivationName(
                            [derivation_colorV, value, derivation_uniqueV],
                            setFieldValue
                          );
                          setFieldValue("name", nameValue);
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.id}>
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) =>
                          renderInput(
                            "form",
                            params,
                            "derivation_size",
                            "Derivação Tamanho *",
                            handleBlur
                          )
                        }
                      />
                    )}
                    {verifyTypeDerivation(derivationLists, 3) && (
                      <Autocomplete
                        disabled={primaryDerivation === 3 && derivations_optionsV.length > 1}
                        options={accessArrayOptionsByType(derivationLists, 3) || []}
                        getOptionLabel={(o) => o.name}
                        value={handleValue(derivation_uniqueV)}
                        isOptionEqualToValue={(o, v) => o.id === v.id}
                        onChange={(e, value) => {
                          setFieldValue("derivation_unique", value);
                          const nameValue = handleDerivationName(
                            [derivation_colorV, derivation_sizeV, value],
                            setFieldValue
                          );
                          setFieldValue("name", nameValue);
                        }}
                        renderOption={(props, option) => (
                          <Box component="li" {...props} key={option.id}>
                            {option.name}
                          </Box>
                        )}
                        renderInput={(params) =>
                          renderInput(
                            "form",
                            params,
                            "derivation_unique",
                            "Derivação Único *",
                            handleBlur
                          )
                        }
                      />
                    )}
                  </MDBox>
                ) : (
                  <MDBox px={3}>
                    <MDProgress
                      variant="gradient"
                      variantProgress="indeterminate"
                      color="primary"
                    />
                  </MDBox>
                )}

                <MDBox px={3}>
                  <FormField
                    name="name"
                    label="Nome *"
                    type="text"
                    value={nameV}
                    error={errors.name && touched.name}
                    success={nameV.length > 0 && !errors.name}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="cost_price"
                    label="Custo de produto *"
                    type="text"
                    value={cost_priceV}
                    onChange={(e, v) => {
                      const { value } = e.target;
                      setFieldValue("cost_price", currencyMask(value));
                    }}
                    error={errors.cost_price && touched.cost_price}
                    success={cost_priceV.length > 0 && !errors.cost_price}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="old_price"
                    label="Preço antigo"
                    type="text"
                    value={old_priceV}
                    onChange={(e, v) => {
                      const { value } = e.target;
                      setFieldValue("old_price", currencyMask(value));
                    }}
                    error={errors.old_price && touched.old_price}
                    success={old_priceV.length > 0 && !errors.old_price}
                  />
                </MDBox>

                <MDBox px={3}>
                  <FormField
                    name="sale_price"
                    label="Preço venda *"
                    type="text"
                    value={sale_priceV}
                    onChange={(e, v) => {
                      const { value } = e.target;
                      setFieldValue("sale_price", currencyMask(value));
                    }}
                    error={errors.sale_price && touched.sale_price}
                    success={sale_priceV.length > 0 && !errors.sale_price}
                  />
                </MDBox>

                <MDBox display="flex" gap="5px" py={2} px={3} ml="auto">
                  <MDButton
                    disabled={isSubmitting}
                    color="primary"
                    type="submit"
                    size="large"
                    fullWidth
                  >
                    SALVAR
                  </MDButton>
                </MDBox>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </DashboardLayout>
  );
}

export default DerivationEdit;
