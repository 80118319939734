import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    password: Yup.string()
      .required("A senha é obrigatória")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{6,}$/,
        "Senha deve ter no mínimo: 6 caracteres, 1 letra maiúscula, 1 letra minúscula, 1 número e 1 caractere especial ou símbolo"
      ),
    confirmPassword: Yup.string()
      .required("A confirmação da senha é obrigatória")
      .oneOf([Yup.ref("password")], "As senhas não são iguais"),
  }),
];

export default validations;
