import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    email: Yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
    password: Yup.string().required("A senha é obrigatória"),
  }),
];

export default validations;
