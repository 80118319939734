import axios from "axios";
import { API_URL } from "shared/constants";
import { redirectTo } from "shared/utils/redirect_to";

const requestHandler = (request) => {
  request.headers.Authorization = `Bearer ${localStorage.getItem("TOKEN")}`;

  return request;
};
const requestErrorHandler = (error) => Promise.reject(error);

const responseHandler = (response) => response;
const responseErrorHandler = (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem("TOKEN");
    redirectTo("/entrar");
  }

  if (error.response.status === 403) {
    localStorage.removeItem("TOKEN");
    redirectTo("/entrar");
  }

  return Promise.reject(error);
};

const httpService = axios.create({
  baseURL: API_URL,
});

httpService.interceptors.request.use(requestHandler, requestErrorHandler);
httpService.interceptors.response.use(responseHandler, responseErrorHandler);

export default httpService;
