import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";

export default function CircularIndeterminate({ size, color, ...rest }) {
  const theme = useTheme();
  const { palette } = theme;
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress
        {...rest}
        size={size ?? 20}
        thickness={4}
        style={{ color: color ?? palette.primary.main }}
      />
    </Box>
  );
}

CircularIndeterminate.defaultProps = {
  size: 20,
  color: undefined,
};

// Typechecking props for the CircularIndeterminate
CircularIndeterminate.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};
