export const KEY = "VALUE";
// local
// export const API_URL = "http://localhost:3000";

// dev
// export const API_URL = "https://api.dev.sistema.hiatto.com.br";

// homol
// export const API_URL = "https://api.hml.sistema.hiatto.com.br";

// prod
export const API_URL = "https://api.hiatto.com.br";
