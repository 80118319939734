/* eslint-disable no-unused-vars */

import PropTypes from "prop-types";
import MDBox from "shared/components/MDBox";

import styles from "./styles.module.css";

function ReportDerivationSizes({ productReport, sizes }) {
  const calculateTotal = (size) =>
    productReport?.derivations.reduce((total, item) => {
      if (item.size === size) {
        return total + item.amount;
      }
      return total;
    }, 0);

  // Calcular o total geral
  const totalAmount = productReport?.derivations.reduce((total, item) => total + item.amount, 0);

  return (
    <MDBox className={styles.modalTable}>
      <table id="htmlTable">
        <thead>
          <tr>
            <th>Tamanhos</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {sizes.length > 0 &&
            sizes.map((size) => (
              <tr key={size}>
                <td>{size}</td>
                <td>{calculateTotal(size)}</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            <td>{totalAmount}</td>
          </tr>
        </tfoot>
      </table>
    </MDBox>
  );
}

ReportDerivationSizes.propTypes = {
  productReport: PropTypes.objectOf(PropTypes.any).isRequired,
  sizes: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default ReportDerivationSizes;
