/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { utils, writeFile, writeFileXLSX } from "xlsx";

import { Divider, Grid, Icon, IconButton, Modal, useTheme } from "@mui/material";
import MDTypography from "shared/components/MDTypography";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";

import { dataXLSX, exportToExcel } from "pages/product/service/xlsx_stock";
import testPdfStock from "pages/product/service/pdf_stock";
import { currencyMask } from "shared/utils/masks";

import styles from "./styles.module.css";

function ReportModal({ open, onClose, data }) {
  const theme = useTheme();
  const [initialValuesFromApi, setInitialValuesFromApi] = useState();

  const handleSubmit = async (values, actions) => {
    const { format } = values;
    // try {
    //   const res = await DerivationService.createStock(data.code, stock);
    //   actions.setTouched({});
    //   actions.setSubmitting(false);
    //   toastSuccess("Estoque criado com sucesso", "");
    //   onClose();
    // } catch (e) {
    //   toastError(e.message);
    // }
  };

  const handlePrice = (value, qtd = 1) => {
    const valueTotal = value * qtd;
    const formatedPrice = valueTotal.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatedPrice;
  };

  const reduceValue = (value) => dataXLSX.reduce((total, obj) => total + Number(obj[value]), 0);
  const reduceSumAmountPrice = (value, value2) =>
    dataXLSX.reduce((total, obj) => total + Number(obj[value]) * Number(obj[value2]), 0);

  return (
    <Modal open={open} onClose={onClose}>
      <MDBox className={styles.modal}>
        <MDBox className={styles.modelHeader}>
          <Grid container>
            <Grid xs={11} sm={12} item>
              <MDTypography align="center">Relatório de Estoque</MDTypography>
            </Grid>
            <Grid item xs={1} sm={1} position="absolute" style={{ right: 5, top: 5 }}>
              <MDBox display="flex" justifyContent="flex-end">
                <IconButton size="small" onClick={onClose}>
                  <Icon fontSize="small" align="right">
                    close
                  </Icon>
                </IconButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Divider sx={{ background: theme.palette.dark.main, m: 0 }} />
        <MDBox className={styles.modelContainer}>
          <MDBox display="flex" gap={2}>
            <MDButton
              variant="gradient"
              color="primary"
              onClick={() => exportToExcel(dataXLSX)}
              sx={{ mb: 2 }}
            >
              download xlsx
            </MDButton>
            <MDButton
              variant="gradient"
              color="primary"
              onClick={() => testPdfStock(dataXLSX)}
              sx={{ mb: 2 }}
            >
              download pdf
            </MDButton>
          </MDBox>
          <MDBox className={styles.modalTable}>
            <table id="stockTable">
              <thead>
                <tr>
                  <th colSpan={7}>Relatório de estoque</th>
                </tr>
                <tr>
                  <th>Código</th>
                  <th className={styles.subject}>Descrição</th>
                  <th>Estoque</th>
                  <th>Preço de custo</th>
                  <th>Custo de estoque</th>
                  <th>Preço de venda</th>
                  <th>Valor total do estoque</th>
                </tr>
              </thead>
              <tbody>
                {dataXLSX.map((row, index) => (
                  <tr key={index}>
                    <td>{row.code}</td>
                    <td>
                      <div>{row.observations}</div>
                    </td>
                    <td>{row.amount_available_for_sale}</td>
                    <td>{handlePrice(row.cost_price)}</td>
                    <td>{handlePrice(row.cost_price, row.amount_available_for_sale)}</td>
                    <td>{handlePrice(row.sale_price)}</td>
                    <td>{handlePrice(row.sale_price, row.amount_available_for_sale)}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>Total</th>
                  <td> </td>
                  <td>{reduceValue("amount_available_for_sale")} </td>
                  <td>{handlePrice(reduceValue("cost_price"))} </td>
                  <td>
                    {handlePrice(reduceSumAmountPrice("cost_price", "amount_available_for_sale"))}
                  </td>
                  <td>{handlePrice(reduceValue("sale_price"))} </td>
                  <td>
                    {handlePrice(reduceSumAmountPrice("sale_price", "amount_available_for_sale"))}
                  </td>
                </tr>
              </tfoot>
            </table>
          </MDBox>
        </MDBox>
      </MDBox>
    </Modal>
  );
}

ReportModal.defaultProps = {
  onClose: null,
  data: [],
};

ReportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.any),
};

export default ReportModal;
