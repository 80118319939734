import { convertData } from "shared/utils";
import { utils, writeFile } from "xlsx";

const exportToXLSXMovements = (dataXLSX) => {
  if (!dataXLSX) return null;

  const tbl1 = document.querySelectorAll('[id="htmlTable"]');
  let allData = [];

  tbl1.forEach((table, index) => {
    const resMovement = dataXLSX[index];

    const headerItems = [
      [
        "Número OF:",
        resMovement?.shipping_code,
        "",
        "Período: ",
        `${convertData(resMovement?.start_date)} a ${convertData(resMovement?.final_date)}`,
      ],
      ["Cliente Produto: ", "", "", "Nota fiscal:", resMovement?.nf],
      [
        "Produto:",
        resMovement?.product_name,
        "",
        "Data da movimentação:",
        convertData(resMovement?.datetime),
      ],
      ["Pedido", "", "", "Código do produto:", resMovement?.product_code],
    ];
    const footerItems = [["Observações: "], [resMovement.observations]];

    const ws = utils.table_to_sheet(table);
    const jsonData = utils.sheet_to_json(ws, { header: 1 });

    allData = allData.concat(headerItems);
    allData = allData.concat(jsonData);
    allData = allData.concat(footerItems, ["", ""]);
  });

  const worksheet = utils.json_to_sheet(allData, { skipHeader: true });

  const new_workbook = utils.book_new();
  utils.book_append_sheet(new_workbook, worksheet, "worksheet");
  writeFile(new_workbook, "relatorio_movimentacao.xls");
  return new_workbook;
};

export default exportToXLSXMovements;
