export const weightMask = (value) => {
  if (!value) return "";
  const currencyNumber = value || "";

  const numericValue = parseInt(currencyNumber.replace(/[^0-9]/g, ""), 10) / 1000;
  return Number.isNaN(numericValue)
    ? ""
    : numericValue.toLocaleString("pt-BR", {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
};

export const centimeterMask = (value) => {
  if (!value) return "";
  const currencyNumber = value || "";

  const numericValue = parseInt(currencyNumber.replace(/[^0-9]/g, ""), 10) / 100;
  return Number.isNaN(numericValue)
    ? ""
    : numericValue.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
};

export const currencyMask = (value) => {
  if (!value) return "";
  const currencyNumber = value || "";

  const numericValue = parseInt(currencyNumber.replace(/[^0-9]/g, ""), 10) / 100;
  return Number.isNaN(numericValue)
    ? ""
    : numericValue.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
};
