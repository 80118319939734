import { isAxiosError } from "axios";
import httpService from "../../../shared/services/http";

export default class DerivationService {
  static async create(code, body) {
    const route = `/product/${code}/derivation`;

    try {
      const response = await httpService.post(route, body);

      if (!response) {
        throw new Error("Não foi possível criar derivação");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 409:
          case 502:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível criar derivação");
    }
  }

  static async getAll(code, page, limit, search) {
    const route = `/product/${code}/derivation`;
    const pagination = `?page=${page}&limit=${limit}`;

    try {
      const response = await httpService.get(`${route}${pagination}${search}`);

      if (!response) {
        throw new Error("Não foi possível carregar derivações");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 502:
            throw new Error(message);
          default:
        }
      }

      throw new Error("Não foi possível carregar derivações");
    }
  }

  static async getById(code, id) {
    const route = `/product/${code}/derivation/${id}`;
    try {
      const response = await httpService.get(route);
      if (!response) throw new Error("Não foi possível carregar derivação");
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;
        switch (status) {
          case 404:
            throw new Error("Derivação não encontrada");
          case 502:
            throw new Error(message);
          default:
        }
      }
      throw new Error("Não foi possível carregar derivação");
    }
  }

  static async update(code, body, id) {
    const route = `/product/${code}/derivation/${id}`;

    try {
      const response = await httpService.patch(route, body);

      if (!response) {
        throw new Error("Não foi possível atualizar derivação");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;
        switch (status) {
          case 404:
            throw new Error("Derivação não encontrada");

          case 409:
          case 502:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível atualizar derivação");
    }
  }

  static async delete(code, id) {
    const route = `/product/${code}/derivation/${id}`;

    try {
      const response = await httpService.delete(route);

      if (!response) {
        throw new Error("Não foi possível remover derivação");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("Derivação não encontrada");

          default:
        }
      }

      throw new Error("Não foi possível remover derivação");
    }
  }

  static async getProductDerivationLists(code) {
    const route = `/product/${code}/derivation/fields`;

    try {
      const response = await httpService.get(route);

      if (!response) {
        throw new Error("Não foi possível carregar listas de produto derivação");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("Listas de produto derivação não encontrada");

          default:
        }
      }

      throw new Error("Não foi possível carregar listas de produto derivação");
    }
  }

  static async whoAmI() {
    const route = `/who-am-i`;

    try {
      const response = await httpService.get(route);

      if (!response) {
        throw new Error("Não foi possível carregar informações");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("Derivação não encontrada");

          default:
        }
      }

      throw new Error("Não foi possível carregar informações");
    }
  }

  static async createStock(code, body) {
    const route = `/stock/${code}`;

    try {
      const response = await httpService.post(route, body);

      if (!response) {
        throw new Error("Não foi possível fazer movimentação de estoque");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 409:
          case 502:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível fazer movimentação de estoque");
    }
  }

  static async getTotalProductsById(code) {
    const route = `/product/${code}/stock`;
    try {
      const response = await httpService.get(route);
      if (!response) throw new Error("Não foi possível carregar quantidade total");
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;
        switch (status) {
          case 404:
            throw new Error("Derivação não encontrada");
          case 502:
            throw new Error(message);
          default:
        }
      }
      throw new Error("Não foi possível carregar quantidade total");
    }
  }

  static async getDerivationsByBarcode(code, barcode) {
    const route = `/product/${code}/derivation-barcode/${barcode}`;
    try {
      const response = await httpService.get(route);
      if (!response) throw new Error("Não foi possível carregar derivação");
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;
        switch (status) {
          case 404:
            throw new Error("Derivação não encontrada");
          case 502:
            throw new Error(message);
          default:
        }
      }
      throw new Error("Não foi possível carregar derivações");
    }
  }

  static async syncDerivations(code) {
    const route = `/product/${code}/derivation/sync`;
    try {
      const response = await httpService.get(route);
      if (!response) throw new Error("Não foi possível fazer sincronização");
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;
        switch (status) {
          case 404:
            throw new Error("Derivação não encontrada");
          case 502:
            throw new Error(message);
          default:
        }
      }
      throw new Error("Não foi possível carregar derivações");
    }
  }

  static async replicatePrices(code, body) {
    const route = `/product/${code}/derivation-price`;

    try {
      const response = await httpService.patch(route, body);

      if (!response) {
        throw new Error("Não foi possível fazer movimentação de estoque");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 409:
          case 502:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível fazer movimentação de estoque");
    }
  }

  static async getDataStockReport(code) {
    const route = `/report/stock/${code}`;
    try {
      const response = await httpService.get(route);
      if (!response) throw new Error("Não foi possível carregar dados");
      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          default:
        }
      }
      throw new Error("Não foi possível carregar dados");
    }
  }
}
