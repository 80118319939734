/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
import { TextField, Icon, Popover, IconButton } from "@mui/material";
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { ErrorMessage } from "formik";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MDTypography from "../MDTypography";

function FormFieldTreeView({
  treeArray,
  label,
  name,
  fieldVariant,
  formData,
  value,
  fieldShrink,
  isSequential,
  ...rest
}) {
  const { setFieldValue, handleBlur } = formData;

  const [selectedItem, setSelectedItem] = useState(value || "");
  const [selectedItemLabel, setSelectedItemLabel] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);
  const [expanded, setExpanded] = useState([]);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleIconClick = (nodeId) => {
    if (expanded.includes(nodeId)) {
      setExpanded(expanded.filter((id) => id !== nodeId));
    } else {
      setExpanded([...expanded, nodeId]);
    }
  };

  const handleTreeItemClick = (node) => {
    setSelectedItem(node);
    setSelectedItemLabel(node.name);
    setFieldValue(name, node);
    handleClose();
  };

  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={String(nodes.id)}
      label={
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            role="button"
            onClick={() => handleIconClick(nodes.id)}
            tabIndex={0}
            onKeyPress={(e) => {}}
          >
            {nodes.icon}
          </span>
          <span
            role="button"
            onClick={() => handleTreeItemClick(nodes)}
            style={{ display: "flex", fontSize: "14px" }}
            tabIndex={0}
            onKeyPress={(e) => {}}
          >
            <span style={{ display: "flex", alignItems: "center", margin: "0 5px" }}>
              <Icon fontSize="small" color="secondary">
                folder
              </Icon>
            </span>
            {nodes.name}
          </span>
        </div>
      }
      onClick={(event) => event.stopPropagation()}
    >
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </TreeItem>
  );

  const handleChange = (event) => {
    const { content } = event.target;
    if (content === "") {
      setSelectedItem("");
      setFieldValue(name, "");
      setSelectedItemLabel("");
      setTimeout(() => handleClose(), 50);
    }
  };

  function findItemById(items, id) {
    const foundItem = items.find((item) => item.id === id);
    if (foundItem) {
      return [foundItem.name];
    }
    for (const item of items) {
      if (item.children) {
        const foundInChildren = findItemById(item.children, id);
        if (foundInChildren) {
          return [item.name, ...foundInChildren];
        }
      }
    }

    return null;
  }

  useEffect(() => {
    if (isSequential) {
      if (selectedItem !== "") {
        const sequence = findItemById(treeArray, selectedItem.id);
        const sequenceLabel = sequence.length > 1 ? sequence.join(" >> ") : sequence[0];
        setSelectedItemLabel(sequenceLabel);
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    setSelectedItem(value);
  }, [value]);

  return (
    <>
      <TextField
        {...rest}
        id="tree-view"
        label={label}
        name={name}
        value={selectedItemLabel}
        onClick={handleClick}
        variant={fieldVariant}
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton
              sx={{ visibility: selectedItem ? "visible" : "hidden", zIndex: 5 }}
              size="small"
              onClick={() => {
                handleChange({ target: { content: "" } });
              }}
            >
              <Icon> clear </Icon>
            </IconButton>
          ),
        }}
        onBlur={handleBlur}
      />
      <MDTypography component="div" variant="caption" color="error" fontWeight="regular" mt={0.75}>
        <ErrorMessage name={name} />
      </MDTypography>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            width: anchorEl ? anchorEl.clientWidth : "auto",
            maxWidth: "none",
            maxHeight: "150px",
            overflow: "auto",
          },
        }}
      >
        <TreeView
          aria-label="tree-view"
          defaultExpandIcon={<Icon fontSize="small">keyboard_arrow_right</Icon>}
          defaultCollapseIcon={<Icon>keyboard_arrow_down</Icon>}
        >
          {treeArray.map((node) => renderTree(node))}
        </TreeView>
      </Popover>
    </>
  );
}

FormFieldTreeView.defaultProps = {
  label: "",
  value: null,
  isSequential: false,
  fieldVariant: "standard",
  fieldShrink: false,
};

FormFieldTreeView.propTypes = {
  treeArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.any]),
  formData: PropTypes.objectOf(PropTypes.any).isRequired,

  isSequential: PropTypes.bool,
  fieldVariant: PropTypes.string,
  fieldShrink: PropTypes.bool,
};

export default FormFieldTreeView;
