/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";

import { Autocomplete, Grid } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDInput from "shared/components/MDInput";
import {
  compareOptionToValue,
  handleValue,
  adornedInputPropsAutocomplete,
  handleNoOptionsAutocomplete,
} from "shared/components/autocomplete/autocomplete_configs";
import FormFieldAutocomplete from "shared/components/form_field/form_field_autocomplete";
import FormFieldMemo from "shared/components/form_field/form_field_memo";
import PropTypes from "prop-types";
import ProductsService from "pages/product/service";
import toastError from "shared/components/snackbar/error/toast_error";

function ProductCESTAutocomplete({ formData }) {
  const { values, setFieldValue, handleBlur } = formData;
  const { cest: cestV } = values;

  const [cestCode, setCestCode] = useState("");
  const [cestOptions, setCestOptions] = useState();
  const [cestOptionsPage, setCestOptionsPage] = useState(1);
  const [cestOptionsTotalPages, setCestOptionsTotalPages] = useState(1);
  const [cestOptionsSearch, setCestOptionsSearch] = useState(1);
  const [loading, setLoading] = useState(false);

  const loadNCMs = async (search = "", setField, page = 1, limit = 100) => {
    try {
      setLoading(true);
      const res = await ProductsService.getProductCEST(page, limit, search);
      if (res) {
        setCestOptions(res.data);
        setCestOptionsPage(res.meta.page);
        setCestOptionsTotalPages(res.meta.total_pages);

        if (res.data.length > 0 && search.includes("code=")) setField("cest", res.data[0] ?? "");
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreNCMs = async (search = "", page = 1, limit = 100) => {
    try {
      setLoading(true);
      const res = await ProductsService.getProductCEST(page, limit, search);
      if (res) {
        setCestOptions([...cestOptions, ...res.data]);
        setCestOptionsPage(res.meta.page);
        setCestOptionsTotalPages(res.meta.total_pages);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAutocompleteScroll = (event) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1 && cestOptionsPage < cestOptionsTotalPages) {
      loadMoreNCMs(cestOptionsSearch, cestOptionsPage + 1);
    }
  };

  useEffect(() => {
    if (cestV && !cestCode) setCestCode(cestV.code);
  }, [cestV]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={2}>
        <MDInput
          name="cest code"
          label="Código CEST"
          variant="standard"
          type="text"
          value={cestCode}
          onChange={(event) => {
            const { value } = event.target;
            setCestCode(value);
          }}
          onBlur={(event) => {
            const { value } = event.target;
            if (value && value.length >= 3) {
              setFieldValue("cest", "");
              loadNCMs(`&code=${value.trim()}`, setFieldValue);
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={10}>
        <Autocomplete
          options={cestOptions ?? []}
          noOptionsText={handleNoOptionsAutocomplete(cestOptions, "async")}
          getOptionLabel={(o) => `${o?.code} - ${o?.description}`}
          value={handleValue(cestV)}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          onChange={(e, value) => {
            setFieldValue("cest", value);
            setCestCode(value?.code ?? "");
          }}
          renderOption={(props, option) => (
            <MDBox {...props} component="li" key={`${option.code}_${option.description}`}>
              {option.code} - {option.description}
            </MDBox>
          )}
          renderInput={(params) => (
            <FormFieldAutocomplete
              {...params}
              fieldVariant="standard"
              type="text"
              label="Descrição do CEST"
              name="cest"
              onChange={(event) => {
                const { value } = event.target;
                if (value && value.length >= 3) {
                  setCestOptionsSearch(`&description=${value.trim()}`);
                  setCestOptionsPage(1);
                  loadNCMs(`&description=${value.trim()}`, setFieldValue, cestOptionsPage);
                }
              }}
              onBlur={(event) => {
                handleBlur(event);
                // if (!event.target.value) setCestOptions([]);
              }}
              InputProps={adornedInputPropsAutocomplete(loading, params)}
            />
          )}
          ListboxProps={{ onScroll: handleAutocompleteScroll }}
        />
      </Grid>
    </Grid>
  );
}

ProductCESTAutocomplete.propTypes = {
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProductCESTAutocomplete;
