import { isAxiosError } from "axios";
import httpService from "../../../shared/services/http";

export default class UserService {
  static async create(role, permissions, name, email) {
    const route = `/user`;

    try {
      const response = await httpService.post(route, {
        role,
        permissions,
        name,
        email,
      });

      if (!response) {
        throw new Error("Não foi possível criar usuário");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        const message = error.response.data?.message;

        switch (status) {
          case 409:
          case 502:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível criar usuário");
    }
  }

  static async getAll(page, limit, search) {
    const route = `/user`;
    const pagination = `?page=${page}&limit=${limit}`;

    try {
      const response = await httpService.get(`${route}${pagination}${search}`);

      if (!response) {
        throw new Error("Não foi possível carregar usuários");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          default:
        }
      }

      throw new Error("Não foi possível carregar usuários");
    }
  }

  static async getById(id) {
    const route = `/user/${id}`;

    try {
      const response = await httpService.get(route);

      if (!response) {
        throw new Error("Não foi possível carregar usuário");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("Usuário não encontrado");

          default:
        }
      }

      throw new Error("Não foi possível carregar usuário");
    }
  }

  static async update(id, role, permissions, name, email, status) {
    const route = `/user/${id}`;

    try {
      const response = await httpService.patch(route, {
        role,
        permissions,
        name,
        email,
        status,
      });

      if (!response) {
        throw new Error("Não foi possível atualizar usuário");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorStatus = error.response?.status;
        const message = error.response.data?.message;

        switch (errorStatus) {
          case 404:
            throw new Error("Usuário não encontrado");

          case 409:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível atualizar usuário");
    }
  }

  static async updateStatus(id, status) {
    const route = `/user/${id}`;

    try {
      const response = await httpService.patch(route, {
        status,
      });

      if (!response) {
        throw new Error("Não foi possível atualizar usuário");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const errorStatus = error.response?.status;
        const message = error.response.data?.message;

        switch (errorStatus) {
          case 404:
            throw new Error("Usuário não encontrado");

          case 409:
            throw new Error(message);

          default:
        }
      }

      throw new Error("Não foi possível atualizar usuário");
    }
  }

  static async delete(id) {
    const route = `/user/${id}`;

    try {
      const response = await httpService.delete(route);

      if (!response) {
        throw new Error("Não foi possível remover usuário");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("Usuário não encontrado");

          default:
        }
      }

      throw new Error("Não foi possível remover usuário");
    }
  }

  static async whoAmI() {
    const route = `/who-am-i`;

    try {
      const response = await httpService.get(route);

      if (!response) {
        throw new Error("Não foi possível carregar informações");
      }

      return response.data;
    } catch (error) {
      if (isAxiosError(error)) {
        const status = error.response?.status;
        // const message = error.response.data?.message;

        switch (status) {
          case 404:
            throw new Error("Usuário não encontrado");

          default:
        }
      }

      throw new Error("Não foi possível carregar informações");
    }
  }
}
