/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";

import { Autocomplete, Grid } from "@mui/material";
import MDBox from "shared/components/MDBox";
import {
  compareOptionToValue,
  handleValue,
  adornedInputPropsAutocomplete,
  handleNoOptionsAutocomplete,
} from "shared/components/autocomplete/autocomplete_configs";
import PropTypes from "prop-types";
import ProductsService from "pages/product/service";
import toastError from "shared/components/snackbar/error/toast_error";
import MDInput from "shared/components/MDInput";
import { useDebounce } from "use-lodash-debounce";

const inputStyle = {
  style: {
    padding: "0.535rem",
  },
};

function ProductAutocompleteAsync({ productState, productStateItem, productCodeState }) {
  const { productOptions, setProductOptions } = productState;
  const { productItem, setProductItem } = productStateItem;
  const { filterProductCode, setFilterProductCode } = productCodeState;

  const [productOptionsPage, setProductOptionsPage] = useState(1);
  const [productOptionsTotalPages, setProductOptionsTotalPages] = useState(1);
  const [productOptionsLimit, setProductOptionsLimit] = useState(100);
  const [productOptionsSearch, setProductOptionsSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const debouncedValue = useDebounce(productOptionsSearch, 500);

  const loadProducts = async (search = "", page = 1, limit = 100) => {
    try {
      setLoading(true);
      const res = await ProductsService.getAll(page, limit, search);
      if (res) {
        setProductOptions(res.data);
        setProductOptionsPage(res.meta.page);
        setProductOptionsTotalPages(res.meta.total_pages);

        if (search.includes("code") && res.data.length > 0) setProductItem(res.data[0]);
        else if (search.includes("code") && res.data.length === 0)
          toastError("Código informado não possui produtos");
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreProducts = async (search = "", page = 1, limit = 100) => {
    try {
      setLoading(true);
      const res = await ProductsService.getAll(page, limit, search);
      if (res) {
        setProductOptions([...productOptions, ...res.data]);
        setProductOptionsPage(res.meta.page);
        setProductOptionsTotalPages(res.meta.total_pages);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleProductsScroll = (event) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1 && productOptionsPage < productOptionsTotalPages) {
      loadMoreProducts(productOptionsSearch, productOptionsPage + 1, productOptionsLimit);
    }
  };

  useEffect(() => {
    if (debouncedValue) loadProducts(productOptionsSearch, 1);
  }, [debouncedValue]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={3}>
        <MDInput
          label="Código do produto"
          type="text"
          name="filter"
          value={filterProductCode}
          size="small"
          fullWidth
          onChange={(e) => {
            setFilterProductCode(e.target.value);
          }}
          onBlur={(event) => {
            const { value } = event.target;
            if (value && value.length >= 3) {
              loadProducts(`&code=${value.trim()}`);
            }
          }}
          inputProps={inputStyle}
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <Autocomplete
          options={productOptions ?? []}
          noOptionsText={handleNoOptionsAutocomplete(productOptions, "async")}
          getOptionLabel={(o) => `${o?.code} - ${o?.name}`}
          value={handleValue(productItem)}
          isOptionEqualToValue={(o, v) => compareOptionToValue(o, v, "code")}
          onChange={(e, value) => {
            setProductItem(value);
            if (value && value.code) setFilterProductCode(value.code);
          }}
          renderOption={(props, option) => (
            <MDBox {...props} component="li" key={`${option.code}_${option.name}`}>
              {option.code} - {option.name}
            </MDBox>
          )}
          renderInput={(params) => (
            <MDInput
              {...params}
              variant="outlined"
              type="text"
              label="Nome do produto *"
              name="product"
              size="small"
              onChange={(event) => {
                const { value } = event.target;
                if (value && value.length >= 3) {
                  setProductOptionsSearch(`&name=${value.trim()}`);
                  setProductOptionsPage(1);
                }
              }}
              onBlur={(event) => {
                if (!event.target.value) setProductOptions([]);
              }}
              InputProps={adornedInputPropsAutocomplete(loading, params)}
            />
          )}
          ListboxProps={{ onScroll: handleProductsScroll }}
        />
      </Grid>
    </Grid>
  );
}

ProductAutocompleteAsync.defaultProps = {};

ProductAutocompleteAsync.propTypes = {
  productState: PropTypes.objectOf(PropTypes.any).isRequired,
  productStateItem: PropTypes.objectOf(PropTypes.any).isRequired,
  productCodeState: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProductAutocompleteAsync;
