import { toast } from "react-toastify";
import ErrorSnackbar from "shared/components/snackbar/error";

const config = { autoClose: 5000 };

const toastError = (text = "Ocorreu um erro.", title) => {
  const titleToast = title || "Erro";
  toast.error(
    <ErrorSnackbar
      content={text || "Informações incorretas ou sessão inválida."}
      title={titleToast}
    />,
    config
  );
};

export default toastError;
