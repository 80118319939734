import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    codeDerivation: Yup.string().required("Código pai é obrigatório"),
    name: Yup.string().required("Nome é obrigatório"),
    // derivations: Yup.array()
    //   .of(Yup.string().required("Derivação é obrigatória").nullable())
    //   .required("Derivação é obrigatória")
    //   .min(1, "O campo deve ter no mínimo 1 derivação selecionada"),
    // derivations_options: Yup.array()
    // .when(registration_category.name, {
    //   is: (value) => value && value === "recurrent",
    //   then: Yup.array().required(date.errorMsg).min(1, date.minSize).nullable(),
    // })
    // .nullable(),

    derivation_color: Yup.object()
      .when("derivations_options", {
        is: (value) => value && value.includes(1),
        then: Yup.object().required("Derivação cor é obrigatória").nullable(),
      })
      .nullable(),
    derivation_size: Yup.object()
      .when("derivations_options", {
        is: (value) => value && value.includes(2),
        then: Yup.object().required("Derivação tamanho é obrigatória").nullable(),
      })
      .nullable(),
    derivation_unique: Yup.object()
      .when("derivations_options", {
        is: (value) => value && value.includes(3),
        then: Yup.object().required("Derivação único é obrigatória").nullable(),
      })
      .nullable(),

    // old_price: Yup.string().required("Preço antigo é obrigatório"),
    sale_price: Yup.string().required("Preço venda é obrigatório"),
    cost_price: Yup.string().required("Custo de produto é obrigatório"),
  }),
];

export default validations;
