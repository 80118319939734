import { toast } from "react-toastify";
import SuccessSnackBar from "shared/components/snackbar/success";

const config = { autoClose: 5000 };

function toastSuccess(
  text,
  title = "",
  isRedirect = false,
  navigateRoute,
  navigateContent = "Redirecionar para listagem"
) {
  const titleToast = title || "Sucesso";
  toast.success(
    <SuccessSnackBar
      content={text}
      title={titleToast}
      isRedirect={isRedirect}
      navigateRoute={navigateRoute}
      navigateContent={navigateContent}
    />,
    config
  );
}

export default toastSuccess;
