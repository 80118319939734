const initialValues = {
  codeDerivation: "",
  name: "",
  derivations: [""],
  old_price: "",
  sale_price: "",
  cost_price: "",

  derivations_options: [],
  derivation_color: "",
  derivation_size: "",
  derivation_unique: "",
};

export default initialValues;
