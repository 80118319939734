import { jwtDecode } from "jwt-decode";
import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import LoginService from "../pages/login/service";

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [token, setToken] = useState();
  const [keeplogged, setKeeplogged] = useState(false);
  const [userLogged, setUserLogged] = useState();

  const handleKeepLogged = useCallback((value) => {
    localStorage.setItem("keepLogged", value);
    setKeeplogged(value);
  });

  const updateToken = useCallback((value) => {
    setToken(JSON.parse(JSON.stringify(value)));
  });

  useEffect(() => {
    const localStorageToken = localStorage.getItem("TOKEN");
    const isKeepLogged = localStorage.getItem("keepLogged");

    if (localStorageToken) {
      setToken(JSON.parse(JSON.stringify(localStorageToken)));
    }

    if (isKeepLogged) {
      setKeeplogged(JSON.parse(isKeepLogged) === true);
    }
  }, []);

  useEffect(() => {
    if (token) {
      localStorage.setItem("TOKEN", token);

      const user = jwtDecode(token);
      setUserLogged(user);
    } else {
      setUserLogged();
    }
  }, [token]);

  const handleLogin = useCallback(async ({ email, password }) => {
    const result = await LoginService.login(email, password);
    const accessToken = result.access_token;

    localStorage.setItem("TOKEN", accessToken);

    setToken(accessToken);

    return jwtDecode(accessToken);
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.removeItem("TOKEN");

    handleKeepLogged(false);
    setToken(undefined);
  }, [handleKeepLogged]);

  const authenticated = useMemo(() => !!token, [token]);

  const value = useMemo(
    () => ({
      authenticated,
      handleLogin,
      handleLogout,
      handleKeepLogged,
      keeplogged,
      updateToken,
      token,
      userLogged,
    }),
    [
      authenticated,
      handleLogin,
      handleLogout,
      handleKeepLogged,
      keeplogged,
      updateToken,
      token,
      userLogged,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuthContext = () => {
  const {
    authenticated,
    handleLogin,
    handleLogout,
    handleKeepLogged,
    keeplogged,
    updateToken,
    token,
    userLogged,
  } = useContext(AuthContext);

  return {
    authenticated,
    handleLogin,
    handleLogout,
    handleKeepLogged,
    keeplogged,
    updateToken,
    token,
    userLogged,
  };
};
