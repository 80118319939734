/* eslint-disable no-unused-vars */

import PropTypes from "prop-types";
import MDBox from "shared/components/MDBox";

import styles from "./styles.module.css";

function ReportDerivationColorsSizes({ productReport, sizes, colors }) {
  const calculateTotal = (color) =>
    productReport?.derivations.reduce((total, item) => {
      if (item.color === color) {
        return total + item.amount;
      }
      return total;
    }, 0);

  // Função para calcular o total de uma coluna
  const calculateColumnTotal = (size) =>
    productReport?.derivations.reduce((total, item) => {
      if (item.size === size) {
        return total + item.amount;
      }
      return total;
    }, 0);

  // Calcular o total geral
  const totalAmount = productReport?.derivations.reduce((total, item) => total + item.amount, 0);

  return (
    <MDBox className={styles.modalTable}>
      <table id="htmlTable">
        <thead>
          <tr>
            <th>Cor</th>
            {sizes.length > 0 && sizes.map((size) => <th key={size}>{size}</th>)}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {colors.length > 0 &&
            colors.map((color) => (
              <tr key={color}>
                <td>{color}</td>
                {sizes.map((size) => {
                  const item = productReport.derivations.find(
                    (d) => d.color === color && d.size === size
                  );
                  return <td key={`${color}-${size}`}>{item ? item.amount : 0}</td>;
                })}
                <td>{calculateTotal(color)}</td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td>Total</td>
            {sizes.map((size) => (
              <td key={`total-${size}`}>{calculateColumnTotal(size)}</td>
            ))}
            <td>{totalAmount}</td>
          </tr>
        </tfoot>
      </table>
    </MDBox>
  );
}

ReportDerivationColorsSizes.defaultProps = {
  sizes: [],
};

ReportDerivationColorsSizes.propTypes = {
  productReport: PropTypes.objectOf(PropTypes.any).isRequired,
  colors: PropTypes.arrayOf(PropTypes.any).isRequired,
  sizes: PropTypes.arrayOf(PropTypes.any),
};

export default ReportDerivationColorsSizes;
