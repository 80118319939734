/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */

import { Grid, Icon, IconButton } from "@mui/material";

import { ErrorMessage, FieldArray } from "formik";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDTypography from "shared/components/MDTypography";

import { PropTypes } from "prop-types";
import FormFieldTreeView from "shared/components/form_field/form_field_tree_view";

function FieldArrayProductsCategories({ name, options, label, valueField, formData, children }) {
  const { setFieldValue, values, errors, handleBlur } = formData;
  const { categories } = values;

  const handleDelete = (array, index) => {
    const dataFilter = array;
    dataFilter.splice(index, 1);
    setFieldValue(name, dataFilter);
  };

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <>
          {categories &&
            categories.map((item, index) => (
              <Grid container spacing={1} key={index}>
                <Grid item xs={12} sm={11}>
                  <FormFieldTreeView
                    name={`${name}[${index}]`}
                    value={valueField[index]}
                    label="Selecione"
                    formData={{ setFieldValue, errors, handleBlur }}
                    treeArray={options}
                    isSequential
                  />
                </Grid>

                {categories.length > 0 && (
                  <Grid item xs={12} sm={1}>
                    <IconButton
                      sx={{ pt: 3 }}
                      title="Deletar"
                      color="error"
                      onClick={() => handleDelete(valueField, index, setFieldValue)}
                    >
                      <Icon>delete</Icon>
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
          <MDBox>
            <MDTypography
              component="div"
              variant="caption"
              color="error"
              align="center"
              fontWeight="regular"
              mt={0.75}
            >
              {typeof errors[name] === "string" ? <ErrorMessage name={name} /> : <MDBox />}
            </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDButton
              onClick={() => arrayHelpers.push("")}
              variant="gradient"
              color="info"
              mb={2}
              size="small"
            >
              Adicionar {label}
            </MDButton>
          </MDBox>
        </>
      )}
    />
  );
}

FieldArrayProductsCategories.defaultProps = {
  children: false,
};

FieldArrayProductsCategories.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])).isRequired,
  valueField: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object]))
    .isRequired,
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  children: PropTypes.node,
};

export default FieldArrayProductsCategories;
