/* eslint-disable no-unused-vars */
/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import { Icon } from "@mui/material";
import ChangePassword from "pages/change_password";
import ForgotPassword from "pages/forgot_password";
import Login from "pages/login";
import UserCreate from "pages/user/create";
import UserEdit from "pages/user/edit";
import UserList from "pages/user/list";

import ProductList from "pages/product/list";
import ProductCreate from "pages/product/create";
import ProductEdit from "pages/product/edit";
import ReportBarcode from "pages/product/report_barcode";

import DerivationList from "pages/derivation/list";
import DerivationCreate from "pages/derivation/create";
import DerivationEdit from "pages/derivation/edit";

import MovementsList from "pages/movements/list";
import MovementCreate from "pages/movements/create";

const routes = [
  {
    name: "Login",
    key: "login",
    route: "/entrar",
    component: <Login />,
  },
  {
    name: "Forgot Password",
    key: "forgot-password",
    route: "/esqueci-a-senha",
    component: <ForgotPassword />,
  },
  {
    name: "Change Password",
    key: "change-password",
    route: "/trocar-a-senha/:token",
    component: <ChangePassword />,
  },
  {
    roles: [0],
    type: "collapse",
    noCollapse: true,
    name: "Usuários",
    key: "users",
    route: "/usuarios",
    icon: <Icon fontSize="medium">people</Icon>,
    component: <UserList />,
  },
  {
    roles: [0],
    name: "Criar Usuário",
    key: "users-create",
    route: "/usuarios/criar",
    component: <UserCreate />,
  },
  {
    roles: [0],
    name: "Editar Usuário",
    key: "users-edit",
    route: "/usuarios/editar/:id",
    component: <UserEdit />,
  },

  {
    roles: [0, 1],
    type: "collapse",
    noCollapse: false,
    name: "Produtos",
    key: "products",
    // route: "/produtos",
    icon: <Icon fontSize="medium">inventory</Icon>,
    collapse: [
      {
        roles: [0, 1],
        type: "collapse",
        noCollapse: false,
        name: "Produtos",
        key: "products-list",
        route: "/produtos",
        // icon: <Icon fontSize="medium">inventory</Icon>,
        component: <ProductList />,
      },
      {
        roles: [0, 1],
        type: "collapse",
        noCollapse: true,
        name: "Código de barras",
        key: "report-barcode",
        route: "/relatorio-cb",
        // icon: <Icon fontSize="medium">inventory</Icon>,
        component: <ReportBarcode />,
      },
    ],
  },

  {
    roles: [0, 1],
    name: "Criar Produto",
    key: "products-create",
    route: "/produtos/criar",
    component: <ProductCreate />,
  },
  {
    roles: [0, 1],
    name: "Editar Produto",
    key: "products-edit",
    route: "/produtos/editar/:id",
    component: <ProductEdit />,
  },

  {
    roles: [0, 1],
    name: "Derivações",
    key: "derivation",
    route: "/produtos/:code/derivacoes",
    component: <DerivationList />,
  },
  {
    roles: [0, 1],
    name: "Criar Derivação",
    key: "derivation-create",
    route: "/produtos/:code/derivacoes/criar",
    component: <DerivationCreate />,
  },
  {
    roles: [0, 1],
    name: "Editar Derivação",
    key: "derivation-edit",
    route: "/produtos/:code/derivacoes/editar/:id",
    component: <DerivationEdit />,
  },

  {
    roles: [0, 1],
    name: "Movimentações",
    type: "collapse",
    icon: <Icon fontSize="medium">change_circle</Icon>,
    key: "movements-options",
    collapse: [
      {
        roles: [0, 1],
        type: "collapse",
        noCollapse: true,
        name: "Lista de movimentações ",
        key: "movements-list",
        route: "/movimentacao/lista",
        icon: <Icon fontSize="medium">change_circle</Icon>,
        component: <MovementsList />,
      },
      {
        roles: [0, 1],
        permissions: ["add-stock", "remove-stock"],
        type: "collapse",
        noCollapse: true,
        name: "Fazer movimentação",
        key: "movements-create",
        route: "/movimentacao/criar",
        icon: <Icon fontSize="medium">change_circle</Icon>,
        component: <MovementCreate />,
      },
    ],
  },

  // Logout
  {
    type: "logout",
    noCollapse: true,
    icon: <Icon fontSize="medium">logout</Icon>,
    name: "Sair",
    key: "logout",
  },
];

export default routes;
