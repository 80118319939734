/* eslint-disable no-unused-vars */
import { Autocomplete, Card, Grid, Icon } from "@mui/material";
import NotificationItem from "examples/Items/NotificationItem";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDInput from "shared/components/MDInput";
import MDProgress from "shared/components/MDProgress";
import MDTypography from "shared/components/MDTypography";
import toastError from "shared/components/snackbar/error/toast_error";
import DataTableApi from "shared/components/tables/data_table_api";

import { handleValue } from "shared/components/autocomplete/autocomplete_configs";
import { movement_operation_type_list } from "shared/utils/select_lists";
import { adornedInputCancelSearch } from "shared/components/input/input_configs";
import { convertData } from "shared/utils";

import MovementsService from "../service";
import ProductAutocompleteAsync from "../components/autocomplete_product_async";
import ReportModal from "../components/report_modal";

function MovementsList() {
  const navigate = useNavigate();

  const [dataTable, setDataTable] = useState([]);
  const [rowSelected, setRowSelected] = useState();

  // filter
  const [filterCode, setFilterCode] = useState("");
  const [filterInitialDate, setFilterInitialDate] = useState("");
  const [filterFinalDate, setFilterFinalDate] = useState("");
  const [filterOperationType, setFilterOperationType] = useState("");
  const [filterSearch, setFilterSearch] = useState("");
  const [filterProductCode, setFilterProductCode] = useState("");
  const [productItem, setProductItem] = useState("");
  const [productOptions, setProductOptions] = useState();

  const [loadingData, setLoadingData] = useState(false);
  const [tableLimit, setTableLimit] = useState(10);
  const [tablePage, setTablePage] = useState(1);
  const [tableTotal, setTableTotal] = useState(0);

  // modal report
  const [openReport, setOpenReport] = useState(false);

  const loadMovements = async (page = 1, limit = 5, search = "") => {
    try {
      setLoadingData(true);
      const res = await MovementsService.getAll(page, limit, search);
      if (res) {
        setDataTable(res);
        setTableTotal(res?.meta?.totalCount);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoadingData(false);
    }
  };

  const dataTableData = {
    columns: [
      {
        Header: "Código do romaneio",
        accessor: "shipping_code",
      },
      {
        Header: "Código do produto",
        accessor: "product_code",
      },
      {
        Header: "Nome do produto",
        accessor: "product_name",
      },
      {
        Header: "Data de criação",
        accessor: "datetime",
        Cell: ({ value }) => convertData(value),
      },
      {
        Header: "Tipo",
        accessor: "type",
        Cell: ({ value }) => (value === 1 ? "Entrada" : "Saída"),
      },
    ],

    rows: dataTable?.data || [],
  };

  const handleClickSearch = () => {
    let search = "";
    if (filterCode) search += `&shipping_code=${filterCode.trim()}`;
    if (filterInitialDate) search += `&start_date=${new Date(filterInitialDate).toISOString()}`;
    if (filterFinalDate) search += `&end_date=${new Date(filterFinalDate).toISOString()}`;
    if (filterOperationType) search += `&operation_type=${filterOperationType.id}`;
    if (productItem || filterProductCode)
      search += `&product_code=${productItem.code || filterProductCode}`;

    setFilterSearch(search.trim());
    setTablePage(1);
    loadMovements(1, tableLimit, search);
  };

  useEffect(() => loadMovements(tablePage, tableLimit, filterSearch), [tablePage, tableLimit]);
  return (
    <>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pb={3}>
          <MDBox mb={3}>
            <MDBox pb={1}>
              <Card>
                <MDBox p={3} lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="medium" mb={1}>
                    Filtros
                  </MDTypography>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <MDInput
                        label="Código romaneio"
                        type="text"
                        name="filter"
                        value={filterCode}
                        size="small"
                        fullWidth
                        InputProps={adornedInputCancelSearch(filterCode, setFilterCode)}
                        onChange={(e) => setFilterCode(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MDInput
                        label="Data inicial"
                        type="date"
                        name="filter"
                        value={filterInitialDate}
                        size="small"
                        fullWidth
                        onChange={(e) => {
                          const { value } = e.target;
                          setFilterInitialDate(value);
                          if (filterFinalDate && filterFinalDate < value) {
                            setFilterFinalDate("");
                          }
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MDInput
                        label="Data final"
                        type="date"
                        name="filter"
                        value={filterFinalDate}
                        size="small"
                        fullWidth
                        onChange={(e) => setFilterFinalDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ min: filterInitialDate ?? "" }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        options={movement_operation_type_list}
                        getOptionLabel={(o) => o.type}
                        value={handleValue(filterOperationType)}
                        onChange={(e, value) => setFilterOperationType(value)}
                        renderInput={(params) => (
                          <MDInput
                            {...params}
                            label="Tipo de operação"
                            size="small"
                            sx={{ height: 15 }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <ProductAutocompleteAsync
                        productState={{ productOptions, setProductOptions }}
                        productStateItem={{ productItem, setProductItem }}
                        productCodeState={{ filterProductCode, setFilterProductCode }}
                      />
                    </Grid>
                    <Grid item xs={2} display="flex" mt={2}>
                      <MDButton
                        variant="gradient"
                        color="primary"
                        size="small"
                        fullWidth
                        onClick={handleClickSearch}
                      >
                        BUSCAR
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </Card>
            </MDBox>
            <Card>
              <MDBox p={3} lineHeight={1}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Movimentações
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MDBox width="12rem" ml="auto">
                      <MDButton
                        disabled={!filterSearch}
                        variant="gradient"
                        color="primary"
                        size="small"
                        fullWidth
                        onClick={() => setOpenReport(true)}
                      >
                        Relatório de movimentações
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
              {loadingData ? (
                <MDProgress variant="gradient" variantProgress="indeterminate" color="primary" />
              ) : (
                <DataTableApi
                  table={dataTableData}
                  onRowSelected={setRowSelected}
                  entriesPerPage={{ defaultValue: tableLimit }}
                  setTableLimit={setTableLimit}
                  tableLimit={tableLimit}
                  setTablePage={setTablePage}
                  tablePage={tablePage}
                  tableTotal={tableTotal}
                >
                  {[
                    <NotificationItem
                      icon={<Icon>create</Icon>}
                      title="edit"
                      key="edit"
                      onClick={(value) => {
                        navigate(`/produtos/editar/${value.code}`);
                      }}
                    />,
                  ]}
                </DataTableApi>
              )}
            </Card>
          </MDBox>
        </MDBox>
      </DashboardLayout>

      <ReportModal open={openReport} onClose={() => setOpenReport(false)} filter={filterSearch} />
    </>
  );
}

export default MovementsList;
