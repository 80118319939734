import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import { useAuthContext } from "context/auth_context";
import { Form, Formik } from "formik";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MDBox from "shared/components/MDBox";
import MDButton from "shared/components/MDButton";
import MDTypography from "shared/components/MDTypography";
import FormField from "shared/components/form_field";
import toastError from "shared/components/snackbar/error/toast_error";
import logoVertical from "../../assets/images/logo_vertical.png";
import initialValues from "./schemas/initial_values";
import validations from "./schemas/validations";

function Login() {
  const navigate = useNavigate();

  const { handleLogin, handleKeepLogged, keeplogged, token } = useAuthContext();

  const handleSubmit = async (values, actions) => {
    try {
      const user = await handleLogin(values);

      actions.setTouched({});
      actions.setSubmitting(false);

      const isAdmin = user.role === 0;

      if (isAdmin) {
        navigate("/usuarios");
      } else {
        navigate("/produtos");
      }
    } catch (e) {
      toastError(e.message);
    }
  };

  useEffect(() => {
    if (keeplogged && token) navigate("/usuarios");
  }, [keeplogged]);

  return (
    <BasicLayout>
      <MDBox
        p={2}
        mb={5}
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <img alt="logo" src={logoVertical} width="100%" />
      </MDBox>
      <Card>
        <MDBox
          variant="gradient"
          borderRadius="lg"
          bgColor="primary"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Login
          </MDTypography>
        </MDBox>
        <MDBox pt={2} px={3}>
          <Formik
            initialValues={initialValues}
            validationSchema={validations[0]}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => {
              const { email: emailV, password: passwordV } = values;

              return (
                <Form id="login-form" autoComplete="off">
                  <FormField
                    fieldVariant="outlined"
                    name="email"
                    label="E-mail *"
                    type="email"
                    value={emailV}
                    error={errors.email && touched.email}
                    success={emailV.length > 0 && !errors.email}
                    onChange={(e) => {
                      const { value } = e.target;
                      setFieldValue("email", value);
                    }}
                  />

                  <FormField
                    fieldVariant="outlined"
                    name="password"
                    label="Senha *"
                    type="password"
                    value={passwordV}
                    error={errors.password && touched.password}
                    success={passwordV.length > 0 && !errors.password}
                  />

                  <MDBox display="flex" alignItems="center" justifyContent="center">
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      color="primary"
                      sx={{ cursor: "pointer" }}
                      onClick={() => navigate("/esqueci-a-senha")}
                    >
                      Esqueceu a senha?
                    </MDTypography>
                  </MDBox>

                  <MDBox mt={4}>
                    <MDButton
                      disabled={isSubmitting}
                      color="primary"
                      type="submit"
                      size="large"
                      fullWidth
                    >
                      AUTENTICAR
                    </MDButton>
                  </MDBox>
                </Form>
              );
            }}
          </Formik>
        </MDBox>
        <MDBox px={3} py={1} display="flex" alignItems="center" justifyContent="center">
          <Switch checked={keeplogged} onChange={(_, v) => handleKeepLogged(v)} />
          <MDTypography variant="button" fontWeight="regular">
            Salvar meus dados
          </MDTypography>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Login;
