/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";

import { Autocomplete, Grid } from "@mui/material";
import MDBox from "shared/components/MDBox";
import MDInput from "shared/components/MDInput";
import {
  compareOptionToValue,
  handleValue,
  adornedInputPropsAutocomplete,
  handleNoOptionsAutocomplete,
} from "shared/components/autocomplete/autocomplete_configs";
import FormFieldAutocomplete from "shared/components/form_field/form_field_autocomplete";
import FormFieldMemo from "shared/components/form_field/form_field_memo";
import PropTypes from "prop-types";
import ProductsService from "pages/product/service";
import toastError from "shared/components/snackbar/error/toast_error";

function ProductNCMAutocomplete({ formData }) {
  const { values, setFieldValue, handleBlur } = formData;
  const { ncm: ncmV } = values;

  const [ncmCode, setNcmCode] = useState("");
  const [ncmOptions, setNcmOptions] = useState();
  const [ncmOptionsPage, setNcmOptionsPage] = useState(1);
  const [ncmOptionsTotalPages, setNcmOptionsTotalPages] = useState(1);
  const [ncmOptionsSearch, setNcmOptionsSearch] = useState(1);
  const [loading, setLoading] = useState(false);

  const loadNCMs = async (search = "", setField, page = 1, limit = 100) => {
    try {
      setLoading(true);
      const res = await ProductsService.getProductNCM(page, limit, search);
      if (res) {
        setNcmOptions(res.data);
        setNcmOptionsPage(res.meta.page);
        setNcmOptionsTotalPages(res.meta.total_pages);

        if (res.data.length > 0 && search.includes("code=")) setField("ncm", res.data[0] ?? "");
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreNCMs = async (search = "", page = 1, limit = 100) => {
    try {
      setLoading(true);
      const res = await ProductsService.getProductNCM(page, limit, search);
      if (res) {
        setNcmOptions([...ncmOptions, ...res.data]);
        setNcmOptionsPage(res.meta.page);
        setNcmOptionsTotalPages(res.meta.total_pages);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAutocompleteScroll = (event) => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    if (listboxNode.scrollHeight - position <= 1 && ncmOptionsPage < ncmOptionsTotalPages) {
      loadMoreNCMs(ncmOptionsSearch, ncmOptionsPage + 1);
    }
  };

  useEffect(() => {
    if (ncmV && !ncmCode) setNcmCode(ncmV.code);
  }, [ncmV]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={2}>
        <MDInput
          name="ncm code"
          label="Código NCM"
          variant="standard"
          type="text"
          value={ncmCode}
          onChange={(event) => {
            const { value } = event.target;
            setNcmCode(value);
          }}
          onBlur={(event) => {
            const { value } = event.target;
            if (value && value.length >= 3) {
              setFieldValue("ncm", "");
              loadNCMs(`&code=${value.trim()}`, setFieldValue);
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={10}>
        <Autocomplete
          options={ncmOptions ?? []}
          noOptionsText={handleNoOptionsAutocomplete(ncmOptions, "async")}
          getOptionLabel={(o) => `${o?.code} - ${o?.description}`}
          value={handleValue(ncmV)}
          isOptionEqualToValue={(o, v) => compareOptionToValue(o, v, "code")}
          onChange={(e, value) => {
            setFieldValue("ncm", value);
            setNcmCode(value?.code ?? "");
          }}
          renderOption={(props, option) => (
            <MDBox {...props} component="li" key={`${option.code}_${option.description}`}>
              {option.code} - {option.description}
            </MDBox>
          )}
          renderInput={(params) => (
            <FormFieldAutocomplete
              {...params}
              fieldVariant="standard"
              type="text"
              label="Descrição do NCM *"
              name="ncm"
              onChange={(event) => {
                const { value } = event.target;
                if (value && value.length >= 3) {
                  setNcmOptionsSearch(`&description=${value.trim()}`);
                  setNcmOptionsPage(1);
                  loadNCMs(`&description=${value.trim()}`, setFieldValue, ncmOptionsPage);
                }
              }}
              onBlur={(event) => {
                handleBlur(event);
                if (!event.target.value) setNcmOptions([]);
              }}
              InputProps={adornedInputPropsAutocomplete(loading, params)}
            />
          )}
          ListboxProps={{ onScroll: handleAutocompleteScroll }}
        />
      </Grid>
    </Grid>
  );
}

// ProductNCMAutocomplete.defaultProps = {
//   setIsSearchByBarCode: () => {},
// };

ProductNCMAutocomplete.propTypes = {
  //   productState: PropTypes.objectOf(PropTypes.any).isRequired,
  formData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default ProductNCMAutocomplete;
