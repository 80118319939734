import * as Yup from "yup";

const validations = [
  Yup.object().shape({
    name: Yup.string().required("Nome é obrigatório"),
    email: Yup.string().email("E-mail inválido").required("E-mail é obrigatório"),
    role: Yup.number().required("Perfil é obrigatório"),
  }),
];

export default validations;
